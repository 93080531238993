import ChartTooltip from '@/app/condition/components/results/components/charts/components/tooltip-wrapper/chart-tooltip'
import { DATE_TEMPLATE } from '@/constants/core/common.constant'
import { mapUnitType } from '@/constants/measurement/unit.constant'
import type { EUnitType } from '@/enums/measurment/unit-type.enum'
import { formatDate } from '@/utils/format-date'
import { ceil } from 'lodash'
import type { FC } from 'react'
import type { TooltipProps } from 'recharts'
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'

type TooltipScalarProps = {
  unit: EUnitType
} & TooltipProps<ValueType, NameType>

const TooltipScalar: FC<TooltipScalarProps> = ({ active, payload, unit }) => {
  if (active && payload) {
    const currentData = payload[0].payload

    const labelUnit = mapUnitType[unit]

    return (
      <ChartTooltip>
        <span>
          {labelUnit}: {ceil(currentData.value, 3)}
        </span>
        <span>T: {formatDate(currentData.time, DATE_TEMPLATE)}</span>
      </ChartTooltip>
    )
  }

  return null
}

export default TooltipScalar
