import type { ILink } from '@/types/navigation/links.type'
import cn from 'classnames'
import type { FC, MouseEventHandler } from 'react'
import React from 'react'
import { NavLink } from 'react-router'

import styles from './menu-link.module.css'

type MenuLinkProps = {
  onClick: MouseEventHandler<HTMLAnchorElement>
} & ILink

const MenuLink: FC<MenuLinkProps> = ({ to, title, icon, onClick, disabled }) => {
  const isActiveClass = ({ isActive }: { isActive: boolean }): string => (isActive ? styles['active'] : '')
  return (
    <li className={styles['item-link']} aria-disabled={disabled}>
      <NavLink className={isActiveClass} to={to} onClick={onClick}>
        <span className={cn('material-symbols-sharp', styles.icon)}>{icon}</span>
        <p>{title}</p>
      </NavLink>
    </li>
  )
}

export default MenuLink
