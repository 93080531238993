import {
  MAX_COUNT_LINES_AMPLITUDE,
  MAX_COUNT_LINES_FREQUENCIES
} from '@/app/condition/components/results/components/charts/components/chart-spectrum/chart-spectrum.constant'
import { generateTicks } from '@/app/condition/components/results/components/charts/components/chart-spectrum/chart-spectrum.service'
import {
  calculateDecibel,
  calculateLinear
} from '@/app/condition/components/results/components/charts/components/chart-spectrum/chart-spectrum.util'
import ChartSpectrumPanel from '@/app/condition/components/results/components/charts/components/chart-spectrum/components/chart-spectrum-panel/chart-spectrum-panel'
import LegendSection from '@/app/condition/components/results/components/charts/components/chart-spectrum/components/legend-section/legend-section'
import {
  getAmplitudeRange,
  getSpectrumChartData
} from '@/app/condition/components/results/components/charts/components/chart-spectrum/components/spectrum-cascade/spectrum-cascade.service'
import TooltipSpectrum from '@/app/condition/components/results/components/charts/components/chart-spectrum/components/tooltip-spectrum/tooltip-spectrum'
import {
  calculateAmFm,
  calculateDeltaFrequency,
  calculateExpressSectionsData,
  calculateSKZ
} from '@/app/condition/components/results/components/charts/components/chart-spectrum/helpers/calculates.helper'
import {
  getChartDataForExpressBars,
  getChartDataForExpressBarsModulates,
  renderExpressBarsChart
} from '@/app/condition/components/results/components/charts/components/chart-spectrum/helpers/render-express-bars-chart.helper'
import { renderExpressSectionsChart } from '@/app/condition/components/results/components/charts/components/chart-spectrum/helpers/render-express-sections-chart.helper'
import useBarsKeyHandlers from '@/app/condition/components/results/components/charts/components/chart-spectrum/hooks/use-bars-key-handlers'
import useExpressBars from '@/app/condition/components/results/components/charts/components/chart-spectrum/hooks/use-express-bars'
import useExpressMarkers from '@/app/condition/components/results/components/charts/components/chart-spectrum/hooks/use-express-markers/use-express-markers'
import useExpressSections from '@/app/condition/components/results/components/charts/components/chart-spectrum/hooks/use-express-sections'
import ChartsTools from '@/app/condition/components/results/components/charts/components/chart-tools/charts-tools'
import ChartUnits from '@/app/condition/components/results/components/charts/components/chart-units/chart-units'
import ChartWrapper from '@/app/condition/components/results/components/charts/components/chart-wrapper/chart-wrapper'
import SliderApp from '@/app/condition/components/results/components/charts/components/slider-app/slider-app'
import { ColorSpectrum, SpectrumKeyMap } from '@/constants/chart/charts.constant'
import { mapPhysicalQuantity } from '@/constants/measurement/unit.constant'
import { EAmplitudeMode, EFrequencyMode } from '@/enums/charts/chart-value-mode.enum'
import type { EUnitType } from '@/enums/measurment/unit-type.enum'
import { EPhysicalQuantityType } from '@/enums/point/physical-quantity-type.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IChartSpectrum, IValuesMode } from '@/types/chart/chart-spectrun.type'
import type { IMeasurement } from '@/types/measurement/measurement.type'
import type { ISpectrum } from '@/types/measurement/measurment-result.type'
import { chartFormatValue } from '@/utils/chart/chart-format-value'
import { getStepSlider } from '@/utils/chart/get-step-slider'
import { vibrationConverter } from '@/utils/unit/unit-converter.util'
import { Checkbox, Flex } from 'antd'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { minBy } from 'lodash'
import type { FC, LegacyRef } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { HotKeys } from 'react-hotkeys'
import { shallowEqual } from 'react-redux'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

type TProps = {
  onChartClick: () => void
  resultId: string
  title: string
  chartButtonRef: LegacyRef<HTMLButtonElement>
  spectrum: ISpectrum
  selectedMeasurement: IMeasurement
}

const initialValuesMode: IValuesMode = {
  amplitudeMode: EAmplitudeMode.DB,
  frequencyMode: EFrequencyMode.Liner
}

const SpectrumCascade: FC<TProps> = ({
  onChartClick,
  resultId,
  chartButtonRef,
  title,
  spectrum,
  selectedMeasurement
}) => {
  const [valuesMode, setValuesMode] = useState<IValuesMode>(initialValuesMode)
  const [amplitudeSliderDisabled, setAmplitudeSliderDisabled] = useState(true)
  const [sliderAmplitude, setSliderAmplitude] = useState<number[] | null>(null)
  const [sliderIndexes, setSliderIndexes] = useState<number[] | null>(null)
  const [isModulateVisible, setIsModulateVisible] = useState(true)
  const { addExpressSectionResult, setResultsBar } = useActions()
  const [targetUnit, setTargetUnit] = useState<EUnitType>(selectedMeasurement.unitType)

  const {
    sections,
    isSectionExpressBarOpen,
    isMarkersExpressBarOpen,
    isBarsExpressBarOpen,
    barOptions: expressBarOptions
  } = useTypedSelector(
    (state) => ({
      ...state.expressSectionReducer,
      ...state.expressMarkersReducer,
      ...state.expressBarsReducer
    }),
    {
      equalityFn: shallowEqual
    }
  )
  const sourceUnit = selectedMeasurement.unitType
  const vibrationTypes = new Set([
    EPhysicalQuantityType.VIBRO_ACCELERATION,
    EPhysicalQuantityType.VIBRO_DISPLACEMENT,
    EPhysicalQuantityType.VIBRO_VELOCITY
  ])

  const isVibration = vibrationTypes.has(mapPhysicalQuantity[sourceUnit])
  const isFrequencyModeLog = valuesMode.frequencyMode === EFrequencyMode.LOG
  const isAmplitudeModeLog = valuesMode.amplitudeMode === EAmplitudeMode.LOG
  const isDbAmplitude = valuesMode.amplitudeMode === EAmplitudeMode.DB

  const deltaFrequency = calculateDeltaFrequency(selectedMeasurement)

  const chartData = useMemo<IChartSpectrum[]>(
    () => getSpectrumChartData(spectrum, deltaFrequency, valuesMode, selectedMeasurement.unitType, targetUnit),
    [spectrum, deltaFrequency, valuesMode, selectedMeasurement.unitType, targetUnit]
  )

  const rangeIndexes = useMemo(() => (chartData ? [0, chartData.length - 1] : [0, 0]), [chartData])

  const rangeAmplitude = useMemo<number[] | null>(
    () => (chartData && rangeIndexes ? getAmplitudeRange(chartData, rangeIndexes) : null),
    [chartData, rangeIndexes]
  )

  const rangeFrequency = useMemo<number[] | null>(() => {
    if (!chartData || !sliderIndexes) {
      return null
    }

    const minIndex = sliderIndexes[0]
    const maxIndex = sliderIndexes[1]
    return [chartData[minIndex]?.frequency, chartData[maxIndex]?.frequency]
  }, [chartData, sliderIndexes])

  useEffect(() => {
    if (!sliderIndexes) {
      setSliderIndexes(rangeIndexes)
    }
  }, [rangeIndexes, sliderIndexes])

  useEffect(() => {
    if (rangeAmplitude && !sliderAmplitude) {
      setSliderAmplitude(rangeAmplitude)
    }
  }, [rangeAmplitude, sliderAmplitude])

  const handleSliderIndex = (range: number[]) => {
    if (chartData) {
      setSliderIndexes(range)
      if (amplitudeSliderDisabled) {
        const amplitudeRange = getAmplitudeRange(chartData, range)
        setSliderAmplitude(amplitudeRange)
      }
    }
  }

  const ticksFrequencies = useMemo(
    () => generateTicks(rangeFrequency, valuesMode.frequencyMode, MAX_COUNT_LINES_FREQUENCIES),
    [rangeFrequency, valuesMode.frequencyMode]
  )

  const stepY = getStepSlider(20, sliderAmplitude)
  const ticksAmplitudes = useMemo(
    () => generateTicks(sliderAmplitude, valuesMode.amplitudeMode, MAX_COUNT_LINES_AMPLITUDE),
    [sliderAmplitude, valuesMode.amplitudeMode]
  )

  const {
    barOptions,
    onMouseLeave: onMouseLeaveBar,
    onMouseMove: onMouseMoveBar,
    onMouseUp: onMouseUpBar,
    setIsMainAreaDrag: setIsMainAreaDragBar,
    setIsModulateAreaDrag,
    setBarOptions,
    refIndexBar,
    refIndexModulateBar
  } = useExpressBars()

  const {
    onMouseLeave: onMouseLeaveSection,
    onMouseMove: onMouseMoveSection,
    onMouseUp: onMouseUpSection,
    ...otherSectionsChartProps
  } = useExpressSections(chartData)

  const { markersHotKeysHandlers, handleMouseMove, handleMouseLeave, handleMouseUp, renderMarkers } = useExpressMarkers(
    chartData,
    title
  )

  const fMax = Number(selectedMeasurement.programSpecterDescription?.specterLineCount?.split('_')[2])

  const skz = calculateSKZ({
    chartData: chartData,
    startIndex: 1,
    endIndex: chartData.length - 1,
    isDb: isDbAmplitude
  })

  const { am, fm } = calculateAmFm({
    chartData: chartData,
    startIndex: 5,
    endIndex: chartData.length - 1,
    selectedMeasurement,
    isDb: isDbAmplitude
  })

  const expressBars = getChartDataForExpressBars({ ...barOptions, chartData })

  const calculateExpressBarsResults = useCallback(() => {
    const mainBars = getChartDataForExpressBars({
      ...expressBarOptions,
      chartData
    })

    const resultsBars = mainBars?.map((mainBar, mainIndex) => {
      let isEmptyLocal = true

      if (typeof mainBar !== 'number') {
        isEmptyLocal = false
      }

      const leftModulateBars = isModulateVisible
        ? getChartDataForExpressBarsModulates({
            barOptions: expressBarOptions,
            chartData,
            bar: mainBar,
            separator: -1
          })
            .map((modulateBar, modulateIndex) => {
              if (typeof modulateBar !== 'number') {
                isEmptyLocal = false
              }

              return {
                count: (modulateIndex + 1) * -1,
                modulateBarF: typeof modulateBar !== 'number' ? modulateBar?.frequency : '--',
                modulateBarA: typeof modulateBar !== 'number' ? modulateBar?.amplitude : '--',
                percent:
                  typeof mainBar !== 'number' && typeof modulateBar !== 'number'
                    ? (modulateBar?.amplitude / mainBar?.amplitude) * 100
                    : '--'
              }
            })
            .reverse()
        : []

      const rightModulateBars = isModulateVisible
        ? getChartDataForExpressBarsModulates({
            barOptions: expressBarOptions,
            chartData,
            bar: mainBar,
            separator: 1
          }).map((modulateBar, modulateIndex) => {
            if (typeof modulateBar !== 'number') {
              isEmptyLocal = false
            }

            return {
              count: modulateIndex + 1,
              modulateBarF: typeof modulateBar !== 'number' ? modulateBar?.frequency : '--',
              modulateBarA: typeof modulateBar !== 'number' ? modulateBar?.amplitude : '--',
              percent:
                typeof mainBar !== 'number' && typeof modulateBar !== 'number'
                  ? (modulateBar?.amplitude / mainBar?.amplitude) * 100
                  : '--'
            }
          })
        : []

      return {
        isEmpty: isEmptyLocal,
        count: mainIndex + 1,
        percent: 100,
        leftModulateBars,
        mainBarF: typeof mainBar !== 'number' ? mainBar?.frequency : '--',
        mainBarA: typeof mainBar !== 'number' ? mainBar?.amplitude : '--',
        rightModulateBars
      }
    })

    if (!resultsBars) return

    setResultsBar({
      title,
      isDb: isModulateVisible,
      results: resultsBars
    })
  }, [chartData, expressBarOptions, isModulateVisible, setResultsBar, title])

  const addChartHandlers = () => {
    if (isSectionExpressBarOpen) {
      return {
        onMouseLeave: onMouseLeaveSection,
        onMouseUp: onMouseUpSection,
        onMouseMove: onMouseMoveSection
      }
    } else if (isMarkersExpressBarOpen) {
      return {
        onMouseLeave: handleMouseLeave,
        onMouseUp: handleMouseUp,
        onMouseMove: handleMouseMove
      }
    } else if (isBarsExpressBarOpen) {
      return {
        onMouseLeave: onMouseLeaveBar,
        onMouseUp: onMouseUpBar,
        onMouseMove: onMouseMoveBar
      }
    }

    return {}
  }

  //подключаю обработчики для горячих кнопок для рядов
  useBarsKeyHandlers({
    barOptions,
    setBarOptions,
    refIndexBar,
    chartData,
    resultId
  })

  const calculateExpressSectionResults = useCallback(() => {
    const dataForExpressSections = calculateExpressSectionsData({
      chartData,
      sections,
      selectedMeasurement,
      isDb: valuesMode.amplitudeMode === EAmplitudeMode.DB
    })

    addExpressSectionResult({
      title,
      amplitudeMode: valuesMode.amplitudeMode,
      unitType: targetUnit as EUnitType,
      data: dataForExpressSections
    })
  }, [addExpressSectionResult, chartData, sections, selectedMeasurement, targetUnit, title, valuesMode.amplitudeMode])

  useEffect(() => {
    if (isBarsExpressBarOpen) {
      calculateExpressBarsResults()
    }
  }, [calculateExpressBarsResults, isBarsExpressBarOpen])

  useEffect(() => {
    if (isSectionExpressBarOpen) {
      calculateExpressSectionResults()
    }
  }, [calculateExpressSectionResults, isSectionExpressBarOpen])

  const handleResetIndexesDoubleClick = () => {
    if (rangeIndexes) {
      setSliderIndexes(rangeIndexes)

      if (amplitudeSliderDisabled) {
        setSliderAmplitude(rangeAmplitude)
      }
    }
  }

  const handleResetAmplitudeDoubleClick = () => {
    if (rangeAmplitude && !amplitudeSliderDisabled) {
      setSliderAmplitude(rangeAmplitude)
    }
  }

  const handleSliderDisableCheckboxChange = (evt: CheckboxChangeEvent) => {
    const notChecked = !evt.target.checked
    setAmplitudeSliderDisabled(notChecked)
    if (notChecked && sliderIndexes) {
      setSliderAmplitude(getAmplitudeRange(chartData, sliderIndexes))
      return
    }
    setSliderAmplitude(getAmplitudeRange(chartData, rangeIndexes))
  }

  const handleAmplitudeModeSelect = (mode: EAmplitudeMode) => {
    if (mode === valuesMode.amplitudeMode) {
      return
    }

    setValuesMode({ ...valuesMode, amplitudeMode: mode })

    setSliderAmplitude((prev) => {
      if (!prev) return null

      if (isDbAmplitude && (mode === EAmplitudeMode.LINER || mode === EAmplitudeMode.LOG)) {
        return prev.map(calculateLinear)
      }

      if (!isDbAmplitude && mode === EAmplitudeMode.DB) {
        return prev.map(calculateDecibel)
      }

      return prev
    })
  }

  const handleFrequencyModeSelect = (mode: EFrequencyMode) => {
    setValuesMode({ ...valuesMode, frequencyMode: mode })
  }

  const handleUnitChange = (unit: EUnitType) => {
    setTargetUnit(unit)
    setSliderAmplitude((prev) => {
      if (prev) {
        return prev.map((amplitude) => {
          const nearestPoint = minBy(chartData, (data) => Math.abs(data.amplitude - amplitude)) as IChartSpectrum

          return isDbAmplitude
            ? calculateDecibel(
                vibrationConverter(calculateLinear(nearestPoint.amplitude), nearestPoint.frequency, targetUnit, unit)
              )
            : vibrationConverter(nearestPoint.amplitude, nearestPoint.frequency, targetUnit, unit)
        })
      }

      return null
    })
  }

  return (
    <HotKeys handlers={markersHotKeysHandlers} keyMap={SpectrumKeyMap} allowChanges={true}>
      <ChartWrapper onChartClick={onChartClick} resultId={resultId} chartButtonRef={chartButtonRef} title={title}>
        <Flex>
          <SliderApp
            min={rangeAmplitude ? rangeAmplitude[0] : 0}
            max={rangeAmplitude ? rangeAmplitude[1] : 0}
            value={sliderAmplitude || [0, 0]}
            onChange={setSliderAmplitude}
            range={{ draggableTrack: true }}
            step={stepY}
            vertical={true}
            tooltip={{ formatter: chartFormatValue }}
            onDoubleClick={handleResetAmplitudeDoubleClick}
            onCheckboxChange={handleSliderDisableCheckboxChange}
            disabled={amplitudeSliderDisabled}
          />

          <Flex flex={1} vertical={true} gap={5}>
            <ChartsTools>
              <ChartUnits
                sourceUnit={sourceUnit}
                targetUnit={targetUnit}
                onSetUnitChange={handleUnitChange}
                isVibration={isVibration}
              />
              <ChartSpectrumPanel
                chartFreqMode={valuesMode.frequencyMode}
                chartAmplitudeMode={valuesMode.amplitudeMode}
                onAmplitudeModeSelect={handleAmplitudeModeSelect}
                onFrequencyModeSelect={handleFrequencyModeSelect}
              />

              {isBarsExpressBarOpen ? (
                <Checkbox
                  disabled={barOptions.countOfModulateBars === 0}
                  checked={isModulateVisible}
                  onChange={() => setIsModulateVisible((prev) => !prev)}
                >
                  m
                </Checkbox>
              ) : null}
            </ChartsTools>
            <ResponsiveContainer height={350}>
              <LineChart {...addChartHandlers()} data={chartData}>
                <CartesianGrid color={'black'} strokeDasharray='3 3' />
                <XAxis
                  dataKey='frequency'
                  domain={rangeFrequency || [0, 0]}
                  type='number'
                  // axisLine={true}
                  ticks={ticksFrequencies || undefined}
                  allowDataOverflow={true}
                  // includeHidden={true}
                  scale={isFrequencyModeLog ? 'log' : 'auto'}
                />
                <YAxis
                  dataKey='amplitude'
                  type='number'
                  ticks={ticksAmplitudes || undefined}
                  allowDataOverflow={true}
                  domain={sliderAmplitude || [0, 0]}
                  scale={isAmplitudeModeLog ? 'log' : 'auto'}
                />
                <Line
                  isAnimationActive={false}
                  dot={false}
                  dataKey='amplitude'
                  stroke={ColorSpectrum.Amplitude}
                  strokeWidth={1}
                />
                <Line
                  isAnimationActive={false}
                  dot={false}
                  dataKey='amplitudeBackgroundLine'
                  stroke={ColorSpectrum.BackgroundLine}
                  strokeWidth={2}
                />

                {isBarsExpressBarOpen &&
                  renderExpressBarsChart({
                    chartData,
                    expressBars,
                    barOptions,
                    setIsMainAreaDragBar,
                    setIsModulateAreaDrag,
                    refIndexBar,
                    refIndexModulateBar,
                    isModulateVisible
                  })}

                {renderMarkers()}

                {isSectionExpressBarOpen
                  ? renderExpressSectionsChart({
                      ...otherSectionsChartProps,
                      chartData,
                      sliderValuesHorizontal: sliderIndexes || [0, 0]
                    })
                  : null}

                <Legend align='right' verticalAlign='top' content={<LegendSection skz={skz} am={am} fm={fm} />} />

                <Tooltip
                  content={<TooltipSpectrum isLog={isAmplitudeModeLog} dfValue={deltaFrequency} fMax={fMax} />}
                  isAnimationActive={false}
                />
              </LineChart>
            </ResponsiveContainer>
            <SliderApp
              onDoubleClick={handleResetIndexesDoubleClick}
              min={rangeIndexes[0]}
              max={rangeIndexes[1]}
              value={sliderIndexes || [0, 0]}
              onChange={handleSliderIndex}
              range={{ draggableTrack: true }}
            />
          </Flex>
        </Flex>
      </ChartWrapper>
    </HotKeys>
  )
}

export default SpectrumCascade
