import { amplitudeModeConverter } from '@/app/condition/components/results/components/charts/components/chart-spectrum/chart-spectrum.service'
import type { EUnitType } from '@/enums/measurment/unit-type.enum'
import type { IChartSpectrum, IValuesMode } from '@/types/chart/chart-spectrun.type'
import type { ISpectrum } from '@/types/measurement/measurment-result.type'
import { vibrationConverter } from '@/utils/unit/unit-converter.util'
import { isEmpty, maxBy, minBy } from 'lodash'

export const getSpectrumChartData = (
  sourceData: ISpectrum,
  deltaFrequency: number,
  valuesMode: IValuesMode,
  sourceUnit: EUnitType,
  targetUnit: EUnitType
): IChartSpectrum[] =>
  sourceData.amplitudeSpectrum.slice(1).map((amplitude, index) => {
    const frequency = deltaFrequency * index + deltaFrequency
    const spectrumAmplitude = vibrationConverter(amplitude, frequency, sourceUnit, targetUnit)
    const convertedAmplitudeByMode = amplitudeModeConverter(valuesMode.amplitudeMode, spectrumAmplitude)
    let amplitudeBackgroundLine = null

    if (!isEmpty(sourceData.backgroundLine)) {
      const backgroundLine = sourceData.backgroundLine[index]
      const convertedBackgroundLine = vibrationConverter(backgroundLine, frequency, sourceUnit, targetUnit)
      amplitudeBackgroundLine = amplitudeModeConverter(valuesMode.amplitudeMode, convertedBackgroundLine)
    }

    return {
      amplitude: convertedAmplitudeByMode,
      frequency: frequency,
      amplitudeBackgroundLine: amplitudeBackgroundLine,
      index: index
    }
  })

export const getAmplitudeRange = (chartData: IChartSpectrum[], sliderIndexes: number[]): number[] | null => {
  const minAmplitude = minBy(chartData.slice(sliderIndexes[0], sliderIndexes[1]), 'amplitude')
  const maxAmplitude = maxBy(chartData.slice(sliderIndexes[0], sliderIndexes[1]), 'amplitude')

  if (minAmplitude?.amplitude && maxAmplitude?.amplitude) {
    return [minAmplitude.amplitude, maxAmplitude.amplitude]
  }

  return null
}
