import About from '@/app/about/about'
import Condition from '@/app/condition/condition'
import machineConditionRoute from '@/app/condition/machine-condition.route'
import Configuration from '@/app/configuration/configuration'
import configurationRoute from '@/app/configuration/configuration.route'
import Home from '@/app/home/home'
import NotImplemented from '@/app/not-implemented/not-implemented'
import Plans from '@/app/plans/plans'
import Reports from '@/app/reports/reports'
import Settings from '@/app/settings/settings'
import settingsRoute from '@/app/settings/settings.route'
import AppLayout from '@/components/layouts/app-layout/app-layout'
import { AppRoute } from '@/constants/navigation/routes.constant'
import type { RouteObject } from 'react-router'
import { createBrowserRouter, createHashRouter } from 'react-router'

const router: RouteObject[] = [
  {
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: AppRoute.Condition,
        element: <Condition />,
        children: machineConditionRoute
      },
      {
        path: AppRoute.Configuration,
        element: <Configuration />,
        children: configurationRoute
      },
      {
        path: AppRoute.Plans,
        element: <Plans />
      },
      {
        path: AppRoute.Reports,
        element: <Reports />
      },
      {
        path: AppRoute.Settings,
        element: <Settings />,
        children: settingsRoute
      },
      {
        path: AppRoute.About,
        element: <About />
      },
      {
        path: '*',
        element: <NotImplemented />
      }
    ]
  }
]

const appRouter = globalThis.IN_DESKTOP_ENV ? createHashRouter(router) : createBrowserRouter(router)

export default appRouter
