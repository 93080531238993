import Charts from '@/app/condition/components/results/components/charts/charts'
import ResultsSidebar from '@/app/condition/components/results/components/results-sidebar/results-sidebar'
import ResultsTools from '@/app/condition/components/results/components/results-tools/results-tools'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'

import styles from './results.module.css'

const Results: FC = () => {
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)

  const isSelectedMachine = Boolean(selectedMachineId)

  return (
    isSelectedMachine && (
      <>
        <ResultsTools />

        <div className={styles.content}>
          <ResultsSidebar />
          <Charts />
        </div>
      </>
    )
  )
}

export default Results
