import { KEY_ROOT } from '@/components/widgets/equipment-tree/equipment-tree.constant'
import useTreeDraggableHook from '@/components/widgets/equipment-tree/use-tree-draggable.hook'
import { useTreeLoader } from '@/components/widgets/equipment-tree/use-tree-loader.hook'
import { useTreeSelectionHandler } from '@/components/widgets/equipment-tree/use-tree-select-handler.hook'
import { NAME_ROOT } from '@/constants/equipment/equipment-tree.constant'
import { EEquipmentTreeMode } from '@/enums/equipments/equipment-tree-mode.enum'
import { EEquipmentTree } from '@/enums/equipments/equipment-tree.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IEquipmentTreeNode } from '@/types/tree/equipment-tree-node.interface'
import { Tree } from 'antd'
import cn from 'classnames'
import type { FC, Key } from 'react'
import { useEffect, useState } from 'react'

import styles from './equipment-tree.module.css'

const initialTreeStructure = [
  {
    key: KEY_ROOT,
    title: NAME_ROOT,
    group: EEquipmentTree.ROOT,
    icon: <span className={cn('material-symbols-outlined', styles['icon'])}>folder</span>
  }
]

interface IProps {
  equipmentTreeMode?: EEquipmentTreeMode
}

const EquipmentTree: FC<IProps> = ({ equipmentTreeMode }) => {
  const { selectedTreeItems } = useTypedSelector((state) => state.equipmentsUiReducer)
  const { selectedGroupId, selectedMachineId, selectedMeasurementId, selectedPointId, expendedEquipments } =
    useTypedSelector((state) => state.globalReducer)
  const { setSelectedTreeItems } = useActions()
  const [treeMenu, setTreeMenu] = useState<IEquipmentTreeNode[]>(initialTreeStructure)
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([])
  const { handleDataLoad } = useTreeLoader(treeMenu, setTreeMenu, equipmentTreeMode)
  const { handleSelect } = useTreeSelectionHandler(treeMenu)
  const isDraggable = equipmentTreeMode === EEquipmentTreeMode.PLAN
  const { allowDraggableNode, onDragStart, onDragEnd } = useTreeDraggableHook(isDraggable)
  const limitedMachine =
    equipmentTreeMode === EEquipmentTreeMode.CONDITION ||
    equipmentTreeMode === EEquipmentTreeMode.PLAN ||
    equipmentTreeMode === EEquipmentTreeMode.KINEMATIC

  useEffect(() => {
    setExpandedKeys((prevState) =>
      Array.from(
        new Set(
          [...prevState, selectedGroupId, selectedMachineId, selectedPointId, selectedMeasurementId, KEY_ROOT].filter(
            (item): item is string => item !== null
          )
        )
      )
    )
    const selectedEquipment: string | null =
      selectedMeasurementId || selectedPointId || selectedMachineId || selectedGroupId || null

    if (selectedEquipment) {
      setSelectedTreeItems([selectedEquipment])

      if (limitedMachine) {
        if (selectedMachineId) {
          setSelectedTreeItems([selectedMachineId])
        }
      }
    }
  }, [
    limitedMachine,
    expendedEquipments,
    selectedGroupId,
    selectedMachineId,
    selectedMeasurementId,
    selectedPointId,
    setSelectedTreeItems
  ])

  const handleExpand = (keys: Key[]) => {
    setExpandedKeys(keys as string[])
  }

  return (
    <Tree
      showIcon={true}
      treeData={treeMenu}
      showLine={true}
      loadData={handleDataLoad}
      expandedKeys={expandedKeys}
      onExpand={handleExpand}
      onSelect={handleSelect}
      selectedKeys={selectedTreeItems}
      className={styles['tree']}
      blockNode={true}
      draggable={{ icon: false, nodeDraggable: allowDraggableNode }}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    />
  )
}

export default EquipmentTree
