import PointIcon from '@/components/icons/equipments/point-icon'
import EquipmentItem from '@/components/widgets/equipment-item/equipment-item'
import Spinner from '@/components/widgets/spinner/spinner'
import { ApiTag } from '@/constants/store/api.constants'
import useActions from '@/hooks/use-actions'
import { useAppDispatch } from '@/hooks/use-app-dispatch'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { baseApi } from '@/store/api/api-base-query'
import { useGetMeasuringPointsQuery } from '@/store/api/points.api'
import type { FC, ReactEventHandler } from 'react'
import { useEffect } from 'react'

import styles from './points-list.module.css'

const PointsList: FC = () => {
  const dispatch = useAppDispatch()
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)

  const { setSelectedPointId, addExpendedEquipment } = useActions()

  const { data: pointsResponse, isFetching } = useGetMeasuringPointsQuery(selectedMachineId, {
    skip: !selectedMachineId
  })

  const points = pointsResponse?.content

  useEffect(() => {
    dispatch(baseApi.util.invalidateTags([ApiTag.MeasurementsResult]))
  }, [dispatch, points])

  const handlePointClick: ReactEventHandler<HTMLDivElement> = (evt) => {
    const pointId = evt.currentTarget.getAttribute('data-id')
    if (pointId) {
      setSelectedPointId(pointId)
      addExpendedEquipment(pointId)
    }
  }

  if (isFetching) {
    return <Spinner />
  }

  return (
    selectedMachineId && (
      <div className={styles.container}>
        {points?.map((point) => (
          <EquipmentItem
            key={point.pointId}
            element={{
              id: point.pointId,
              name: point.name,
              equipmentIcon: <PointIcon />,
              showStatisticalIndicator: true,
              isNavigationElement: true
            }}
            onClick={handlePointClick}
          />
        ))}
        {points?.length === 0 && (
          <p style={{ textAlign: 'center', fontSize: '12px', margin: 0 }}>Точки измерения отсутствуют</p>
        )}
      </div>
    )
  )
}

export default PointsList
