import PlansMainContent from '@/app/plans/components/plans-main-content/plans-main-content'
import PlansToolbar from '@/app/plans/components/plans-toolbar/plans-toolbar'
import LeftBar from '@/components/layouts/left-bar/left-bar'
import ToolBar from '@/components/layouts/tool-bar/tool-bar'
import EquipmentTree from '@/components/widgets/equipment-tree/equipment-tree'
import { EEquipmentTreeMode } from '@/enums/equipments/equipment-tree-mode.enum'
import type { FC } from 'react'
import React from 'react'

const Plans: FC = () => (
  <>
    <ToolBar>
      <PlansToolbar />
    </ToolBar>
    <LeftBar>
      <EquipmentTree equipmentTreeMode={EEquipmentTreeMode.PLAN} />
    </LeftBar>
    <PlansMainContent />
  </>
)

export default Plans
