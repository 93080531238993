import { chartViewModeAmplitudeOptions } from '@/app/condition/components/results/components/charts/components/chart-spectrum/components/chart-spectrum-panel/chart-spectrum-panel.constant'
import { mapUnitType } from '@/constants/measurement/unit.constant'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { TExpressSectionResult } from '@/store/reducers/charts/express-section.slice'
import classNames from 'classnames'
import type { FC, LegacyRef } from 'react'
import { shallowEqual } from 'react-redux'

import styles from '../../../../express-sidebar.module.css'

import ExpressSectionResultItem from '../express-section-result-item/express-section-result-item'

interface IProps {
  result: TExpressSectionResult
  refSectionListItem: LegacyRef<HTMLLIElement> | null
  onClickExpressSectionButton: () => void
  currentIndex: number
}

const ExpressSectionListItem: FC<IProps> = ({
  result,
  refSectionListItem,
  onClickExpressSectionButton,
  currentIndex
}) => {
  const { data, title, unitType, amplitudeMode } = result
  const { sections, selectedResultIndex } = useTypedSelector(
    (state) => ({
      ...state.expressSectionReducer,
      ...state.chartsUiReducer
    }),
    {
      equalityFn: shallowEqual
    }
  )

  const titleWithoutMeasurementName = title
    .split(' ')
    .filter((_, index) => index !== 1)
    .join(' ')

  const amplitudeModeLabel = chartViewModeAmplitudeOptions.find((item) => item.value === amplitudeMode)?.label

  return (
    <li ref={refSectionListItem} className={styles['list-item']}>
      <button onClick={onClickExpressSectionButton} type={'button'} className={styles.button}>
        <h3
          className={classNames(styles['list-header'], styles['list-header-bg'], {
            [styles['list-header-active']]: selectedResultIndex === currentIndex
          })}
        >
          {`${titleWithoutMeasurementName} [${mapUnitType[unitType]}][${amplitudeModeLabel}]`}
        </h3>

        <ul className={styles.list}>
          {data.map((dataItem, index) => (
            <ExpressSectionResultItem
              key={index}
              skz={dataItem.skz}
              am={dataItem.am}
              fm={dataItem.fm}
              index={index}
              fl={sections[index].fl}
              fh={sections[index].fh}
            />
          ))}
        </ul>
      </button>
    </li>
  )
}

export default ExpressSectionListItem
