import ModalServer from '@/app/settings/components/server-settings/components/modal-add-server/modal-server'
import ServersSelect from '@/app/settings/components/server-settings/components/servers-select/servers-select'
import { EModeModal } from '@/app/settings/components/server-settings/server-settings.type'
import ButtonPrimary from '@/components/controlers/button-primary/button-primary'
import TextStatic from '@/components/controlers/form-controls/text-static/text-static'
import ContentWrapper from '@/components/controlers/panel/content-wrapper/content-wrapper'
import PanelTitle from '@/components/controlers/panel/panel-title/panel-title'
import SwitchIndicator from '@/components/controlers/switch-indicator/switch-indicator'
import TextInfo from '@/components/controlers/text-info/text-info'
import ButtonsWrapper from '@/components/wrappers/buttons-wrapper/buttons-wrapper'
import ControlsWrapper from '@/components/wrappers/controls-wrapper/controls-wrapper'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useLazyGetInfoAnotherUrlQuery } from '@/store/api/info.api'
import { serverStorage } from '@/store/local-storage/server.storage'
import type { IServer } from '@/types/settings/server/server.interface'
import { Form } from 'antd'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'

import styles from './server-settings.module.css'

export const ServerSettings: FC = () => {
  const { setServer, setModalSettings } = useActions()
  const { isDesktop, server, modalSettings } = useTypedSelector((state) => state.serverSettingsReducer)
  const [triggerGetInfo, { isSuccess }] = useLazyGetInfoAnotherUrlQuery()
  const [servers, setServers] = useState<IServer[]>([])
  const [selectedServer, setSelectedServer] = useState<IServer | null>(null)

  const connectionStatus = (
    <SwitchIndicator sourceBoolean={isSuccess} trueContent={'Сервер онлайн'} falseContent={'Сервер оффлайн'} />
  )

  const isSelectedActiveServer = server.id === selectedServer?.id

  useEffect(() => {
    const activeServer = serverStorage.getActiveServer()
    setServers(serverStorage.getServers())
    setServer(activeServer)
    setSelectedServer(activeServer)
  }, [setServer])

  const handleSetActiveServerFinish = async () => {
    if (!selectedServer) {
      return
    }

    serverStorage.setActiveServer(selectedServer)
    setServer(selectedServer)
  }

  const handleServerSelected = async (selectedServerValue: IServer) => {
    setSelectedServer(selectedServerValue)
    try {
      await triggerGetInfo({ url: selectedServerValue.url }).unwrap()
    } catch (e) {
      console.error(e)
    }
  }

  const handleCloseModal = () => {
    setModalSettings({ opened: false })
  }

  const handleServersAction = async (value: IServer) => {
    switch (modalSettings.mode) {
      case EModeModal.ADD:
        setServers((prev) => {
          const newServers = [...prev, value]
          serverStorage.setServers(newServers)
          return newServers
        })
        setSelectedServer(value)
        break
      case EModeModal.EDIT:
        setServers((prev) => {
          const index = prev.findIndex((item) => item.id === value.id)
          if (index === -1) {
            serverStorage.setServers(prev)
            return prev
          }

          const newServers = [...prev.slice(0, index), value, ...prev.slice(index + 1)]
          serverStorage.setServers(newServers)
          return newServers
        })
        setSelectedServer(value)
        break
      case EModeModal.DELETE:
        setServers((prev) => {
          const newServers = prev.filter((item) => item.id !== value.id)
          serverStorage.setServers(newServers)
          return newServers
        })
        setSelectedServer(server)
        break
    }
    setModalSettings({ opened: false })
    try {
      await triggerGetInfo({ url: value.url }).unwrap()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      {!isDesktop ? (
        <TextInfo> Настройки подключения к серверу доступны только в настольном приложении</TextInfo>
      ) : (
        <ContentWrapper>
          <Form className={styles['container']} onFinish={handleSetActiveServerFinish}>
            <ControlsWrapper>
              <PanelTitle>Список серверов</PanelTitle>
              <ServersSelect
                servers={servers}
                selectedServer={selectedServer}
                onSelect={handleServerSelected}
                activatedServer={server}
              />
            </ControlsWrapper>
            <ControlsWrapper>
              <PanelTitle>Информация о сервере</PanelTitle>
              <TextStatic label='Адрес сервера' value={selectedServer?.url} />

              <TextStatic label='Имя сервера' value={selectedServer?.name} />
              <TextStatic label='Статус' value={connectionStatus} />
            </ControlsWrapper>
            <ControlsWrapper>
              <ButtonsWrapper>
                <ButtonPrimary htmlType={'submit'} title='Установить подключение' />
              </ButtonsWrapper>
            </ControlsWrapper>
          </Form>
        </ContentWrapper>
      )}

      <ModalServer
        modalOpened={modalSettings}
        onClose={handleCloseModal}
        onUpdateServers={handleServersAction}
        servers={servers}
        selectedServer={selectedServer}
      />
    </>
  )
}

export default ServerSettings
