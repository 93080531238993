import Generator from '@/app/configuration/components/devices/generator/generator'
import Vp3701Panel from '@/app/configuration/components/devices/vp3701-panel/vp3701-panel'
import Vp3711ChannelPanel from '@/app/configuration/components/devices/vp3711-channel-panel/vp3711-channel-panel'
import Vp3711ModulePanel from '@/app/configuration/components/devices/vp3711-module-panel/vp3711-module-panel'
import Vp3711Panel from '@/app/configuration/components/devices/vp3711-panel/vp3711-panel'
import { EDeviceContent } from '@/enums/device/device-content.enum'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'
import React from 'react'

const Devices: FC = () => {
  const { deviceContent, selectedDeviceId } = useTypedSelector((state) => state.devicesReducer)
  const isVp3701DeviceContent = deviceContent === EDeviceContent.VP_3701_DEVICE
  const isVp3711DeviceContent = deviceContent === EDeviceContent.VP_3711_DEVICE
  const isVp3711ModuleContent = deviceContent === EDeviceContent.VP_3711_MODULE
  const isVp3711ChannelContent = deviceContent === EDeviceContent.VP_3711_CHANNEL
  const isGeneratorDeviceContent = deviceContent === EDeviceContent.GENERATOR_DEVICE

  return (
    selectedDeviceId && (
      <>
        {isVp3701DeviceContent && <Vp3701Panel deviceId={selectedDeviceId} />}
        {isGeneratorDeviceContent && <Generator deviceId={selectedDeviceId} />}
        {isVp3711DeviceContent && <Vp3711Panel deviceId={selectedDeviceId} />}
        {isVp3711ModuleContent && <Vp3711ModulePanel deviceId={selectedDeviceId} />}
        {isVp3711ChannelContent && <Vp3711ChannelPanel deviceId={selectedDeviceId} />}
      </>
    )
  )
}

export default Devices
