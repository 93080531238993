import type { TGroupForm } from '@/app/configuration/components/equipments/components/group/group-form.type'
import ButtonPrimary from '@/components/controlers/button-primary/button-primary'
import ContentWrapper from '@/components/controlers/panel/content-wrapper/content-wrapper'
import FormItemApp from '@/components/controlers/panel/form-item-mcm/form-item-app'
import GroupIcon from '@/components/icons/equipments/group-icon'
import { KEY_ROOT } from '@/components/widgets/equipment-tree/equipment-tree.constant'
import HeaderConfiguration from '@/components/widgets/header-work-space/header-configuration'
import ButtonsWrapper from '@/components/wrappers/buttons-wrapper/buttons-wrapper'
import ControlsWrapper from '@/components/wrappers/controls-wrapper/controls-wrapper'
import { ButtonTitle } from '@/constants/core/common.constant'
import { GroupProperty } from '@/constants/group/group.constant'
import { EEquipmentMode } from '@/enums/equipments/equipment-mode.enum'
import { EEquipmentTree } from '@/enums/equipments/equipment-tree.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import {
  useCreateGroupMutation,
  useGetGroupQuery,
  useUpdateGroupMutation,
  useUpdateGroupPausedMutation
} from '@/store/api/grpups.api'
import type { IGroup, TUpdateGroup } from '@/types/group/group.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Form, Input, Switch } from 'antd'
import { useForm } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'

import styles from '@/app/configuration/components/equipments/components/group/group.module.css'

const Group: FC = () => {
  const { selectedGroupId: groupId } = useTypedSelector((state) => state.globalReducer)
  const { equipmentMode } = useTypedSelector((state) => state.equipmentsUiReducer)
  const { setEquipmentMode } = useActions()
  const [form] = useForm<TGroupForm>()
  const [savedData, setSavedData] = useState<IGroup | null>(null)
  const isRoot = KEY_ROOT === groupId
  const { data: groupElement } = useGetGroupQuery(
    { groupId: groupId },
    { skip: !groupId || isRoot, refetchOnMountOrArgChange: true }
  )
  const [triggerCreateGroup, { isLoading: isLoadingCreate }] = useCreateGroupMutation()
  const [triggerUpdateGroupPaused, { isLoading: isLoadingPaused }] = useUpdateGroupPausedMutation()
  const [triggerUpdateGroup, { isLoading: isLoadingUpdate }] = useUpdateGroupMutation()
  const idLoadingForm = isLoadingCreate || isLoadingUpdate || isLoadingPaused
  const { updateTreeMenuItem, updateTreeMenuTitle, setSelectedTreeItems, setSelectedGroupId } = useActions()
  const isAddMode = equipmentMode === EEquipmentMode.ADD
  const isEditMode = equipmentMode === EEquipmentMode.DEFAULT
  const title = isAddMode ? 'Добавление новой группы' : `Группа ${groupElement?.name}`

  useEffect(() => {
    if (groupElement && isEditMode) {
      const data = { ...groupElement, paused: !groupElement.paused }
      form.setFieldsValue(data)
      setSavedData(data)
      return
    }

    if (isAddMode) {
      form.resetFields()
      setSavedData(null)
    }
  }, [form, groupElement, isAddMode, isEditMode])

  if (isRoot && isEditMode) {
    return null
  }

  const handleGroupFinish = async () => {
    if (isAddMode) {
      const fields = form.getFieldsValue()
      const parentId = isRoot ? null : groupId
      try {
        const { id } = await triggerCreateGroup({ ...fields, parentId }).unwrap()

        successNotificationCreate('Группа успешно добавлена')

        updateTreeMenuItem({
          id,
          name: fields.name as string,
          group: EEquipmentTree.GROUPS,
          parentKey: groupId || KEY_ROOT,
          icon: GroupIcon
        })

        setSelectedTreeItems([id])
        setSelectedGroupId(id)
        setEquipmentMode(EEquipmentMode.DEFAULT)
      } catch (e) {
        errorNotificationCreate(e)
      }
    }

    if (isEditMode && groupId && groupElement) {
      try {
        const data = form.getFieldsValue()
        const updatePayload: TUpdateGroup = { ...groupElement, ...data }
        await triggerUpdateGroup({ data: updatePayload, groupId: groupId }).unwrap()
        updateTreeMenuTitle({
          id: groupId,
          newTitle: data.name as string
        })
        successNotificationCreate('Группа успешно обновлена')
      } catch (e) {
        errorNotificationCreate(e)
      }
    }
  }

  const handleChangePaused = async () => {
    if (groupId && isEditMode) {
      const paused = form.getFieldValue(GroupProperty.Paused)
      const name = form.getFieldValue(GroupProperty.Name)
      const activated = !paused

      try {
        await triggerUpdateGroupPaused({ data: { paused: activated }, groupId: groupId }).unwrap()
      } catch (error) {
        errorNotificationCreate(error)
      }
    }
  }

  const resetForm = () => {
    if (savedData) {
      form.setFieldsValue(savedData)
      return
    }

    form.resetFields()
  }

  return (
    <>
      <HeaderConfiguration content={title} />
      <ContentWrapper>
        <Form
          disabled={idLoadingForm}
          form={form}
          className={styles.container}
          layout={'horizontal'}
          onFinish={handleGroupFinish}
        >
          <ControlsWrapper>
            <FormItemApp
              name={GroupProperty.Name}
              label='Наименование'
              rules={[{ required: true, message: 'Поле обязательно' }]}
            >
              <Input placeholder='Введите наименование' />
            </FormItemApp>
            <FormItemApp name={GroupProperty.FullName} label='Полное наименование'>
              <Input placeholder='Введите полное наименование' />
            </FormItemApp>
            <FormItemApp name={GroupProperty.Location} label='Расположение группы'>
              <Input placeholder='Введите расположение' />
            </FormItemApp>
            <FormItemApp name={GroupProperty.Comment} label='Комментарий'>
              <TextArea placeholder='Введите комментарий' className={styles['text-area']} />
            </FormItemApp>
          </ControlsWrapper>
          {isEditMode && (
            <ControlsWrapper>
              <FormItemApp name={GroupProperty.Paused} label='Активация группы'>
                <Switch loading={isLoadingPaused} onChange={handleChangePaused} />
              </FormItemApp>
            </ControlsWrapper>
          )}
          <ControlsWrapper className={styles['buttons-wrapper']}>
            <ButtonsWrapper>
              <ButtonPrimary
                htmlType='button'
                onClick={resetForm}
                title={ButtonTitle.Reset}
                isLoadingSkeleton={false}
                isLoading={false}
                disabled={false}
              />
              <ButtonPrimary htmlType='submit' title={ButtonTitle.Save} isLoading={idLoadingForm} />
            </ButtonsWrapper>
          </ControlsWrapper>
        </Form>
      </ContentWrapper>
    </>
  )
}
export default Group
