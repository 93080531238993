import EquipmentIcons from '@/components/widgets/equipment-icons/equipment-icons'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IEquipmentElement } from '@/types/equipment/equipment-element.interface'
import cn from 'classnames'
import type { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import styles from './equipment-item.module.css'

type TProps = {
  element: IEquipmentElement
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const EquipmentItem: FC<TProps> = ({ element, ...props }) => {
  const { id, name, showThresholdIndicator, showStatisticalIndicator, equipmentIcon, isNavigationElement } = element
  const { selectedMachineId, selectedPointId, selectedMeasurementId, selectedGroupId } = useTypedSelector(
    (state) => state.globalReducer
  )
  const isSelected = [selectedMachineId, selectedPointId, selectedGroupId, selectedMeasurementId].some(
    (selectedId) => selectedId === id
  )

  return (
    <div
      {...props}
      role='button'
      className={cn(styles['equipment-item'], {
        [styles['equipment-item-active']]: isSelected && isNavigationElement
      })}
      data-id={id}
    >
      <EquipmentIcons
        equipmentIcon={equipmentIcon}
        id={id}
        showStatisticalIndicator={showStatisticalIndicator}
        showThresholdIndicator={showThresholdIndicator}
      />
      <p className={styles['text']}>{name}</p>
    </div>
  )
}

export default EquipmentItem
