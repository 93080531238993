import MeasurementIcon from '@/components/icons/equipments/measurement-icon'
import EquipmentItem from '@/components/widgets/equipment-item/equipment-item'
import Spinner from '@/components/widgets/spinner/spinner'
import { configCharts } from '@/constants/chart/charts.constant'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMeasurementsQuery } from '@/store/api/measurements.api'
import { getTypeMeasurement } from '@/utils/measurement/get-type-measurement'
import type { ReactEventHandler } from 'react'

import styles from './measurements-list.module.css'

const MeasurementsList = () => {
  const { setSelectedMeasurementId, setConfigChart, addExpendedEquipment } = useActions()
  const { selectedMachineId, selectedPointId } = useTypedSelector((state) => state.globalReducer)
  const { data: measurementsResponse, isFetching } = useGetMeasurementsQuery(selectedPointId, {
    skip: !selectedPointId
  })

  const measurements = measurementsResponse?.content

  if (isFetching) {
    return <Spinner />
  }

  const handleMeasurementClick: ReactEventHandler<HTMLDivElement> = (evt) => {
    const measurementId = evt.currentTarget.getAttribute('data-id')
    if (measurementId) {
      setSelectedMeasurementId(measurementId)
      addExpendedEquipment(measurementId)

      const foundMeasurement = measurements?.find((measurement) => measurement.measurementId === measurementId)
      const typeMeasurement = getTypeMeasurement(foundMeasurement)
      if (typeMeasurement) {
        setConfigChart(configCharts[typeMeasurement])
      }
    }
  }

  return (
    selectedPointId &&
    selectedMachineId && (
      <div className={styles.container}>
        {measurements?.map((measurement, index) => (
          <EquipmentItem
            key={measurement.measurementId}
            element={{
              id: measurement.measurementId,
              name: measurement.name,
              equipmentIcon: <MeasurementIcon />,
              showThresholdIndicator: true,
              isNavigationElement: true
            }}
            onClick={handleMeasurementClick}
          />
        ))}
        {measurements?.length === 0 && (
          <p style={{ textAlign: 'center', fontSize: '12px', margin: 0 }}>Виды измерения отсутствуют</p>
        )}
      </div>
    )
  )
}

export default MeasurementsList
