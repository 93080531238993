import ServerSettings from '@/app/settings/components/server-settings/server-settings'
import { SettingsRoute } from '@/constants/navigation/routes.constant'
import type { RouteObject } from 'react-router'

const settingsRoute: RouteObject[] = [
  {
    path: SettingsRoute.Server,
    element: <ServerSettings />
  }
]

export default settingsRoute
