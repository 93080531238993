import ConnectionPanel from '@/app/configuration/components/connections/components/connection-panel/connection-panel'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'

const Connections: FC = () => {
  const { selectedPointId } = useTypedSelector((state) => state.globalReducer)
  const { selectedDeviceId, deviceContent } = useTypedSelector((state) => state.devicesReducer)
  return <ConnectionPanel pointId={selectedPointId} deviceId={selectedDeviceId} deviceContent={deviceContent} />
}

export default Connections
