import { mapPhysicalQuantity } from '@/constants/measurement/unit.constant'
import type { EUnitType } from '@/enums/measurment/unit-type.enum'
import useGetMeasurement from '@/hooks/api/use-get-measurement.hook'
import { getUnitTypeOptions, getUnitTypeOptionsWithoutAssociation } from '@/utils/measurement/get-filter-unit.unit'
import { Flex, Select } from 'antd'
import type { FC } from 'react'

import styles from './chart-unit.module.css'

type TProps = {
  onSetUnitChange: (unit: EUnitType) => void
  sourceUnit: EUnitType
  targetUnit: EUnitType
  isVibration?: boolean
}

const ChartUnits: FC<TProps> = ({ onSetUnitChange, sourceUnit, targetUnit, isVibration }) => {
  const { data: measurement } = useGetMeasurement()

  const physicalQuantityType = mapPhysicalQuantity[sourceUnit]
  const unitOptions = isVibration
    ? getUnitTypeOptions(physicalQuantityType)
    : getUnitTypeOptionsWithoutAssociation(physicalQuantityType)

  if (!measurement) {
    return null
  }

  const handleSetUnitChange = (value: string) => {
    const unitValue = value as EUnitType
    onSetUnitChange(unitValue)
  }

  return (
    <Flex align='center' gap={5}>
      <p style={{ margin: 0, fontSize: '14px' }}>Ед. изм.</p>
      <Select value={targetUnit} options={unitOptions} onChange={handleSetUnitChange} className={styles['select']} />
    </Flex>
  )
}

export default ChartUnits
