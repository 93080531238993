import { deviceOptions } from '@/app/plans/components/plans-toolbar/components/popup-plans/plans.constant'
import ButtonPrimary from '@/components/controlers/button-primary/button-primary'
import FormItemApp from '@/components/controlers/panel/form-item-mcm/form-item-app'
import Popup from '@/components/widgets/popup/popup'
import ButtonsWrapper from '@/components/wrappers/buttons-wrapper/buttons-wrapper'
import { DATE_TEMPLATE } from '@/constants/core/common.constant'
import { AddPlanFieldLabel, AddPlanFieldName, controlTypeOptions } from '@/constants/plan/plan.constant'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useCreatePlanMutation } from '@/store/api/plans.api'
import type { IAddPlanForm, TPlan } from '@/types/plan/plan.type'
import { formatDate } from '@/utils/format-date'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Form, Input, Select } from 'antd'
import type { FC } from 'react'
import React, { useCallback } from 'react'

import styles from './popup-plans.module.css'

type TProps = {
  setIsPopupOpen: (value: React.SetStateAction<boolean>) => void
  isPopupOpen: boolean
  onSubmitHandler: (object: TPlan) => void
}

const PopupPlans: FC<TProps> = ({ isPopupOpen, setIsPopupOpen, onSubmitHandler }) => {
  const [form] = Form.useForm<IAddPlanForm>()

  const { plans } = useTypedSelector((state) => state.planReducer)

  const [createPlan, { isLoading: isCreatingPlan }] = useCreatePlanMutation()

  const onResetPlan = useCallback(() => {
    setIsPopupOpen(false)
    form.resetFields()
  }, [form, setIsPopupOpen])

  const onFinishAddPlan = useCallback(async () => {
    try {
      const fieldValues = form.getFieldsValue()

      if (!plans.find((currentPlan) => currentPlan.name === fieldValues.name.trim())) {
        const planResult = await createPlan({
          name: fieldValues.name.trim(),
          planType: fieldValues.planType,
          deviceType: fieldValues.deviceType
        }).unwrap()

        if (!planResult) throw new Error('Маршрут не создан')

        const plan: TPlan = {
          name: fieldValues.name.trim(),
          planType: fieldValues.planType,
          deviceType: fieldValues.deviceType,
          machineIds: [],
          excludedMachineIds: [],
          excludedMeasurementIds: [],
          excludedMeasuringPointIds: [],
          planId: planResult?.id,
          status: 'SENT',
          modified: formatDate(String(Date.now()), DATE_TEMPLATE),
          lastExecutionTimestamp: '---------- --:--:--',
          nextExecutionTimestamp: '---------- --:--:--',
          machines: []
        }

        onSubmitHandler(plan)

        successNotificationCreate('Маршрут добавлен')

        onResetPlan()
      } else {
        errorNotificationCreate({
          status: 'Маршрут уже существует!'
        })
      }
    } catch (error) {
      errorNotificationCreate(error)
    }
  }, [createPlan, form, onResetPlan, onSubmitHandler, plans])

  const handleClosePopup = () => {
    setIsPopupOpen(false)
  }

  return (
    <Popup className={styles['popup']} title={'Добавление маршрута'} isOpen={isPopupOpen} onCancel={handleClosePopup}>
      <Form
        form={form}
        name={'plan'}
        onReset={onResetPlan}
        onFinish={onFinishAddPlan}
        className={styles.form}
        disabled={isCreatingPlan}
      >
        <FormItemApp
          name={AddPlanFieldName.Name}
          label={AddPlanFieldLabel.Name}
          rules={[{ required: true, message: 'Поле обязательно' }]}
        >
          <Input placeholder='Введите наименование маршрута' />
        </FormItemApp>
        <FormItemApp
          name={AddPlanFieldName.DeviceType}
          label={AddPlanFieldLabel.DeviceType}
          rules={[{ required: true, message: 'Необходимо выбрать тип устройства' }]}
        >
          <Select options={deviceOptions} placeholder='Выберите тип устройства' />
        </FormItemApp>
        <FormItemApp
          name={AddPlanFieldName.PlanType}
          label={AddPlanFieldLabel.PlanType}
          rules={[{ required: true, message: 'Необходимо выбрать тип маршрут' }]}
        >
          <Select options={controlTypeOptions} placeholder='Выберите тип маршрута' />
        </FormItemApp>
        <ButtonsWrapper>
          <ButtonPrimary htmlType={'reset'} title={'ОТМЕНА'} />
          <ButtonPrimary htmlType={'submit'} title={'ДОБАВИТЬ'} loading={isCreatingPlan} />
        </ButtonsWrapper>
      </Form>
    </Popup>
  )
}

export default PopupPlans
