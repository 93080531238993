import MeasurementIcon from '@/components/icons/equipments/measurement-icon'
import ThresholdIndicator from '@/components/widgets/danger-state-indicator/threshold-indicator'
import EquipmentItem from '@/components/widgets/equipment-item/equipment-item'
import type { INodesState } from '@/types/nodes/nodes.type'
import type { FC } from 'react'

interface IProps {
  name: string
  paused: boolean
  id: string
  dataAllNodesState?: INodesState[]
}

const PlanMeasurementItem: FC<IProps> = ({ name, paused, dataAllNodesState, id }) => (
  <EquipmentItem
    element={{
      id,
      name,
      paused,
      indicator: <ThresholdIndicator id={id} />,
      equipmentIcon: <MeasurementIcon />
    }}
  />
)

export default PlanMeasurementItem
