import { AppRoute, ConditionRoute } from '@/constants/navigation/routes.constant'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

const Home: FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(`${AppRoute.Condition}/${ConditionRoute.Description}`)
  }, [navigate])
  return <></>
}

export default Home
