import {
  getDiapason,
  getRangeTimeValues,
  getRangeValues,
  getThinnedData
} from '@/app/condition/components/results/components/charts/components/chart-timing/chart-timing.service'
import TooltipTiming from '@/app/condition/components/results/components/charts/components/chart-timing/components/tooltip-timing/tooltip-timing'
import { searchStrobeBinary } from '@/app/condition/components/results/components/charts/components/chart-timing/helpers/search-strobe-binary.helper'
import ChartsTools from '@/app/condition/components/results/components/charts/components/chart-tools/charts-tools'
import ChartUnits from '@/app/condition/components/results/components/charts/components/chart-units/chart-units'
import ChartWrapper from '@/app/condition/components/results/components/charts/components/chart-wrapper/chart-wrapper'
import SliderApp from '@/app/condition/components/results/components/charts/components/slider-app/slider-app'
import type { EUnitType } from '@/enums/measurment/unit-type.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMeasurementQuery } from '@/store/api/measurements.api'
import type { TStrobeIndexes } from '@/store/reducers/charts/express-strobe.slice'
import type { IChartTiming } from '@/types/chart/charts.type'
import type { TStrobeOptions } from '@/types/express-strobe.type'
import { chartFormatValue } from '@/utils/chart/chart-format-value'
import { generateGraphTicks } from '@/utils/chart/generate-graph-ticks'
import { getStepSlider } from '@/utils/chart/get-step-slider'
import { unitConverter } from '@/utils/unit/unit-converter.util'
import { Flex } from 'antd'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import type { FC, LegacyRef } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'

import calculateAs from '../express-sidebar/helpers/calculate-as.helper'
import calculateEx from '../express-sidebar/helpers/calculate-ex.helper'
import calculatePK from '../express-sidebar/helpers/calculate-pk.helper'
import calculateSKZ from '../express-sidebar/helpers/calculate-skz.helper'
import LegendStrobe from './components/legend-strobe/legend-strobe'
import { renderFrontStrobes, renderMainStrobes } from './helpers/render-strobes.helper'
import useStrobeData from './hooks/use-strobe-data'

type ChartTimingProps = {
  title: string
  data: number[]
  durationInSec?: number
  resultId: string
  index: number
  handleClickOnChart: () => void
  chartButtonRef: LegacyRef<HTMLButtonElement> | null
}

export const sliceArrayByPeriod = (lastValue: number | null, period: number, start: number) => {
  if (!lastValue) {
    return null
  }
  const result = []

  for (let i = start; i < lastValue; i += period) {
    result.push(i)
  }

  return result
}

const ChartTiming: FC<ChartTimingProps> = ({
  title,
  data,
  durationInSec,
  resultId,
  index,
  handleClickOnChart,
  chartButtonRef
}) => {
  const { pushStrobeIndexes } = useActions()
  const { selectedMeasurementId } = useTypedSelector((state) => state.globalReducer)
  const { data: measurement } = useGetMeasurementQuery(selectedMeasurementId, {
    skip: !selectedMeasurementId
  })
  const { isStrobeExpressBarOpen } = useTypedSelector((state) => state.expressStrobeReducer)
  const sourceUnit = measurement?.unitType || null
  const [targetUnit, setTargetUnit] = useState<EUnitType | null>(sourceUnit)

  const {
    setIsResizeDuration,
    setIsAreaDrag,
    setIsResizePeriod,
    strobeData,
    onMouseLeave,
    onMouseMove,
    onMouseUp,
    refIndexStrobe,
    isAreaDrag,
    isResizeDuration,
    isResizePeriod
  } = useStrobeData(index)

  const isHandlerActive = isAreaDrag || isResizeDuration || isResizePeriod
  const [sliderDisabled, setSliderDisabled] = useState(true)

  const [sliderData, setSliderData] = useState<number[] | null>([1, data.length - 1])
  const [sliderValues, setSliderValues] = useState<number[] | null>(null)
  const [sliderIndexes, setSliderIndexes] = useState<number[] | null>(null)

  const refChartBlock = useRef<HTMLDivElement | null>(null)
  const diapason = getDiapason(refChartBlock)

  const rangeData = useMemo<number[]>(() => (data ? [1, data.length - 1] : [0, 0]), [data])

  const chartData = useMemo<IChartTiming[] | null>(() => {
    if (durationInSec && diapason && sliderData) {
      return getThinnedData(data, diapason, sliderData, durationInSec, sourceUnit, targetUnit)
    }

    return null
  }, [durationInSec, diapason, sliderData, data, sourceUnit, targetUnit])

  const rangeIndexes = useMemo<number[] | null>(() => (chartData ? [1, chartData.length - 1] : null), [chartData])

  const rangeValues = useMemo<number[] | null>(
    () => (chartData ? getRangeValues(chartData, [1, chartData.length - 1]) : null),
    [chartData]
  )

  const rangeTimeValues = useMemo<number[] | null>(
    () => getRangeTimeValues(chartData, sliderIndexes),
    [chartData, sliderIndexes]
  )

  useEffect(() => {
    if (rangeIndexes && !sliderIndexes) {
      setSliderIndexes(rangeIndexes)
    }
  }, [rangeIndexes, sliderIndexes])

  useEffect(() => {
    if (!sliderValues && !sliderValues) {
      setSliderValues(rangeValues)
    }
  }, [rangeValues, sliderValues])

  const stepY = getStepSlider(20, rangeValues)
  const ticksTimeValues = generateGraphTicks(rangeTimeValues, 14)
  const ticksValues = generateGraphTicks(sliderValues, 8)

  const slicedChartData = useMemo(
    () =>
      chartData ? sliceArrayByPeriod(chartData[chartData.length - 1].time, strobeData.period, strobeData.start) : null,
    [chartData, strobeData.period, strobeData.start]
  )

  const skz = useMemo(() => calculateSKZ(data), [data])
  const pk = useMemo(() => calculatePK(data), [data])
  const as = useMemo(() => calculateAs(data), [data])
  const ex = useMemo(() => calculateEx(data), [data])

  // Функция для работы стробов написанная Денисом Ложкиным, в будущем необходимо будет провести рефакторинг кода
  const calculateStrobeIndexes = useCallback(
    (strobeDataCurrent?: TStrobeOptions) => {
      if (!rangeTimeValues || !chartData) {
        return null
      }

      const discrete = Number(measurement?.inputSignalDescription?.actualSamplingRateValue)
      let strobeDataTemp: Omit<TStrobeOptions, 'offset'> = {
        start: (1 / discrete) * 1000,
        period: (1 / discrete) * 1000,
        duration: ((1 / discrete) * 1000) / 2
      }

      if (strobeDataCurrent) {
        strobeDataTemp = strobeDataCurrent
      }

      const lastTime = rangeTimeValues[1]

      const slicedIndexes = sliceArrayByPeriod(lastTime, strobeDataTemp.period, strobeDataTemp.start)
      if (!slicedIndexes) {
        return null
      }

      const strobeIndexes: TStrobeIndexes = []

      for (let i = 0; i < slicedIndexes.length; i++) {
        const time = slicedIndexes[i]

        const startIndexStrobe = searchStrobeBinary(chartData, time, 0, chartData.length - 1)?.sourceIndex

        const endIndexStrobe = searchStrobeBinary(
          chartData,
          time + strobeDataTemp.duration,
          0,
          chartData.length - 1
        )?.sourceIndex

        if (startIndexStrobe === undefined || endIndexStrobe === undefined) {
          continue
        }

        strobeIndexes.push([startIndexStrobe, endIndexStrobe])
      }

      pushStrobeIndexes({
        strobeData: strobeDataTemp,
        title: title,
        strobeIndexes
      })
    },
    [rangeTimeValues, chartData, measurement?.inputSignalDescription?.actualSamplingRateValue, pushStrobeIndexes, title]
  )

  useEffect(() => {
    if (isStrobeExpressBarOpen) {
      calculateStrobeIndexes()
    }
  }, [calculateStrobeIndexes, isStrobeExpressBarOpen])

  const handleResetIndexesDoubleClick = () => {
    if (rangeIndexes) {
      setSliderIndexes(rangeIndexes)
    }
  }

  const handleResetDataDoubleClick = () => {
    setSliderData(rangeData)
  }

  const handleResetValueDoubleClick = () => {
    if (rangeValues && !sliderDisabled) {
      setSliderValues(rangeValues)
    }
  }

  const handleSliderDisableCheckboxChange = (evt: CheckboxChangeEvent) => {
    const notChecked = !evt.target.checked
    setSliderDisabled(notChecked)
    if (notChecked && sliderIndexes) {
      setSliderValues(getRangeValues(chartData, sliderIndexes))
      return
    }
    setSliderValues(getRangeValues(chartData, rangeIndexes))
  }

  const handleSliderDataChange = (range: number[]) => {
    const [minValue, maxValue] = range
    const limitRange = maxValue - minValue
    if (diapason && limitRange <= diapason * 2) {
      return
    }
    setSliderData(range)
  }

  const handleUnitChange = (unit: EUnitType) => {
    setTargetUnit(unit)
    setSliderValues((prev) => (prev && targetUnit ? prev.map((item) => unitConverter(item, targetUnit, unit)) : null))
  }

  return (
    <ChartWrapper
      onChartClick={handleClickOnChart}
      title={title}
      chartRef={refChartBlock}
      resultId={resultId}
      chartButtonRef={chartButtonRef}
    >
      <Flex>
        <SliderApp
          range={{
            draggableTrack: true
          }}
          min={rangeValues ? rangeValues[0] : 0}
          max={rangeValues ? rangeValues[1] : 0}
          value={sliderValues || [0, 0]}
          vertical={true}
          step={stepY}
          tooltip={{ formatter: chartFormatValue }}
          onChange={setSliderValues}
          onDoubleClick={handleResetValueDoubleClick}
          onCheckboxChange={handleSliderDisableCheckboxChange}
          disabled={sliderDisabled}
        />
        <Flex flex={1} vertical={true} gap={5}>
          <ChartsTools>
            {sourceUnit && targetUnit && (
              <ChartUnits sourceUnit={sourceUnit} onSetUnitChange={handleUnitChange} targetUnit={targetUnit} />
            )}
          </ChartsTools>
          <SliderApp
            range={{ draggableTrack: true }}
            min={rangeData ? rangeData[0] : 0}
            max={rangeData ? rangeData[1] : 0}
            value={sliderData || [0, 0]}
            onChange={handleSliderDataChange}
            onDoubleClick={handleResetDataDoubleClick}
            borderBottom={true}
          />
          <ResponsiveContainer height={500}>
            <ComposedChart
              onMouseLeave={
                isHandlerActive
                  ? () => {
                      onMouseLeave()
                      calculateStrobeIndexes(strobeData)
                    }
                  : undefined
              }
              onMouseUp={
                isHandlerActive
                  ? () => {
                      onMouseUp && onMouseUp()
                      calculateStrobeIndexes(strobeData)
                    }
                  : undefined
              }
              onMouseMove={isHandlerActive ? onMouseMove : undefined}
              data={chartData || []}
            >
              <XAxis
                dataKey='time'
                type='number'
                domain={rangeTimeValues || undefined}
                axisLine={true}
                ticks={ticksTimeValues || undefined}
                tick={{ fill: 'black' }}
                tickFormatter={(time) => String(Number(Number(time)?.toFixed(2)))}
                allowDataOverflow={true}
              />
              <YAxis
                dataKey='value'
                axisLine={true}
                ticks={ticksValues || undefined}
                tickMargin={5}
                tickFormatter={(value) => String(Number(value.toFixed(2)))}
                tickLine={true}
                allowDataOverflow={true}
                tick={{ fill: 'black' }}
                domain={sliderValues || [0, 0]}
              />

              {isStrobeExpressBarOpen && sliderIndexes ? (
                <ReferenceArea
                  x1={chartData?.[sliderIndexes[0]]?.time}
                  x2={chartData?.[sliderIndexes[1]]?.time}
                  ifOverflow='visible'
                  fillOpacity={0.8}
                  fill={'#444444'}
                />
              ) : null}

              <CartesianGrid strokeDasharray='3 3' />

              {isStrobeExpressBarOpen && chartData && slicedChartData
                ? renderFrontStrobes({
                    chartData,
                    slicedChartData,
                    strobeData,
                    sliderValuesHorizontal: sliderIndexes || [0, 0]
                  })
                : null}

              <Line isAnimationActive={false} dot={false} dataKey='value' stroke='#444444' strokeWidth={1} />

              {isStrobeExpressBarOpen && chartData && slicedChartData
                ? renderMainStrobes({
                    slicedChartData,
                    chartData,
                    strobeData,
                    sliderValuesHorizontal: sliderIndexes || [0, 0],
                    refIndexStrobe,
                    setIsAreaDrag,
                    setIsResizeDuration,
                    setIsResizePeriod
                  })
                : null}

              {isStrobeExpressBarOpen ? (
                <Legend
                  align='right'
                  verticalAlign='top'
                  content={<LegendStrobe skz={skz} pk={pk} ex={ex} as={as} />}
                />
              ) : null}

              <Tooltip content={<TooltipTiming />} isAnimationActive={false} />
            </ComposedChart>
          </ResponsiveContainer>
          <SliderApp
            onDoubleClick={handleResetIndexesDoubleClick}
            min={rangeIndexes ? rangeIndexes[0] : 0}
            max={rangeIndexes ? rangeIndexes[1] : 0}
            value={sliderIndexes || [0, 0]}
            onChange={setSliderIndexes}
            range={{ draggableTrack: true }}
          />
        </Flex>
      </Flex>
    </ChartWrapper>
  )
}

export default ChartTiming
