/**
minValue минимальное значение,

maxValue максимальное значение,

maxCountOfLines максимальное количество линий шкалы (оно же оптимальное)
*/
export const generateGraphTicks = (rangeData: number[] | null, maxCountOfLines: number): number[] | null => {
  if (!rangeData) {
    return null
  }

  const [minValue, maxValue] = rangeData

  const ticks = []
  const firstStep = []
  const stepCount = []
  const degree = []
  const stepBase = [1, 2, 5]
  const stepMin = (maxValue - minValue) / maxCountOfLines

  let stepTemp = 0
  let walkPosition = 0
  let selectedBase = 0

  for (let i = 0; i <= 2; i++) {
    degree[i] = -15

    while (stepBase[i] * Math.pow(10, degree[i]) <= stepMin) {
      degree[i]++
    }

    stepTemp = stepBase[i] * Math.pow(10, degree[i])
    stepCount[i] = 0
    firstStep[i] = Math.ceil(minValue / stepTemp)
    walkPosition = stepTemp * firstStep[i]

    while (walkPosition < maxValue) {
      walkPosition += stepTemp

      if (walkPosition < maxValue) {
        stepCount[i]++
      }
    }

    if (stepCount[i] >= maxCountOfLines) {
      stepCount[i] = -1
    }
  }

  if (stepCount[1] > stepCount[0] && stepCount[1] > stepCount[2]) {
    selectedBase = 1
  }
  if (stepCount[2] > stepCount[0] && stepCount[2] > stepCount[1]) {
    selectedBase = 2
  }
  if (stepCount[0] > stepCount[1] && stepCount[0] > stepCount[2]) {
    selectedBase = 0
  }

  const lastStep = stepCount[selectedBase]

  for (let i = 0; i <= lastStep; i++) {
    const b = (firstStep[selectedBase] + i) * stepBase[selectedBase] * Math.pow(10, degree[selectedBase])

    if (degree[selectedBase] < 0) {
      ticks[i] = Number(b.toFixed(-degree[selectedBase]))
    } else {
      ticks[i] = b
    }
  }

  return ticks
}

/*
export const generateGraphTicksLog = (
  minValue: number,
  maxValue: number
): number[] => {
  const convertedMinValue = parseFloat(
    Number(
      Math.floor(minValue / Math.pow(10, Math.floor(Math.log10(minValue)))) *
        Math.pow(10, Math.floor(Math.log10(minValue)))
    )?.toFixed(11)
  )
  const convertedMaxValue = parseFloat(
    Number(
      Math.ceil(maxValue / Math.pow(10, Math.floor(Math.log10(maxValue)))) *
        Math.pow(10, Math.floor(Math.log10(maxValue)))
    )?.toFixed(11)
  )

  const startDegree = Math.floor(Math.log10(convertedMinValue))
  const startLine = Math.ceil(convertedMinValue / Math.pow(10, startDegree))
  const endDegree = Math.floor(Math.log10(convertedMaxValue))
  const endLine = Math.floor(convertedMaxValue / Math.pow(10, endDegree))

  let isDrawLine = false

  const ticks = []

  for (let i = startDegree; i <= endDegree; i++) {
    for (let j = 1; j <= 9; j++) {
      if (startDegree == endDegree) {
        if (j >= startLine && j <= endLine) {
          isDrawLine = true
        } else {
          isDrawLine = false
        }
      } else {
        if (
          (i == startDegree && j >= startLine) ||
          (i > startDegree && i < endDegree) ||
          (i == endDegree && j <= endLine)
        ) {
          isDrawLine = true
        } else {
          isDrawLine = false
        }
      }

      if (isDrawLine) {
        const currentLine = j * Math.pow(10, i)
        ticks.push(currentLine)
      }
    }
  }

  return ticks
}
*/

/**
 * Функция для генерации логарифмической шкалы с метками.
 * @param {number} leftMostValue - Начальное значение на шкале.
 * @param {number} rightMostValue - Конечное значение на шкале.
 * @returns {Array} - Массив значений тиков логарифмической шкалы.
 */
export function generateGraphTicksLog(leftMostValue: number, rightMostValue: number): string[] {
  // Функция для преобразования значения, если оно меньше 1e-10
  function normalizeValue(value: number) {
    return value < 1e-10 ? 1e-10 : value
  }

  // Функция для вычисления начала и конца логарифмического графика
  function computeScaleBound(value: number) {
    const base = Math.pow(10, Math.floor(Math.log10(value)))
    return parseFloat((Math.floor(value / base) * base).toFixed(11))
  }

  function formatValue(value: number) {
    return value < 1e-2 ? value.toExponential() : value.toString()
  }

  // Преобразуем значения, если они меньше 1e-10
  leftMostValue = normalizeValue(leftMostValue)
  rightMostValue = normalizeValue(rightMostValue)

  // Вычисляем начало и конец логарифмического графика
  const minValue = computeScaleBound(leftMostValue)
  const maxValue = computeScaleBound(rightMostValue)

  const minExponent = Math.floor(Math.log10(minValue))
  const minCoefficient = Math.ceil(minValue / Math.pow(10, minExponent))
  const maxExponent = Math.floor(Math.log10(maxValue))
  const maxCoefficient = Math.floor(maxValue / Math.pow(10, maxExponent))

  const ticks = []
  for (let exponent = minExponent; exponent <= maxExponent; exponent++) {
    for (let coefficient = 1; coefficient <= 9; coefficient++) {
      let drawLine = false
      if (minExponent === maxExponent) {
        // Если находимся на одном уровне степени, метки распределяются равномерно
        if (coefficient >= minCoefficient && coefficient <= maxCoefficient) {
          drawLine = true
        }
      } else {
        if (
          (exponent === minExponent && coefficient >= minCoefficient) ||
          (exponent > minExponent && exponent < maxExponent) ||
          (exponent === maxExponent && coefficient <= maxCoefficient)
        ) {
          drawLine = true
        }
      }

      if (drawLine) {
        const tickValue = formatValue(parseFloat((coefficient * Math.pow(10, exponent)).toFixed(10)))
        ticks.push(tickValue)
      }
    }
  }
  return ticks
}

// 1e-3
