import Connections from '@/app/configuration/components/connections/connections'
import Devices from '@/app/configuration/components/devices/devices'
import Equipment from '@/app/configuration/components/equipments/equipment'
import KinematicsConfiguration from '@/app/configuration/components/kinematics-configuration/kinematics-configuration'
import MnemonicConfiguration from '@/app/configuration/components/mnemonic-configuration/mnemonic-configuration'
import { ConfigurationRoute } from '@/constants/navigation/routes.constant'
import type { RouteObject } from 'react-router'

const configurationRoute: RouteObject[] = [
  {
    path: ConfigurationRoute.Equipment,
    element: <Equipment />,
    index: true
  },
  {
    path: ConfigurationRoute.MimicDiagram,
    element: <MnemonicConfiguration />
  },
  {
    path: ConfigurationRoute.KinematicsDiagram,
    element: <KinematicsConfiguration />
  },
  {
    path: ConfigurationRoute.Devices,
    element: <Devices />
  },
  {
    path: ConfigurationRoute.Connections,
    element: <Connections />
  }
]

export default configurationRoute
