import { EEquipmentTree } from '@/enums/equipments/equipment-tree.enum'
import useActions from '@/hooks/use-actions'
import type { IEquipmentTreeNode } from '@/types/tree/equipment-tree-node.interface'
import type { DataNode } from 'antd/es/tree'
import type { TreeProps } from 'antd/lib'

const useTreeDraggableHook = (draggable?: boolean) => {
  const { setDraggableMachineId } = useActions()
  const allowDraggableNode = (node: DataNode) => {
    const extendedNode = node as IEquipmentTreeNode
    return draggable ? extendedNode.group === EEquipmentTree.MACHINES : false
  }

  const onDragStart: TreeProps['onDragStart'] = (info) => {
    const key = info?.node?.key as string
    if (key) {
      setDraggableMachineId(key)
    }
  }

  const onDragEnd = () => {
    setDraggableMachineId(null)
  }

  return { allowDraggableNode, onDragStart, onDragEnd }
}

export default useTreeDraggableHook
