import ButtonToolbar from '@/components/controlers/button-toolbar/button-toolbar'
import Control from '@/components/icons/control/control'
import MachineIcon from '@/components/icons/equipments/machine-icon'
import MeasurementIcon from '@/components/icons/equipments/measurement-icon'
import PointIcon from '@/components/icons/equipments/point-icon'
import { EEquipmentContent } from '@/enums/equipments/equipment-content.enum'
import { EEquipmentMode } from '@/enums/equipments/equipment-mode.enum'
import { ETypeMeasurement } from '@/enums/measurment/type-measurement.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMeasurementQuery } from '@/store/api/measurements.api'
import { getTypeMeasurement } from '@/utils/measurement/get-type-measurement'
import { FolderOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Button, Dropdown, Tooltip } from 'antd'
import type { FC } from 'react'
import React from 'react'

import styles from './equipment-tool.module.css'

const ALLOWED_TYPES_MEASUREMENT = [ETypeMeasurement.TemperatureDescription, ETypeMeasurement.CommonLevelDescription]

const EquipmentTools: FC = () => {
  const {
    selectedPointId: pointId,
    selectedMeasurementId: measurementId,
    selectedMachineId: machineId,
    selectedGroupId: groupId
  } = useTypedSelector((state) => state.globalReducer)
  const { equipmentContent } = useTypedSelector((state) => state.equipmentsUiReducer)
  const { setSelectedMeasurementId, setEquipmentContent, setEquipmentMode } = useActions()
  const { data: currentMeasurement } = useGetMeasurementQuery(measurementId, {
    skip: !measurementId
  })

  const toleranceControlOpened = equipmentContent === EEquipmentContent.TOLERANCE
  const isAllowedAddGroupOrMachine = EEquipmentContent.GROUP !== equipmentContent && equipmentContent !== null

  const checkAccessToleranceButton = () => {
    if (currentMeasurement && measurementId) {
      const currentType = getTypeMeasurement(currentMeasurement)
      return currentType ? ALLOWED_TYPES_MEASUREMENT.includes(currentType) : false
    }
  }

  const handleAddGroup = () => {
    setEquipmentContent(EEquipmentContent.GROUP)
    setEquipmentMode(EEquipmentMode.ADD)
  }

  const handleAddMachine = () => {
    setEquipmentContent(EEquipmentContent.MACHINE)
    setEquipmentMode(EEquipmentMode.ADD)
  }

  const handleAddPoint = () => {
    setEquipmentContent(EEquipmentContent.POINT)
    setEquipmentMode(EEquipmentMode.ADD)
  }

  const handleAddMeasurement = () => {
    setEquipmentContent(EEquipmentContent.MEASUREMENT)
    setEquipmentMode(EEquipmentMode.ADD)
  }

  const handleOpenToleranceControl = () => {
    if (toleranceControlOpened) {
      setEquipmentContent(EEquipmentContent.MEASUREMENT)
      return
    }
    setEquipmentContent(EEquipmentContent.TOLERANCE)
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Button
          className={styles['button']}
          icon={<FolderOutlined />}
          type='text'
          onClick={handleAddGroup}
          disabled={isAllowedAddGroupOrMachine}
        >
          Добавить группу
        </Button>
      )
    },
    {
      key: '2',
      label: (
        <Button
          className={styles['button']}
          icon={<MachineIcon />}
          type='text'
          onClick={handleAddMachine}
          disabled={isAllowedAddGroupOrMachine}
        >
          Добавить машину
        </Button>
      )
    },
    {
      key: '3',
      label: (
        <Button
          className={styles['button']}
          icon={<PointIcon />}
          type='text'
          onClick={handleAddPoint}
          disabled={EEquipmentContent.MACHINE !== equipmentContent}
        >
          Добавить точку измерения
        </Button>
      )
    },
    {
      key: '4',
      label: (
        <Button
          className={styles['button']}
          icon={<MeasurementIcon />}
          type='text'
          disabled={EEquipmentContent.POINT !== equipmentContent}
          onClick={handleAddMeasurement}
        >
          Добавить вид измерения
        </Button>
      )
    }
  ]

  return (
    <>
      <Tooltip title={'Добавить объект'} placement='right'>
        <Dropdown className={styles['dropdown']} menu={{ items }} trigger={['click']}>
          <ButtonToolbar icon={'add'} placement={'bottomRight'} />
        </Dropdown>
      </Tooltip>
      <ButtonToolbar
        title='Настроить допусковый контроль'
        disabled={!checkAccessToleranceButton()}
        activated={toleranceControlOpened}
        onClick={handleOpenToleranceControl}
      >
        <Control />
      </ButtonToolbar>
      <ButtonToolbar onClick={() => console.log('delete')} title={'Удалить объект'} icon={'delete'} disabled={true} />
    </>
  )
}

export default EquipmentTools
