import { AppRoute, ConditionRoute, ConfigurationRoute, SettingsRoute } from '@/constants/navigation/routes.constant'
import { SettingsTitle } from '@/constants/settings/settings.constant'
import type { ILink } from '@/types/navigation/links.type'

export const menuLinks: Readonly<ILink[]> = [
  {
    to: `${AppRoute.Condition}/${ConditionRoute.Results}`,
    icon: 'network_check',
    title: 'Состояние'
  },
  {
    to: AppRoute.ExpertAnalysis,
    icon: 'multiline_chart',
    title: 'Экспертный анализ',
    disabled: true
  },
  {
    to: AppRoute.Plans,
    icon: 'timeline',
    title: 'Маршруты'
  },
  {
    to: AppRoute.OffRoute,
    icon: 'exit_to_app',
    title: 'Вне маршрута',
    disabled: true
  },
  {
    to: AppRoute.Reports,
    icon: 'library_books',
    title: 'Отчёты'
  },
  {
    to: `${AppRoute.Configuration}/${ConfigurationRoute.Equipment}`,
    icon: 'developer_board',
    title: 'Конфигурация'
  },
  {
    to: `${AppRoute.Settings}/${SettingsRoute.Server}`,
    icon: 'settings',
    title: 'Настройка'
  },
  {
    to: AppRoute.Support,
    icon: 'support',
    title: 'Поддержка',
    disabled: true
  },
  {
    to: AppRoute.About,
    icon: 'help_outline',
    title: 'О системе'
  },
  {
    to: AppRoute.Exit,
    icon: 'directions_run',
    title: 'Выход',
    disabled: true
  }
] as const

export const settingsLinks: Readonly<ILink[]> = [
  {
    to: SettingsRoute.Server,
    title: SettingsTitle.Server,
    icon: 'dns'
  }
] as const

export const configurationLinks: Readonly<ILink[]> = [
  {
    to: ConfigurationRoute.Equipment,
    title: 'оборудование',
    icon: 'memory'
  },
  {
    to: ConfigurationRoute.MimicDiagram,
    title: 'мнемосхема',
    icon: 'schema'
  },
  {
    to: ConfigurationRoute.KinematicsDiagram,
    title: 'кинематика',
    icon: 'account_tree'
  },
  {
    to: ConfigurationRoute.Devices,
    title: 'устройства',
    icon: 'device_hub'
  },
  {
    to: ConfigurationRoute.Connections,
    title: 'связи',
    icon: 'join_inner'
  }
] as const

export const conditionLinks: Readonly<ILink[]> = [
  {
    to: ConditionRoute.Description,
    title: 'описание',
    icon: 'description'
  },
  {
    to: ConditionRoute.MimicDiagram,
    title: 'мнемосхема',
    icon: 'schema'
  },
  {
    to: ConditionRoute.KinematicsDiagram,
    title: 'кинематика',
    icon: 'account_tree'
  },
  {
    to: ConditionRoute.Results,
    title: 'Результаты',
    icon: 'fact_check'
  },
  {
    to: ConditionRoute.WorkMode,
    title: 'раб. режим',
    icon: 'home_repair_service'
  }
] as const
