export const ApiResource = {
  Bearing: '/bearing',
  Connections: '/connections',
  Vp3701: '/vp3701',
  Generators: '/generators',
  Images: '/images',
  Info: '/info',
  Kinematic: '/kinematic',
  Measurements: '/measurements',
  Mnemo: '/mnemo',
  Plans: '/plans',
  MeasuringPoints: '/measuring-points',
  ServerName: '/servername',
  Machines: '/machines',
  Groups: '/groups',
  GetAllNodes: '/nodes-state',
  Ltr: '/ltr'
} as const

export const ApiTag = {
  Connections: 'connections',
  Devices: 'devices',
  Machines: 'machines',
  MeasuringPoints: 'measuring-points',
  Measurements: 'measurements',
  MeasurementsResult: 'measurements-result',
  Plans: 'plans',
  Threshold: 'threshold',
  Mnemo: 'mnemo',
  Info: 'info',
  Images: 'images',
  Vp3701: 'vp3701',
  ServerName: 'server-name',
  GetAllNodes: 'get-all-nodes',
  Kinematic: 'kinematic',
  Ltr: 'ltr',
  Groups: 'groups'
} as const
