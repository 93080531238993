import DevicesTools from '@/app/configuration/components/devices-tools/devices-tools'
import EquipmentTools from '@/app/configuration/components/equipment-tools/equipment-tools'
import KinematicToolBar from '@/app/configuration/components/kinematic-tool-bar/kinematic-tool-bar'
import MnemonicCommands from '@/app/configuration/components/mnemonic-commands/mnemonic-commands'
import Submenu from '@/components/controlers/submenu/submenu'
import LeftBar from '@/components/layouts/left-bar/left-bar'
import ToolBar from '@/components/layouts/tool-bar/tool-bar'
import WorkSpace from '@/components/layouts/work-space/work-space'
import DevicesTree from '@/components/widgets/devices-tree/devices-tree'
import EquipmentTree from '@/components/widgets/equipment-tree/equipment-tree'
import { configurationLinks } from '@/constants/navigation/links.constant'
import { ConfigurationRoute } from '@/constants/navigation/routes.constant'
import { EEquipmentTreeMode } from '@/enums/equipments/equipment-tree-mode.enum'
import { endsWithPathnameEnding } from '@/utils/ends-with-pathname-ending.util'
import type { FC } from 'react'
import React from 'react'
import { Outlet, useLocation } from 'react-router'

const Configuration: FC = () => {
  const { pathname } = useLocation()

  return (
    <>
      <ToolBar>
        {endsWithPathnameEnding(pathname, ConfigurationRoute.Equipment) && <EquipmentTools />}
        {endsWithPathnameEnding(pathname, ConfigurationRoute.MimicDiagram) && <MnemonicCommands />}
        {endsWithPathnameEnding(pathname, ConfigurationRoute.KinematicsDiagram) && <KinematicToolBar />}
        {endsWithPathnameEnding(pathname, ConfigurationRoute.Devices) && <DevicesTools />}
      </ToolBar>
      <LeftBar>
        {endsWithPathnameEnding(pathname, ConfigurationRoute.Equipment) && (
          <EquipmentTree equipmentTreeMode={EEquipmentTreeMode.CONFIGURATION} />
        )}
        {endsWithPathnameEnding(pathname, ConfigurationRoute.MimicDiagram) && (
          <EquipmentTree equipmentTreeMode={EEquipmentTreeMode.CONFIGURATION} />
        )}
        {endsWithPathnameEnding(pathname, ConfigurationRoute.KinematicsDiagram) && (
          <EquipmentTree equipmentTreeMode={EEquipmentTreeMode.KINEMATIC} />
        )}
        {endsWithPathnameEnding(pathname, ConfigurationRoute.Devices) && <DevicesTree />}
        {endsWithPathnameEnding(pathname, ConfigurationRoute.Connections) && (
          <EquipmentTree equipmentTreeMode={EEquipmentTreeMode.CONNECTION} />
        )}
      </LeftBar>
      <WorkSpace>
        <Submenu links={configurationLinks} />
        <Outlet />
      </WorkSpace>
    </>
  )
}

export default Configuration
