import type { ThemeConfig } from 'antd'

export const DesignTokensAntd: ThemeConfig = {
  token: {
    colorPrimary: '#000',
    colorPrimaryBorder: '#BBBBBBFF',
    colorBorder: '#BBBBBBFF',
    borderRadiusSM: 0,
    fontFamily: 'Inter, Arial, Helvetica, sans-serif',
    borderRadiusLG: 0,
    borderRadius: 0,
    padding: 4,
    controlItemBgHover: 'transparent'
  },
  components: {
    Button: {
      // defaultHoverBg: 'transparent'
    },
    Tree: {
      nodeSelectedBg: 'rgb(214 214 214)',
      nodeHoverBg: 'rgb(214 214 214)'
    },
    TreeSelect: {
      nodeSelectedBg: 'rgb(214 214 214)',
      nodeHoverBg: 'rgb(214 214 214)'
    },
    Collapse: {
      contentPadding: '6px'
    }
  }
}

// :where(.css-dev-only-do-not-override-1kgbnv1)
// .ant-tree-select-dropdown .ant-select-tree
// .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected,
//
// :where(.css-dev-only-do-not-override-1kgbnv1).
// ant-tree-select-dropdown .ant-select-tree
// .ant-select-tree-checkbox+span.ant-select-tree-node-selected
