import { useGetCurrentMnemonicQuery, useGetMnemonicsQuery } from '@/store/api/mnemo.api'

import { useTypedSelector } from '../use-typed-selector'

const useGetMnemoSchema = () => {
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)

  const { data: mnemonicSchemeData } = useGetMnemonicsQuery(selectedMachineId, {
    skip: !selectedMachineId
  })

  const mnemoSchemeId = mnemonicSchemeData?.content[0]?.mnemoSchemeId

  const resultCurrentMnemonic = useGetCurrentMnemonicQuery(mnemoSchemeId, {
    skip: !mnemoSchemeId
  })

  if (!mnemoSchemeId) {
    return {
      ...resultCurrentMnemonic,
      data: null
    }
  }

  return resultCurrentMnemonic
}

export default useGetMnemoSchema
