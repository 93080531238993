import MnemonicToolbar from '@/app/configuration/components/mnemonic-configuration/mnemonic-toolbar/mnemonic-toolbar'
import TextInfo from '@/components/controlers/text-info/text-info'
import MnemonicMain from '@/components/widgets/mnemonic/mnemonic-main/mnemonic-main'
import useGetMnemoSchema from '@/hooks/api/use-get-mnemo-schema.hook'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'

const MnemonicConfiguration: FC = () => {
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)
  const { data: mnemoSchema } = useGetMnemoSchema()

  return (
    <>
      {mnemoSchema && (
        <>
          <MnemonicToolbar />
          <MnemonicMain mnemoScheme={mnemoSchema} condition={false} />
        </>
      )}
      {!selectedMachineId && <TextInfo>Машина не выбрана</TextInfo>}
      {!mnemoSchema && <TextInfo>Мнемосхема отсутствует</TextInfo>}
    </>
  )
}

export default MnemonicConfiguration
