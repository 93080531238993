import { isNumber } from 'lodash'

type TGetStepSlider = (desiredSteps: number, rangeValues: number[] | null) => number | undefined

export const getStepSlider: TGetStepSlider = (desiredSteps, rangeValues) => {
  if (rangeValues) {
    const [minValue, maxValue] = rangeValues
    if (isNumber(maxValue) && isNumber(minValue)) {
      const rawStep = (maxValue - minValue) / desiredSteps

      // Округляем шаг до ближайшей "удобной" величины
      const magnitude = Math.pow(10, Math.floor(Math.log10(rawStep))) // Определяем порядок величины
      const roundedStep = Math.ceil(rawStep / magnitude) * magnitude

      // Убеждаемся, что шаг не превышает диапазон
      return Math.min(roundedStep, maxValue - minValue)
    }
  }
}
