import appRouter from '@/app/app.routes'
import { DesignTokensAntd } from '@/constants/core/design-tokens-antd.constant'
import { ConfigProvider } from 'antd'
import ru_RU from 'antd/locale/ru_RU'
import type { FC } from 'react'
import { RouterProvider } from 'react-router'

const App: FC = () => (
  <ConfigProvider locale={ru_RU} theme={DesignTokensAntd}>
    <RouterProvider router={appRouter} />
  </ConfigProvider>
)

export default App
