import type { IConnectionElement } from '@/app/configuration/components/connections/components/connection-panel/connection-panel.type'
import { DeviceTypeLabel } from '@/constants/device/device.constant'
import { Vp3701ModulesName } from '@/constants/device/vp3701/vp3701-modules-name.constant'
import { mapVp3701ChannelType } from '@/constants/device/vp3711/vp3701-channel-type.constant'
import { NAME_ROOT } from '@/constants/equipment/equipment-tree.constant'
import { EConnectionContent } from '@/enums/connection/connection-content.enum'
import { ETargetType } from '@/enums/connection/target-type.enum'
import type { EVp3701ChannelType } from '@/enums/connection/vp3701-channel-type.enum'
import { EDeviceContent } from '@/enums/device/device-content.enum'
import { EPhysicalQuantityType } from '@/enums/point/physical-quantity-type.enum'
import useActions from '@/hooks/use-actions'
import { useLazyGetAllParentsQuery } from '@/store/api/nodes.api'
import { useLazyGetMeasuringPointQuery } from '@/store/api/points.api'
import type { IConnection } from '@/types/connection/connection.type'
import { Empty } from 'antd'
import cn from 'classnames'
import type { FC } from 'react'

import styles from './path-connections.module.css'

type TProps = {
  title: string
  connections?: IConnectionElement[]
  undefinedText: string
  foundConnectedElement?: IConnection
  content: EConnectionContent
}

const PathConnections: FC<TProps> = ({ title, connections, undefinedText, foundConnectedElement, content }) => {
  const {
    setSelectedPointId,
    setSelectedDeviceId,
    setDeviceContent,
    setConnectionElement,
    setSelectedMachineId,
    setExpendedEquipments
  } = useActions()
  const [triggerGetMeasuringPoint] = useLazyGetMeasuringPointQuery()
  const [triggerGetAllParents] = useLazyGetAllParentsQuery()

  const isPointsContent = content === EConnectionContent.POINTS

  const handleSelectPointAndDeviceClick = async (element: IConnectionElement) => {
    try {
      const point = await triggerGetMeasuringPoint(element.measuringPointId).unwrap()
      const parents = await triggerGetAllParents({ objectId: element.measuringPointId }).unwrap()
      const expendedKeys = parents.map((item) => item.id)
      setExpendedEquipments(expendedKeys)
      setSelectedMachineId(point.machineId)
      setSelectedPointId(element.measuringPointId)
    } catch (e) {
      console.error(e)
    }

    let deviceId = element.generatorId
    let deviceContent = EDeviceContent.GENERATOR_DEVICE
    if (element.vp3701Id) {
      const nameModule =
        element.signalType === EPhysicalQuantityType.TEMPERATURE ? Vp3701ModulesName.MLX90 : Vp3701ModulesName.KX134

      deviceId = `${element.vp3701Id}-${nameModule}-${element.vp3701ChannelType}`
      deviceContent = EDeviceContent.VP_3701_CHANNEL
    }

    if (element.ltrModuleId) {
      deviceId = `${element.ltrModuleId}-${element.ltrModuleChannelNumber}`
      deviceContent = EDeviceContent.VP_3711_CHANNEL
    }

    setSelectedDeviceId(deviceId ?? null)
    setDeviceContent(deviceContent)
    setConnectionElement(element)
  }

  const generateNamePathConnection = (connection: IConnectionElement) => {
    if (isPointsContent) {
      const paths = connection.paths?.measuringPoint?.reduce((acc, measuringPoint) => `${acc} / ${measuringPoint}`, '')
      if (!paths) {
        return `${connection.name}`
      }
      return `${NAME_ROOT} ${paths}`
    }

    if (connection.targetType === ETargetType.GENERATOR) {
      const path = connection.paths?.generator?.reduce((acc, nameGenerator) => `${acc} / ${nameGenerator}`, '')
      return `${DeviceTypeLabel.Generator} ${path}`
    }

    if (connection.targetType === ETargetType.LTR24) {
      const path = connection.paths?.ltr?.reduce((acc, ltr) => `${acc} / ${ltr}`, '')
      return `${DeviceTypeLabel.Vp3711} ${path}`
    }

    if (connection.targetType === ETargetType.VP3701) {
      if (connection.paths?.vp3701) {
        const mappedConnection = [...connection.paths.vp3701]
        const lastIndex = mappedConnection.length - 1
        const channelType = mappedConnection[lastIndex] as EVp3701ChannelType
        mappedConnection[lastIndex] = mapVp3701ChannelType[channelType]
        const path = mappedConnection.reduce((acc, vp3701) => `${acc} / ${vp3701}`, '')
        return `${DeviceTypeLabel.Vp3701} ${path}`
      }
    }

    return connection.name
  }

  return (
    <div className={styles['path-connections']}>
      <h2 className={styles['title']}>{title}</h2>
      <div className={styles['list']}>
        {!connections && <Empty description={undefinedText} className={styles['empty']} />}
        {connections?.length === 0 && <Empty description={'Подключений нет'} className={styles['empty']} />}
        {connections?.map((connection) => (
          <span
            key={connection.connectionId}
            className={cn(styles['list-item'], {
              [styles['list-item-found']]: foundConnectedElement?.connectionId === connection.connectionId
            })}
          >
            <a onClick={() => handleSelectPointAndDeviceClick(connection)} className={styles['link']}>
              {generateNamePathConnection(connection)}
            </a>
          </span>
        ))}
      </div>
    </div>
  )
}

export default PathConnections
