import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetInfoQuery } from '@/store/api/info.api'
import { Flex } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import type { FC, PropsWithChildren } from 'react'
import { useEffect, useState } from 'react'

import styles from './status-board.module.css'

const FORMAT_DATE = 'D MMMM YYYY, HH:mm'

const StatusBoard: FC<PropsWithChildren> = ({ children }) => {
  const { version, server } = useTypedSelector((state) => state.serverSettingsReducer)
  const { selectedMachineId, selectedPointId, selectedGroupId, selectedMeasurementId } = useTypedSelector(
    (state) => state.globalReducer
  )
  const { data: dataInfo } = useGetInfoQuery()
  const [currentDate, setCurrentDate] = useState('')

  useEffect(() => {
    // Обновляем дату каждую минуту
    const intervalId = setInterval(() => {
      setCurrentDate(dayjs().locale('ru').format(FORMAT_DATE))
    }, 1000)

    // Чистим интервал при размонтировании компонента
    return () => clearInterval(intervalId)
  }, [])

  return (
    <div className={styles['status-board']}>
      <Flex gap={5} align='center' style={{ overflow: 'auto' }}>
        <p className={styles['text']}>{currentDate}</p>
        <p className={styles['text']}>Клиент {version}</p>
        <p className={styles['text']}>Сервер {dataInfo?.version}</p>
        <p className={styles['text']}>Адр. сервера {server?.url}</p>
        {selectedGroupId && <p className={styles['text']}>Группа {selectedGroupId}</p>}
        {selectedMachineId && <p className={styles['text']}>Машина {selectedMachineId}</p>}
        {selectedPointId && <p className={styles['text']}>ТИ {selectedPointId}</p>}
        {selectedMeasurementId && <p className={styles['text']}>Вид изм. {selectedMeasurementId}</p>}
      </Flex>
      {children}
    </div>
  )
}

export default StatusBoard
