import DevicesTree from '@/components/widgets/devices-tree/devices-tree'
import type { FC } from 'react'

import styles from './connection-device-container.module.css'

const ConnectionDeviceContainer: FC = () => (
  <div className={styles['device-menu-container']}>
    <DevicesTree />
  </div>
)

export default ConnectionDeviceContainer
