import { ApiResource, ApiTag } from '@/constants/store/api.constants'
import type { ICreatedPoint, IMeasuringPoint, ISelectedMeasuringPoint, TUpdatedPoint } from '@/types/point/point.type'

import { baseApi } from './api-base-query'

interface IGetPointsResponse {
  content: ISelectedMeasuringPoint[]
}

export const measurementPointsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMeasuringPoints: builder.query<IGetPointsResponse, string | null | undefined>({
      query: (machineId) => ({
        url: `${ApiResource.MeasuringPoints}?machineId=${machineId}`
      }),
      providesTags: [ApiTag.MeasuringPoints]
    }),

    createMeasuringPoints: builder.mutation<{ id: string }, ICreatedPoint>({
      query: (data) => ({
        url: ApiResource.MeasuringPoints,
        method: 'POST',
        body: data
      }),
      invalidatesTags: [ApiTag.MeasuringPoints]
    }),

    getMeasuringPoint: builder.query<IMeasuringPoint, string | null | undefined>({
      query: (id) => ({ url: `${ApiResource.MeasuringPoints}/${id}` }),
      providesTags: [ApiTag.MeasuringPoints]
    }),

    updateMeasuringPoints: builder.mutation<null, { updatedData: TUpdatedPoint; pointId: string }>({
      query: ({ updatedData, pointId }) => ({
        url: `${ApiResource.MeasuringPoints}/${pointId}`,
        method: 'PUT',
        body: updatedData
      }),
      invalidatesTags: [ApiTag.MeasuringPoints]
    }),
    updateMeasuringPointsStatus: builder.mutation<null, { pointId: string; statusData: { paused: boolean } }>({
      query: ({ pointId, statusData }) => ({
        url: `${ApiResource.MeasuringPoints}/${pointId}/paused`,
        method: 'PUT',
        body: statusData
      }),
      invalidatesTags: [ApiTag.MeasuringPoints]
    })
  })
})

export const {
  useGetMeasuringPointsQuery,
  useLazyGetMeasuringPointsQuery,
  useCreateMeasuringPointsMutation,
  useGetMeasuringPointQuery,
  useLazyGetMeasuringPointQuery,
  useUpdateMeasuringPointsMutation,
  useUpdateMeasuringPointsStatusMutation
} = measurementPointsApi
