import MachineIcon from '@/components/icons/equipments/machine-icon'
import EquipmentItem from '@/components/widgets/equipment-item/equipment-item'
import StatisticalIndicator from '@/components/widgets/statistical-indicator/statistical-indicator'
import { EEquipmentTree } from '@/enums/equipments/equipment-tree.enum'
import type { FC } from 'react'

interface IProps {
  name?: string
  paused?: boolean
  id: string
}

const PlanItem: FC<IProps> = ({ name, paused, id }) => (
  <EquipmentItem
    element={{
      id: id,
      name: name || '',
      indicator: <StatisticalIndicator id={id} type={EEquipmentTree.MACHINES} />,
      paused: paused || false,
      equipmentIcon: <MachineIcon />
    }}
  />
)

export default PlanItem
