import GroupIcon from '@/components/icons/equipments/group-icon'
import MachineIcon from '@/components/icons/equipments/machine-icon'
import MeasurementIcon from '@/components/icons/equipments/measurement-icon'
import PointIcon from '@/components/icons/equipments/point-icon'
import EquipmentIcons from '@/components/widgets/equipment-icons/equipment-icons'
import { EEquipmentTreeMode } from '@/enums/equipments/equipment-tree-mode.enum'
import { EEquipmentTree } from '@/enums/equipments/equipment-tree.enum'
import { useLazyGetGroupsQuery } from '@/store/api/grpups.api'
import { useLazyGetMachinesQuery } from '@/store/api/machines.api'
import { useLazyGetMeasurementsQuery } from '@/store/api/measurements.api'
import { useLazyGetMeasuringPointsQuery } from '@/store/api/points.api'
import type { TGroupElement } from '@/types/group/group.type'
import type { IMachineInfo } from '@/types/machine.type'
import type { IMeasurement } from '@/types/measurement/measurement.type'
import type { ISelectedMeasuringPoint } from '@/types/point/point.type'
import type { IEquipmentTreeNode } from '@/types/tree/equipment-tree-node.interface'
import { updateTreeData } from '@/utils/tree/update-tree-data'
import type { EventDataNode } from 'antd/es/tree'
import { type Dispatch, type SetStateAction, useCallback } from 'react'

import styles from './equipment-tree.module.css'

export const mapMeasurementToTree = (measurements: IMeasurement[]): IEquipmentTreeNode[] =>
  measurements.map((measurement) => ({
    key: measurement.measurementId,
    title: measurement.name,
    group: EEquipmentTree.MEASUREMENTS,
    isLeaf: true,
    icon: (
      <EquipmentIcons
        equipmentIcon={<MeasurementIcon />}
        id={measurement.measurementId}
        showThresholdIndicator={true}
      />
    )
  }))

export const mapPointsToTree = (
  points: ISelectedMeasuringPoint[],
  equipmentTreeMode?: EEquipmentTreeMode
): IEquipmentTreeNode[] =>
  points.map((point) => ({
    key: point.pointId,
    title: point.name,
    group: EEquipmentTree.POINTS,
    isLeaf: equipmentTreeMode === EEquipmentTreeMode.CONNECTION || undefined,
    icon: <EquipmentIcons equipmentIcon={<PointIcon />} id={point.pointId} showStatisticalIndicator={true} />
  }))

export const mapMachinesToTree = (
  machines: IMachineInfo[],
  equipmentTreeMode?: EEquipmentTreeMode
): IEquipmentTreeNode[] => {
  const limitedMachine =
    equipmentTreeMode === EEquipmentTreeMode.CONDITION ||
    equipmentTreeMode === EEquipmentTreeMode.PLAN ||
    equipmentTreeMode === EEquipmentTreeMode.KINEMATIC

  return machines.map((machine) => ({
    key: machine.machineId,
    title: machine.name,
    className: styles['tree-item'],
    group: EEquipmentTree.MACHINES,
    icon: <EquipmentIcons equipmentIcon={<MachineIcon />} showStatisticalIndicator={true} id={machine.machineId} />,
    isLeaf: limitedMachine || undefined
  }))
}

export const mapGroupsToTree = (groups: TGroupElement[]): IEquipmentTreeNode[] =>
  groups.map((group) => ({
    key: group.groupId,
    title: group.name,
    className: styles['tree-item'],
    group: EEquipmentTree.GROUPS,
    icon: <EquipmentIcons equipmentIcon={<GroupIcon />} id={group.groupId} showStatisticalIndicator={true} />
  }))

export const useTreeLoader = (
  treeMenu: IEquipmentTreeNode[],
  setTreeMenu: Dispatch<SetStateAction<IEquipmentTreeNode[]>>,
  equipmentTreeMode?: EEquipmentTreeMode
) => {
  const [getMachines] = useLazyGetMachinesQuery()
  const [getGroups] = useLazyGetGroupsQuery()
  const [getPoints] = useLazyGetMeasuringPointsQuery()
  const [getMeasurements] = useLazyGetMeasurementsQuery()

  const updateTreeByRootOrGroup = useCallback(
    (machines: IMachineInfo[], groups: TGroupElement[], key: string) => {
      const machinesTree = mapMachinesToTree(machines, equipmentTreeMode)
      const groupsTree = mapGroupsToTree(groups)
      const newElements = [...groupsTree, ...machinesTree]
      const updatedTreeData = updateTreeData<IEquipmentTreeNode>(treeMenu, key, newElements)
      setTreeMenu(updatedTreeData)
    },
    [equipmentTreeMode, setTreeMenu, treeMenu]
  )

  const updateTreeByMachine = useCallback(
    (points: ISelectedMeasuringPoint[], key: string) => {
      const pointsTree = mapPointsToTree(points, equipmentTreeMode)
      const updatedTreeData = updateTreeData<IEquipmentTreeNode>(treeMenu, key, pointsTree)
      setTreeMenu(updatedTreeData)
    },
    [equipmentTreeMode, setTreeMenu, treeMenu]
  )

  const updateTreeByMeasurements = useCallback(
    (measurements: IMeasurement[], key: string) => {
      const machinesTree = mapMeasurementToTree(measurements)
      const updatedTreeData = updateTreeData<IEquipmentTreeNode>(treeMenu, key, machinesTree)
      setTreeMenu(updatedTreeData)
    },
    [setTreeMenu, treeMenu]
  )

  const loadRootData = useCallback(
    async (id: string) => {
      try {
        const [groupsResponse, machinesResponse] = await Promise.all([
          getGroups(null).unwrap(),
          getMachines(null).unwrap()
        ])
        updateTreeByRootOrGroup(machinesResponse.content, groupsResponse.content, id)
      } catch (error) {
        console.error(`Failed to load root data for ID ${id}:`, error)
      }
    },
    [getGroups, getMachines, updateTreeByRootOrGroup]
  )

  const loadGroupData = useCallback(
    async (id: string) => {
      try {
        const [groupsResponse, machinesResponse] = await Promise.all([getGroups(id).unwrap(), getMachines(id).unwrap()])
        updateTreeByRootOrGroup(machinesResponse.content, groupsResponse.content, id)
      } catch (error) {
        console.error(`Failed to load group data for ID ${id}:`, error)
      }
    },
    [getGroups, getMachines, updateTreeByRootOrGroup]
  )

  const loadMachineData = useCallback(
    async (id: string) => {
      try {
        const { content: points } = await getPoints(id).unwrap()
        updateTreeByMachine(points, id)
      } catch (error) {
        console.error(`Failed to load points for machine ${id}:`, error)
      }
    },
    [getPoints, updateTreeByMachine]
  )

  const loadPointData = useCallback(
    async (id: string) => {
      try {
        const { content: measurements } = await getMeasurements(id).unwrap()
        updateTreeByMeasurements(measurements, id)
      } catch (error) {
        console.error(`Failed to load measurements for point ${id}:`, error)
      }
    },
    [getMeasurements, updateTreeByMeasurements]
  )

  const handleDataLoad = useCallback(
    async (treeNode: EventDataNode<IEquipmentTreeNode>) => {
      const { key, group } = treeNode
      const id = key as string

      switch (group) {
        case EEquipmentTree.ROOT:
          await loadRootData(id)
          break
        case EEquipmentTree.GROUPS:
          await loadGroupData(id)
          break
        case EEquipmentTree.MACHINES:
          await loadMachineData(id)
          break
        case EEquipmentTree.POINTS:
          await loadPointData(id)
          break
        default:
          console.warn(`Unknown group type: ${group}`)
      }
    },
    [loadRootData, loadGroupData, loadMachineData, loadPointData]
  )

  return { handleDataLoad }
}
