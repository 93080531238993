import { KEY_ROOT } from '@/components/widgets/equipment-tree/equipment-tree.constant'
import { findParentKeys } from '@/components/widgets/equipment-tree/equipment-tree.service'
import { EEquipmentContent } from '@/enums/equipments/equipment-content.enum'
import { EEquipmentMode } from '@/enums/equipments/equipment-mode.enum'
import { EEquipmentTree } from '@/enums/equipments/equipment-tree.enum'
import useActions from '@/hooks/use-actions'
import type { ITreeNode } from '@/types/tree/tree-node.interface'
import type { Key } from 'react'
import { useCallback, useState } from 'react'

export const useTreeSelectionHandler = (treeMenu: ITreeNode[]) => {
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([])

  const {
    setSelectedTreeItems,
    setSelectedGroupId,
    setSelectedMachineId,
    setSelectedPointId,
    setSelectedMeasurementId,
    resetSelectedForMachine,
    setExpendedEquipments,
    setEquipmentMode,
    addExpendedEquipment,
    setEquipmentContent
  } = useActions()

  const handleSelect = useCallback(
    (_: Key[] | null, info: { node: ITreeNode }) => {
      const { group, key } = info.node
      const selectedId = key as string

      const parentsKeys = findParentKeys(selectedId, treeMenu) as string[]
      setSelectedTreeItems([selectedId])
      setEquipmentMode(EEquipmentMode.DEFAULT)

      switch (group) {
        case EEquipmentTree.ROOT:
          setSelectedGroupId(KEY_ROOT)
          resetSelectedForMachine()
          setEquipmentContent(null)

          break

        case EEquipmentTree.GROUPS:
          setSelectedGroupId(selectedId)
          setSelectedMachineId(null)
          setSelectedPointId(null)
          setSelectedMeasurementId(null)
          setEquipmentContent(EEquipmentContent.GROUP)

          break

        case EEquipmentTree.MACHINES:
          setSelectedMachineId(selectedId)
          setSelectedPointId(null)
          setSelectedMeasurementId(null)
          setEquipmentContent(EEquipmentContent.MACHINE)

          break

        case EEquipmentTree.POINTS:
          setSelectedMachineId(parentsKeys[parentsKeys.length - 1] as string)
          setSelectedPointId(selectedId)
          setSelectedMeasurementId(null)
          setEquipmentContent(EEquipmentContent.POINT)

          break

        case EEquipmentTree.MEASUREMENTS:
          setSelectedMachineId(parentsKeys[parentsKeys.length - 2] as string)
          setSelectedPointId(parentsKeys[parentsKeys.length - 1] as string)
          setSelectedMeasurementId(selectedId)
          setEquipmentContent(EEquipmentContent.MEASUREMENT)
          break

        default:
          console.warn(`Unknown group type: ${group}`)
          break
      }
    },
    [
      treeMenu,
      setSelectedTreeItems,
      setEquipmentMode,
      setSelectedGroupId,
      resetSelectedForMachine,
      setEquipmentContent,
      setSelectedMachineId,
      setSelectedPointId,
      setSelectedMeasurementId
    ]
  )

  return { handleSelect }
}
