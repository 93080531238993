import {
  chartViewModeAmplitudeOptions,
  chartViewModeFrequencyOptions
} from '@/app/condition/components/results/components/charts/components/chart-spectrum/components/chart-spectrum-panel/chart-spectrum-panel.constant'
import { StepSize } from '@/app/condition/components/results/components/charts/components/chart-spectrum/components/spectrum-overlay/spectrum-overlay.constant'
import DocTooltip from '@/components/widgets/doc-tooltip/doc-tooltip'
import type { EAmplitudeMode, EFrequencyMode } from '@/enums/charts/chart-value-mode.enum'
import { Select } from 'antd'
import { isUndefined } from 'lodash'
import type { SelectHandler } from 'rc-select/lib/Select'
import type { FC } from 'react'

import styles from './chart-spectrum-panel.module.css'

type TProps = {
  chartAmplitudeMode: EAmplitudeMode
  chartFreqMode: EFrequencyMode
  onAmplitudeModeSelect: SelectHandler<EAmplitudeMode>
  onFrequencyModeSelect: SelectHandler<EFrequencyMode>
  selectedFrequency?: number
}

const ChartSpectrumPanel: FC<TProps> = ({
  chartAmplitudeMode,
  chartFreqMode,
  onAmplitudeModeSelect,
  onFrequencyModeSelect,
  selectedFrequency
}) => {
  const ContentForTooltip = () => (
    <>
      <h2 className={styles['title']}>Горячие клавиши</h2>
      <div className={styles['content']}>
        <p>
          Клавиша <span className={styles['combination']}>«←»</span> – {StepSize.SmallStep} шаг влево.
        </p>
        <p>
          Клавиша <span className={styles['combination']}>«→»</span> – {StepSize.SmallStep} шаг вправо.
        </p>
        <p>
          Комбинация <span className={styles['combination']}>«Shift + ←»</span> – {StepSize.MediumStep} шагов влево.
        </p>
        <p>
          Комбинация <span className={styles['combination']}>«Shift + →»</span> – {StepSize.MediumStep} шагов вправо.
        </p>
        <p>
          Комбинация <span className={styles['combination']}>«Ctrl + ←»</span> – {StepSize.LargeStep} шагов влево.
        </p>
        <p>
          Комбинация <span className={styles['combination']}>«Ctrl + →»</span> – {StepSize.LargeStep} шагов вправо.
        </p>
        <p>
          Комбинация <span className={styles['combination']}>«Ctrl + Shift + →»</span> – увеличить ширину курсора на{' '}
          {StepSize.ExpandableStep}.
        </p>
        <p>
          Комбинация <span className={styles['combination']}>«Ctrl + Shift + ←»</span> – уменьшить ширину курсора на{' '}
          {StepSize.ExpandableStep}.
        </p>
      </div>
    </>
  )

  return (
    <>
      <div className={styles['container-item']}>
        <DocTooltip simpleText={<ContentForTooltip />} />
      </div>
      <div className={styles['container-item']}>
        <p>Amp:</p>
        <Select
          options={chartViewModeAmplitudeOptions}
          defaultValue={chartAmplitudeMode}
          onSelect={onAmplitudeModeSelect}
        />
      </div>
      <div className={styles['container-item']}>
        <p>Freq:</p>
        <Select options={chartViewModeFrequencyOptions} defaultValue={chartFreqMode} onSelect={onFrequencyModeSelect} />
      </div>

      {!isUndefined(selectedFrequency) && (
        <>
          <p title={selectedFrequency.toString()} className={styles['frequency']}>
            Freq: {selectedFrequency}
          </p>
        </>
      )}
    </>
  )
}

export default ChartSpectrumPanel
