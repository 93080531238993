import TooltipTimeSlice from '@/app/condition/components/results/components/charts/components/chart-spectrum/components/spectrum-overlay/components/time-slice-chart/components/tooltip-time-slice/tooltip-time-slice'
import type { ITimeSliceData } from '@/app/condition/components/results/components/charts/components/chart-spectrum/interfaces/time-slice-data.interface'
import ChartWrapper from '@/app/condition/components/results/components/charts/components/chart-wrapper/chart-wrapper'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'
import { useMemo } from 'react'
import { CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import type { LineDot } from 'recharts/types/cartesian/Line'
import type { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart'

import localStyles from './time-slice-chart.module.css'

type TProps = {
  timeSliceData: ITimeSliceData[]
  onSelectHover: (resultId: string) => void
  ticksAmplitudes: number[] | string[] | null
}

const TimeSliceChart: FC<TProps> = ({ timeSliceData, onSelectHover, ticksAmplitudes }) => {
  const { activatedBackgroundLine } = useTypedSelector((state) => state.chartsUiReducer)
  const timeSliceDataFormatted = useMemo(
    () =>
      timeSliceData.reverse().map((item, index) => ({
        index: index + 1,
        amplitude: item.amplitude,
        backgroundLine: item.backgroundLine,
        timestamp: new Date(item.timestamp), // Преобразование строки в миллисекунды
        color: item.color,
        resultId: item.resultId,
        frequency: item.frequency
      })),
    [timeSliceData]
  )

  const renderCustomizedDot: LineDot = ({ cx, cy, payload }: { cx: string; cy: string; payload: ITimeSliceData }) => (
    <circle key={payload.resultId} cx={cx} cy={cy} r={5} fill={payload.color} />
  )

  const handleClick: CategoricalChartFunc = (nextState) => {
    const activeResultId = nextState.activePayload?.[0].payload.resultId as string
    onSelectHover(activeResultId)
  }

  return (
    <ChartWrapper title='Временной срез'>
      <div className={localStyles['responsive-container']}>
        <ResponsiveContainer>
          <ComposedChart data={timeSliceDataFormatted} onClick={handleClick}>
            <CartesianGrid color={'black'} />
            <XAxis
              type='number'
              dataKey='index'
              axisLine={true}
              tick={{ fill: 'black' }}
              allowDataOverflow
              includeHidden={true}
              padding={{ left: 10, right: 10 }}
              domain={['dataMin', 'dataMax']}
              scale='linear'
            />
            <YAxis
              type={'number'}
              dataKey={activatedBackgroundLine ? 'backgroundLine' : 'amplitude'}
              tickMargin={5}
              allowDataOverflow
              tickLine={true}
              tick={{ fill: 'black' }}
              padding={{ top: 25, bottom: 25 }}
              domain={['dataMin', 'dataMax']}
              fontSize={12}
              width={75}
              ticks={ticksAmplitudes || []}
            />
            <Line
              dataKey={activatedBackgroundLine ? 'backgroundLine' : 'amplitude'}
              isAnimationActive={false}
              stroke='black'
              dot={renderCustomizedDot}
            />
            <Tooltip content={<TooltipTimeSlice />} isAnimationActive={false} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </ChartWrapper>
  )
}

export default TimeSliceChart
