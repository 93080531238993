import type { ITimeSliceData } from '@/app/condition/components/results/components/charts/components/chart-spectrum/interfaces/time-slice-data.interface'
import ChartTooltip from '@/app/condition/components/results/components/charts/components/tooltip-wrapper/chart-tooltip'
import { PRECISION_NUMBER } from '@/constants/config.constant'
import { DATE_TEMPLATE, UNIT_GZ } from '@/constants/core/common.constant'
import { formatNumber } from '@/utils/common.util'
import { formatDate } from '@/utils/format-date'
import type { FC } from 'react'
import type { TooltipProps } from 'recharts'
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'

type TProps = TooltipProps<ValueType, NameType>

const TooltipTimeSlice: FC<TProps> = ({ active, payload }) => {
  if (active && payload) {
    const currentData = payload[0]?.payload as ITimeSliceData

    return (
      <ChartTooltip>
        <span>A {formatNumber(currentData.amplitude)}</span>
        {currentData.backgroundLine && <span>Линия фона {formatNumber(currentData.backgroundLine)}</span>}
        <span>
          Freq: {currentData.frequency.toPrecision(PRECISION_NUMBER)} {UNIT_GZ}
        </span>
        <span>Time: {formatDate(currentData?.timestamp, DATE_TEMPLATE)}</span>
      </ChartTooltip>
    )
  }

  return null
}

export default TooltipTimeSlice
