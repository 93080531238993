import TextInfo from '@/components/controlers/text-info/text-info'
import MnemonicMain from '@/components/widgets/mnemonic/mnemonic-main/mnemonic-main'
import Spinner from '@/components/widgets/spinner/spinner'
import useGetMnemoSchema from '@/hooks/api/use-get-mnemo-schema.hook'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'

const MnemonicCondition: FC = () => {
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)
  const { data: mnemoSchema, isFetching: isFetching } = useGetMnemoSchema()

  return (
    <>
      {mnemoSchema && <MnemonicMain condition={true} mnemoScheme={mnemoSchema} />}
      {!selectedMachineId && <TextInfo>Машина не выбрана</TextInfo>}
      {!mnemoSchema && <TextInfo>Мнемосхема отсутствует</TextInfo>}
      {isFetching && <Spinner />}
    </>
  )
}

export default MnemonicCondition
