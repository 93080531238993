import ButtonToolbar from '@/components/controlers/button-toolbar/button-toolbar'
import { TitleButton } from '@/constants/core/common.constant'
import { EDeviceContent } from '@/enums/device/device-content.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'
import React from 'react'

const DevicesTools: FC = () => {
  const { setIsEditGenerator } = useActions()
  const { isEditGenerator, deviceContent } = useTypedSelector((state) => state.devicesReducer)
  const { setIsOpenPopupGenerator } = useActions()
  const isGeneratorDeviceContent = deviceContent === EDeviceContent.GENERATOR_DEVICE
  const isGeneratorContent = deviceContent === EDeviceContent.GENERATOR

  const handleAddPopup = () => {
    setIsOpenPopupGenerator(true)
  }

  const handleEditGenerator = () => {
    setIsEditGenerator(!isEditGenerator)
  }

  return (
    <>
      <ButtonToolbar disabled={!isGeneratorContent} onClick={handleAddPopup} icon={'add'} title={TitleButton.Add} />
      <ButtonToolbar
        disabled={!isGeneratorDeviceContent}
        onClick={handleEditGenerator}
        icon={'edit'}
        activated={isEditGenerator}
        title={TitleButton.Edit}
      />
      <ButtonToolbar disabled={true} onClick={() => console.log('remove')} icon={'delete'} title={TitleButton.Delete} />
    </>
  )
}

export default DevicesTools
