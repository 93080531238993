import Submenu from '@/components/controlers/submenu/submenu'
import LeftBar from '@/components/layouts/left-bar/left-bar'
import WorkSpace from '@/components/layouts/work-space/work-space'
import EquipmentTree from '@/components/widgets/equipment-tree/equipment-tree'
import { conditionLinks } from '@/constants/navigation/links.constant'
import { EEquipmentTreeMode } from '@/enums/equipments/equipment-tree-mode.enum'
import type { FC } from 'react'
import React from 'react'
import { Outlet } from 'react-router'

const Condition: FC = () => (
  <>
    <LeftBar>
      <EquipmentTree equipmentTreeMode={EEquipmentTreeMode.CONDITION} />
    </LeftBar>
    <WorkSpace>
      <Submenu links={conditionLinks} />
      <Outlet />
    </WorkSpace>
  </>
)

export default Condition
