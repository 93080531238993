import KinematicSpace from '@/components/widgets/kinematic-space/kinematic-space'
import { EKinematicWorkMode } from '@/enums/kinematic/work-mode.enum'
import { useGetCurrentKinematicScheme } from '@/hooks/api/use-get-current-kinematic-scheme'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'
import { useEffect } from 'react'

const KinematicsConfiguration: FC = () => {
  const { setWorkMode } = useActions()
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)
  const { data: currentKinematic, isFetching: isFetchingCurrentKinematic } =
    useGetCurrentKinematicScheme(selectedMachineId)

  useEffect(() => {
    setWorkMode(EKinematicWorkMode.Configuration)
  }, [setWorkMode])

  return <>{currentKinematic && selectedMachineId && <KinematicSpace kinematicScheme={currentKinematic} />}</>
}

export default KinematicsConfiguration
