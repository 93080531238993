import PlanMachineItem from '@/app/plans/components/plans-main-content/components/plans-list/components/plan-machine-item'
import PlanMeasurementItem from '@/app/plans/components/plans-main-content/components/plans-list/components/plan-measurement-Item'
import PlanPointItem from '@/app/plans/components/plans-main-content/components/plans-list/components/plan-point-item'
import SwitcherIconElement from '@/app/plans/components/plans-main-content/components/plans-list/components/switcher-icon-element'
import type { TDataNode } from '@/app/plans/components/plans-main-content/plans-main-content'
import { ENodeType } from '@/app/plans/components/plans-main-content/plans-main-content'
import type { INodesState } from '@/types/nodes/nodes.type'
import type { TPlan } from '@/types/plan/plan.type'

const generatePlansTreeData = ({
  selectedPlan,
  dataAllNodesState
}: {
  selectedPlan: TPlan | undefined
  dataAllNodesState: INodesState[] | undefined
}) => {
  const treeData: TDataNode[] | undefined = selectedPlan?.machines?.map((machine) => ({
    key: `${selectedPlan?.planId}-${machine.machineId}`,
    machineId: machine.machineId,
    selectable: false,
    nodeType: ENodeType.MACHINE,
    icon: ({ checked }) => <SwitcherIconElement checked={checked} />,
    title: <PlanMachineItem name={machine.name} paused={machine.paused} id={machine.machineId} />,
    children: machine?.points?.map((point) => ({
      // соглашение что id ключа составляется с nodeId + _ + selectedPlanId
      key: `${selectedPlan?.planId}-${point.pointId}`,
      machineId: machine.machineId,
      pointId: point.pointId,
      selectable: false,
      nodeType: ENodeType.POINT,
      icon: ({ checked }) => <SwitcherIconElement checked={checked} />,
      title: <PlanPointItem name={point.name} paused={point.paused} id={point.pointId} />,
      children: point.measurements?.map((measurement) => ({
        key: `${selectedPlan?.planId}-${measurement.measurementId}`,
        machineId: machine.machineId,
        pointId: point.pointId,
        measurementId: measurement.measurementId,
        selectable: false,
        isLeaf: true,
        nodeType: ENodeType.MEASUREMENT,
        icon: ({ checked }) => <SwitcherIconElement checked={checked} />,
        title: (
          <PlanMeasurementItem
            dataAllNodesState={dataAllNodesState}
            name={measurement.name}
            paused={measurement.paused}
            id={measurement.measuringPointId}
          />
        )
      }))
    }))
  }))

  return treeData
}

export default generatePlansTreeData
