import ChartTooltip from '@/app/condition/components/results/components/charts/components/tooltip-wrapper/chart-tooltip'
import { PRECISION_NUMBER } from '@/constants/config.constant'
import { UNIT_GZ } from '@/constants/core/common.constant'
import type { IChartSpectrum } from '@/types/chart/chart-spectrun.type'
import { formatNumber } from '@/utils/common.util'
import type { FC } from 'react'
import type { TooltipProps } from 'recharts'
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'

type TooltipScalarProps = {
  isLog?: boolean
  fMax?: number
  dfValue: number
} & TooltipProps<ValueType, NameType>

const TooltipSpectrum: FC<TooltipScalarProps> = ({ active, payload }) => {
  if (active && payload) {
    const currentData = payload?.[0]?.payload as IChartSpectrum

    return (
      <ChartTooltip>
        <span>A {formatNumber(currentData?.amplitude)}</span>
        {currentData.amplitudeBackgroundLine && (
          <span>Линия фона {formatNumber(currentData?.amplitudeBackgroundLine)}</span>
        )}
        <span>
          {currentData?.frequency.toPrecision(PRECISION_NUMBER)} {UNIT_GZ}
        </span>
        <span>Линия {currentData?.index + 1}</span>
      </ChartTooltip>
    )
  }

  return null
}

export default TooltipSpectrum
