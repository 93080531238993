import PauseIcon from '@/components/icons/equipments/pause-icon'
import ThresholdIndicator from '@/components/widgets/danger-state-indicator/threshold-indicator'
import { doesNodeContainEquipmentId } from '@/components/widgets/equipment-icons/equipment-icons.service'
import StatisticalIndicator from '@/components/widgets/statistical-indicator/statistical-indicator'
import { useGetAllNodesQuery } from '@/store/api/nodes.api'
import type { IEquipmentElement } from '@/types/equipment/equipment-element.interface'
import type { FC } from 'react'

import styles from './equipment-icons.module.css'

type TProps = Omit<IEquipmentElement, 'name'>
const EquipmentIcons: FC<TProps> = ({ id, equipmentIcon, showStatisticalIndicator, showThresholdIndicator }) => {
  const { data: dataAllNodesState } = useGetAllNodesQuery(null, { pollingInterval: 1000 })
  const foundNodeState = dataAllNodesState?.find(doesNodeContainEquipmentId(id))
  const isPaused = foundNodeState?.paused

  return (
    <div className={styles['equipment-icons']}>
      {showStatisticalIndicator && <StatisticalIndicator nodeState={foundNodeState} />}
      {showThresholdIndicator && <ThresholdIndicator nodeState={foundNodeState} />}
      {equipmentIcon}
      {isPaused && <PauseIcon />}
    </div>
  )
}
export default EquipmentIcons
