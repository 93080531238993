import { useGetKinematicElementsQuery, useGetKinematicsQuery } from '@/store/api/kinematic.api'

export const useGetKinematicElements = (selectedMachineId: string | null) => {
  const kinematicsQuery = useGetKinematicsQuery(selectedMachineId, {
    skip: !selectedMachineId
  })

  const kinematicSchemeId = kinematicsQuery.data?.content[0]?.kinematicSchemeId || null
  const kinematicElementsQuery = useGetKinematicElementsQuery(kinematicSchemeId, { skip: !kinematicSchemeId })

  return { kinematicsQuery, kinematicElementsQuery }
}
