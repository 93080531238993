export const enum EDeviceContent {
  VP_3701 = 'VP_3701',
  GENERATOR = 'GENERATOR',
  VP_3711 = 'VP_3711',
  VP_3701_DEVICE = 'VP_3701_DEVICE',
  VP_3701_MODULE = 'VP_3701_MODULE',
  VP_3701_CHANNEL = 'VP_3701_CHANNEL',
  VP_3711_DEVICE = 'VP_3711_DEVICE',
  VP_3711_MODULE = 'VP_3711_MODULE',
  VP_3711_CHANNEL = 'VP_3711_CHANNEL',
  GENERATOR_DEVICE = 'GENERATOR_DEVICE'
}
