export const AppRoute = {
  Condition: 'condition',
  ExpertAnalysis: 'expert-analysis',
  Plans: 'plans',
  OffRoute: 'off-route',
  Reports: 'reports',
  Configuration: 'configuration',
  Settings: 'settings',
  Support: 'support',
  About: 'about',
  Exit: 'exit'
} as const

export const ConditionRoute = {
  Description: 'description',
  MimicDiagram: 'mimic-diagram',
  KinematicsDiagram: 'kinematics-diagram',
  Results: 'results',
  WorkMode: 'work-mode'
} as const

export const ConfigurationRoute = {
  Equipment: 'equipment',
  MimicDiagram: 'mimic-diagram',
  KinematicsDiagram: 'kinematics-diagram',
  Devices: 'devices',
  Connections: 'connections'
} as const

export const SettingsRoute = {
  Server: 'server'
} as const
