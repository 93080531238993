import Main from '@/components/layouts/main/main'
import StatusBoard from '@/components/layouts/status-board/status-board'
import AnalysisModal from '@/components/widgets/modals/analysis-modal'
import Header from '@/components/wrappers/header/header'
import type { FC } from 'react'
import React from 'react'
import { Outlet } from 'react-router'

const AppLayout: FC = () => (
  <>
    <Header />
    <Main>
      <Outlet />
    </Main>
    <StatusBoard>
      <AnalysisModal />
    </StatusBoard>
  </>
)

export default AppLayout
