import { EPhysicalQuantityType } from '@/enums/point/physical-quantity-type.enum'

export const pointTypes = [
  { label: 'Виброускорение', value: EPhysicalQuantityType.VIBRO_ACCELERATION },
  {
    label: 'Виброскорость',
    value: EPhysicalQuantityType.VIBRO_VELOCITY
  },
  {
    label: 'Виброперемещение',
    value: EPhysicalQuantityType.VIBRO_DISPLACEMENT
  },
  {
    label: 'Температура',
    value: EPhysicalQuantityType.TEMPERATURE
  },
  {
    label: 'Напряжение',
    value: EPhysicalQuantityType.VOLTAGE
  },
  { label: 'Ток', value: EPhysicalQuantityType.AMPERAGE },
  { label: 'Фаза', value: EPhysicalQuantityType.PHASE, disabled: true },
  {
    label: 'Частота вращения',
    value: EPhysicalQuantityType.RPM,
    disabled: true
  },
  {
    label: 'Изменение частоты вращения',
    value: EPhysicalQuantityType.DRPM,
    disabled: true
  }
]

export const mapTypePoint: Record<EPhysicalQuantityType, string> = {
  [EPhysicalQuantityType.VIBRO_ACCELERATION]: 'Виброускорение',
  [EPhysicalQuantityType.TEMPERATURE]: 'Температура',
  [EPhysicalQuantityType.RPM]: 'rpm',
  [EPhysicalQuantityType.AMPERAGE]: 'Сила тока',
  [EPhysicalQuantityType.DRPM]: 'drpm',
  [EPhysicalQuantityType.VOLTAGE]: 'Напряжение',
  [EPhysicalQuantityType.VIBRO_VELOCITY]: 'Виброскорость',
  [EPhysicalQuantityType.PHASE]: 'Фаза',
  [EPhysicalQuantityType.VIBRO_DISPLACEMENT]: 'Виброперемещение'
}
