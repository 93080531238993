import { SliceName } from '@/constants/store/slices.constant'
import type { IGlobalUiState } from '@/store/reducers/global-states/global.interface'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: IGlobalUiState = {
  selectedMachineId: null,
  selectedMeasurementId: null,
  selectedPointId: null,
  selectedGroupId: null,
  expendedEquipments: []
}

export const globalSlice = createSlice({
  name: SliceName.Global,
  initialState,
  reducers: {
    setSelectedMachineId(state, action: PayloadAction<string | null>) {
      state.selectedMachineId = action.payload
      state.selectedPointId = null
      state.selectedMeasurementId = null
    },
    setSelectedPointId(state, action: PayloadAction<string | null>) {
      state.selectedPointId = action.payload
      state.selectedMeasurementId = null
    },
    setSelectedMeasurementId(state, action: PayloadAction<string | null>) {
      state.selectedMeasurementId = action.payload
    },
    setSelectedGroupId(state, action: PayloadAction<string | null>) {
      state.selectedGroupId = action.payload
    },
    resetSelectedForMachine(state) {
      state.selectedMachineId = null
      state.selectedPointId = null
      state.selectedMeasurementId = null
    },
    setExpendedEquipments(state, action: PayloadAction<string[]>) {
      state.expendedEquipments = action.payload
    },
    addExpendedEquipment(state, action: PayloadAction<string>) {
      state.expendedEquipments = [...state.expendedEquipments, action.payload]
    }
  }
})
