import useGetMnemoHook from '@/hooks/api/use-get-mnemo-schema.hook'
import { useGetMnemoStatsQuery } from '@/store/api/mnemo.api'

export const useGetMnemoStats = () => {
  const { data: currentMnemoSchemeData } = useGetMnemoHook()

  const mnemoStatsQuery = useGetMnemoStatsQuery(currentMnemoSchemeData?.mnemoSchemeId || '', {
    skip: !currentMnemoSchemeData?.mnemoSchemeId
  })

  return mnemoStatsQuery
}
