import PointIcon from '@/components/icons/equipments/point-icon'
import EquipmentItem from '@/components/widgets/equipment-item/equipment-item'
import { useGetMeasuringPointQuery } from '@/store/api/points.api'
import type { INodesState } from '@/types/nodes/nodes.type'
import type { FC } from 'react'

import styles from './kinematic-point.module.css'

type TProps = {
  pointId: string
  dataAllNodesState?: INodesState[]
}

const KinematicPoint: FC<TProps> = ({ pointId }) => {
  const { data: currentPoint } = useGetMeasuringPointQuery(pointId, {
    skip: !pointId
  })

  if (!currentPoint) {
    return null
  }

  return (
    <div key={pointId} className={styles['kinematic-point']}>
      <EquipmentItem
        element={{
          id: pointId,
          name: currentPoint.name,
          showStatisticalIndicator: true,
          equipmentIcon: <PointIcon />
        }}
      />
    </div>
  )
}

export default KinematicPoint
