import { amplitudeModeConverter } from '@/app/condition/components/results/components/charts/components/chart-spectrum/chart-spectrum.service'
import {
  calculateAmplitudeHarmonic,
  calculateDecibel,
  calculateFrequencyHarmonic
} from '@/app/condition/components/results/components/charts/components/chart-spectrum/chart-spectrum.util'
import { ColorLine } from '@/app/condition/components/results/components/charts/components/chart-spectrum/components/spectrum-overlay/spectrum-overlay.constant'
import type { IDataLines } from '@/app/condition/components/results/components/charts/components/chart-spectrum/interfaces/data-lines.interface'
import type { ISpectrumDataForCursor } from '@/app/condition/components/results/components/charts/components/chart-spectrum/interfaces/spectrum-data-for-cursor.interface'
import type { ISpectrumOverlayData } from '@/app/condition/components/results/components/charts/components/chart-spectrum/interfaces/spectrum-overlay-data.interface'
import type { ITimeSliceData } from '@/app/condition/components/results/components/charts/components/chart-spectrum/interfaces/time-slice-data.interface'
import type { EAmplitudeMode } from '@/enums/charts/chart-value-mode.enum'
import type { EUnitType } from '@/enums/measurment/unit-type.enum'
import type { IMeasurementResult } from '@/types/measurement/measurment-result.type'
import { vibrationConverter } from '@/utils/unit/unit-converter.util'
import { notification } from 'antd'
import { isEmpty, maxBy } from 'lodash'

export const getOverlayRangeAmplitude = (spectrumOverlayData: ISpectrumOverlayData[], sliderRange: number[]) => {
  const [start, end] = sliderRange
  const slicedData = spectrumOverlayData.slice(start, end)

  let minAmplitude = Infinity
  let maxAmplitude = -Infinity

  slicedData.forEach((obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (key.startsWith('amplitude')) {
        const amplitude = value as number
        if (amplitude < minAmplitude) minAmplitude = amplitude
        if (amplitude > maxAmplitude) maxAmplitude = amplitude
      }
    })
  })

  return [minAmplitude, maxAmplitude]
}

export const getNearestPoint = (
  spectrumOverlayData: ISpectrumOverlayData[],
  amplitude: number
): { key: string; amplitude: number; frequency: number } | null => {
  if (!spectrumOverlayData.length) {
    return null
  }

  let closestPoint: { key: string; amplitude: number; frequency: number } | null = null
  let smallestDifference = Infinity

  spectrumOverlayData.forEach((data) => {
    const { frequency } = data

    Object.entries(data).forEach(([key, value]) => {
      if (key.startsWith('amplitude') && typeof value === 'number') {
        const difference = Math.abs(value - amplitude)

        if (difference < smallestDifference) {
          smallestDifference = difference
          closestPoint = { key, amplitude: value, frequency }
        }
      }
    })
  })

  return closestPoint
}

export const defineRangeBoundsForOverlayData = (
  index: number,
  data: ISpectrumOverlayData[],
  deltaFrequency: number,
  widthCursor: number
): [number, number] | null => {
  const lengthData = data.length - 1
  try {
    const sizeToBound = Math.ceil(widthCursor / deltaFrequency / 2)
    const prevIndex = index - sizeToBound
    const nextIndex = index + sizeToBound

    if (prevIndex <= 0 || nextIndex >= lengthData) {
      throw new Error(
        `The adjacent values in the array must be greater than 0 and less than the maximum ${lengthData} value of the array.`
      )
    }

    return [prevIndex, nextIndex]
  } catch (e) {
    console.error(e)
    notification.error({
      message: 'Ошибка выбранного значения',
      description: `Соседнее значение не может быть меньше или равно 0.\nСоседнее значение не может быть больше или равно максимальному значению в массиве (${lengthData}).`,
      duration: 10
    })

    return null
  }
}

export const transformDataForCursor = (spectrumOverlayData: ISpectrumOverlayData[]): ISpectrumDataForCursor[] => {
  // Формирование списка спектров
  const listOfSpectraIds = Object.keys(spectrumOverlayData[0])
    .map((key) => {
      if (key.startsWith('amplitude')) {
        return key.split('-')[1]
      }
    })
    .filter((element) => element)

  // Трансформация данных.
  // Возвращаем результат трансформации.
  return listOfSpectraIds.map((id) => {
    const amplitudeKey = `amplitude-${id}`
    const timestampKey = `timestamp-${id}`
    const backgroundLineKey = `background-line-${id}`

    return {
      id: id as string,
      timestamp: spectrumOverlayData[0][timestampKey],
      data: spectrumOverlayData.map((item) => ({
        amplitude: item[amplitudeKey],
        frequency: item.frequency,
        index: item.index,
        backgroundLine: item[backgroundLineKey]
      }))
    }
  }) as ISpectrumDataForCursor[]
}

export const getFilteredDataByMaxAmplitude = (
  range: [number, number] | null,
  spectrum: ISpectrumDataForCursor[]
): ISpectrumDataForCursor[] | null => {
  if (!range) {
    return null
  }
  return spectrum.map((itemSpectrum) => {
    const { data } = itemSpectrum
    const rangeBoundsData = data.slice(range[0], range[1] + 1)
    const maxByAmplitude = maxBy(rangeBoundsData, 'amplitude')

    const filteredArray = []

    if (maxByAmplitude?.index) {
      const prevDataByAmplitude = data[maxByAmplitude.index - 1]
      const nextDataByAmplitude = data[maxByAmplitude.index + 1]
      filteredArray.push(prevDataByAmplitude, maxByAmplitude, nextDataByAmplitude)
    }

    return {
      ...itemSpectrum,
      data: filteredArray
    } as ISpectrumDataForCursor
  })
}

export const getTimeSliceDataByCursor = (
  spectrumData: ISpectrumDataForCursor[] | null,
  colors: string[]
): ITimeSliceData[] | null => {
  if (!spectrumData) {
    return null
  }
  return spectrumData.map((spectrumItem, index) => {
    const payload = spectrumItem.data.reduce(
      (acc, dataItem) => ({
        frequencies: acc.frequencies ? [...acc.frequencies, dataItem.frequency] : [dataItem.frequency],
        amplitudes: acc.amplitudes ? [...acc.amplitudes, dataItem.amplitude] : [dataItem.amplitude],
        backgroundLines: acc.backgroundLines
          ? [...acc.backgroundLines, dataItem.backgroundLine]
          : [dataItem.backgroundLine]
      }),
      {} as { frequencies: number[]; amplitudes: number[]; backgroundLines: (number | null)[] }
    )
    const { amplitudes, frequencies, backgroundLines } = payload
    const harmonicAmplitude = calculateAmplitudeHarmonic(amplitudes)
    const isNotNullable = backgroundLines.some((item) => item !== null)
    const harmonicBackgroundLine = isNotNullable ? calculateAmplitudeHarmonic(backgroundLines as number[]) : null
    const harmonicFrequency = calculateFrequencyHarmonic(frequencies, amplitudes, harmonicAmplitude)
    return {
      resultId: `amplitude-${spectrumItem.id}`,
      frequency: harmonicFrequency,
      amplitude: harmonicAmplitude,
      timestamp: spectrumItem.timestamp,
      backgroundLine: harmonicBackgroundLine,
      color: colors[index]
    }
  })
}

export const convertTimeSliceAmplitudeToDecibel = (data: ITimeSliceData[]) =>
  data.map((item) => ({ ...item, amplitude: calculateDecibel(item.amplitude) }))

export const findAmplitudeByIndex = (index: number, data: ISpectrumOverlayData[]): number =>
  data[index].frequency as number

const getMaxLengthByData = (data: IMeasurementResult[]): number =>
  Math.max(
    ...data.map((item) => {
      if (item.powerSpecterValue?.amplitudeSpectrum) {
        return item.powerSpecterValue.amplitudeSpectrum.length
      }
      return 0
    })
  )

export const transformDataForSpectrumOverlay = (
  data: IMeasurementResult[],
  dfValue: number,
  sourceUnit: EUnitType,
  targetUnit: EUnitType,
  amplitudeMode: EAmplitudeMode
): ISpectrumOverlayData[] => {
  // Уменьшаем количество точек на одну
  const countValues = getMaxLengthByData(data)

  return Array.from({ length: countValues }, (_, index) =>
    data.reduce(
      (acc, item) => {
        const frequency = dfValue * index
        // const amplitude = Math.sqrt(item.powerSpecterValue?.values[index] as number)
        const sourceSpecter = item.powerSpecterValue?.amplitudeSpectrum as number[]
        const sourceBackgroundLine = item.powerSpecterValue?.backgroundLine as number[]
        let amplitude = sourceSpecter[index]
        let backgroundLine = null
        amplitude = amplitudeModeConverter(
          amplitudeMode,
          vibrationConverter(amplitude, frequency, sourceUnit, targetUnit)
        )

        if (!isEmpty(sourceBackgroundLine)) {
          backgroundLine = amplitudeModeConverter(
            amplitudeMode,
            vibrationConverter(sourceBackgroundLine[index], frequency, sourceUnit, targetUnit)
          )
        }

        acc[`amplitude-${item.resultId}`] = amplitude // Устанавливаем тег "amplitude" для поиска при преобразованиях
        acc[`background-line-${item.resultId}`] = backgroundLine
        acc[`timestamp-${item.resultId}`] = item.timestamp // Устанавливаем тег "timestamp" для сохранения всех данных по времени
        acc.frequency = frequency
        return acc
      },
      { index: index } as ISpectrumOverlayData
    )
  )
}

export const assignColorsToDataLines = (
  data: IMeasurementResult[],
  value: 'amplitude' | 'background-line'
): IDataLines[] => {
  const colorList = Object.values(ColorLine)
  return data.map((item, index) => ({
    resultId: `${value}-${item.resultId}`,
    color: colorList[index % colorList.length],
    timestamp: item.timestamp
  }))
}

export const convertAmplitudeByCb = (
  data: ISpectrumOverlayData[],
  cb: (value: number) => number
): ISpectrumOverlayData[] =>
  data.map((item) => {
    const convertedItem = { ...item }
    for (const key in convertedItem) {
      if (key.includes('amplitude')) {
        convertedItem[key] = cb(item[key] as number)
      }
    }
    return convertedItem
  })

export const extractTimeSliceData = (payload: ISpectrumOverlayData): ITimeSliceData[] => {
  let colorIndex = 0
  return Object.keys(payload).reduce<ITimeSliceData[]>((acc, key) => {
    if (key.startsWith('amplitude')) {
      const id = key.slice(10) // Извлечение идентификатора из ключа
      const amplitude = payload[key] as number
      const timestampKey = `timestamp-${id}`
      const backgroundLineKey = `background-line-${id}`
      const timestamp = payload[timestampKey] as string
      const backgroundLine = payload[backgroundLineKey]

      if (timestamp) {
        const color = payload.colors[colorIndex]
        acc.push({
          amplitude: amplitude,
          timestamp: timestamp,
          backgroundLine: backgroundLine,
          color: color,
          resultId: `amplitude-${id}`,
          frequency: payload.frequency
        })
        colorIndex++
      }
    }
    return acc
  }, [])
}
