import ViewMode from '@/app/condition/components/results/components/results-tools/components/view-mode/view-mode'
import ButtonToolbar from '@/components/controlers/button-toolbar/button-toolbar'
import { ETypeChart } from '@/constants/chart/charts.constant'
import { EChartsViewMode } from '@/enums/charts/chart-view-mode.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMeasurementQuery, useLazyGetMeasurementResultsQuery } from '@/store/api/measurements.api'
import type { IFiltersChart } from '@/types/chart/filters-chart.type'
import { Checkbox, Form, InputNumber, Tooltip } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import classNames from 'classnames'
import type { FC } from 'react'
import { useEffect, useRef } from 'react'
import { shallowEqual } from 'react-redux'

import styles from './results-tools.module.css'

const ResultsTools: FC = () => {
  const { chartsViewMode, cursorSettings, configChart, activatedBackgroundLine } = useTypedSelector(
    (state) => state.chartsUiReducer
  )
  const isViewModeOverlay = chartsViewMode === EChartsViewMode.Overlay
  const isTypeSpectrum = configChart?.typeChart === ETypeChart.SPECTRUM
  const isTypeTiming = configChart?.typeChart === ETypeChart.TIMING
  const {
    resetExpressResults,
    resetStrobeIndexes,
    setIsExpressBarSectionOpen,
    setIsExpressBarStrobeOpen,
    setIsMarkersExpressOpen,
    setIsBarsExpressOpen,
    setWidthCursor,
    setActiveCursor,
    setConfigChart,
    setActivatedBackgroundLine
  } = useActions()
  const {
    selectedMeasurementId,
    isSectionExpressBarOpen,
    isStrobeExpressBarOpen,
    isMarkersExpressBarOpen,
    isBarsExpressBarOpen,
    refForFocusKeys
  } = useTypedSelector(
    (state) => ({
      ...state.globalReducer,
      ...state.expressSectionReducer,
      ...state.expressStrobeReducer,
      ...state.expressMarkersReducer,
      ...state.expressBarsReducer,
      ...state.chartsUiReducer
    }),
    {
      equalityFn: shallowEqual
    }
  )

  const { data: measurement } = useGetMeasurementQuery(selectedMeasurementId, {
    skip: !selectedMeasurementId
  })

  const [triggerGetResults] = useLazyGetMeasurementResultsQuery()
  const [form] = useForm<IFiltersChart>()
  const widthInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    form.setFieldsValue({
      ['count-results']: configChart?.size,
      ['width-cursor']: cursorSettings.width
    })
  }, [configChart?.size, cursorSettings.width, form])

  const handleFinish = () => {
    const fieldsValue = form.getFieldsValue()

    if (configChart) {
      setConfigChart({ ...configChart, size: fieldsValue['count-results'] })
    }

    resetStrobeIndexes()
    resetExpressResults()
    if (fieldsValue['width-cursor'] === 0) {
      setActiveCursor(false)
      setWidthCursor(0)
      return
    }
    setWidthCursor(fieldsValue['width-cursor'])
    setActiveCursor(true)
    if (refForFocusKeys) {
      refForFocusKeys.focus()
    }
  }

  const handleRefreshResultsClick = () => {
    triggerGetResults({
      measurementId: measurement?.measurementId,
      size: configChart?.size,
      sortDir: 'DESC'
    })
  }

  const handleActivateBackgroundLineChange = () => {
    setActivatedBackgroundLine(!activatedBackgroundLine)
  }

  return (
    <ul className={styles['tools-container']}>
      {selectedMeasurementId && (
        <>
          <li>
            {configChart && (
              <Form form={form} className={styles['form']} onFinish={handleFinish}>
                <Form.Item name='count-results' className={styles['form-item']} label='Результатов'>
                  <InputNumber
                    disabled={selectedMeasurementId === ''}
                    min={0}
                    className={styles['input']}
                    controls={false}
                  />
                </Form.Item>
                {isViewModeOverlay && (
                  <Form.Item name='width-cursor' className={styles['form-item']} label='Ширина курсора (Гц)'>
                    <InputNumber
                      min={0}
                      className={styles['input']}
                      controls={false}
                      decimalSeparator={','}
                      ref={widthInputRef}
                    />
                  </Form.Item>
                )}
                <Tooltip title='Применить' placement='bottom'>
                  <ButtonToolbar
                    disabled={selectedMeasurementId === ''}
                    className={styles['button']}
                    icon={'check'}
                    type='submit'
                  />
                </Tooltip>
              </Form>
            )}
          </li>
          <li className={styles['container-buttons']}>
            <Tooltip title='Обновить результаты'>
              <ButtonToolbar icon={'refresh'} onClick={handleRefreshResultsClick} />
            </Tooltip>
            {isTypeSpectrum && (
              <>
                <Tooltip title={'Ряды'} placement='bottom'>
                  <ButtonToolbar
                    disabled={measurement?.programSpecterDescription === null || isViewModeOverlay}
                    icon={'bar_chart_4_bars'}
                    className={classNames({
                      [styles['button-active']]: isBarsExpressBarOpen
                    })}
                    onClick={() => {
                      setIsBarsExpressOpen(!isBarsExpressBarOpen)
                      setIsExpressBarSectionOpen(false)
                      setIsMarkersExpressOpen(false)
                    }}
                  />
                </Tooltip>

                <Tooltip title={'Маркеры'} placement='bottom'>
                  <ButtonToolbar
                    disabled={measurement?.programSpecterDescription === null || isViewModeOverlay}
                    icon={'bar_chart'}
                    className={classNames({
                      [styles['button-active']]: isMarkersExpressBarOpen
                    })}
                    onClick={() => {
                      setIsMarkersExpressOpen(!isMarkersExpressBarOpen)
                      setIsExpressBarSectionOpen(false)
                      setIsBarsExpressOpen(false)
                    }}
                  />
                </Tooltip>
                <Tooltip title={'Сечения'} placement='bottom'>
                  <ButtonToolbar
                    disabled={measurement?.programSpecterDescription === null || isViewModeOverlay}
                    icon={'query_stats'}
                    className={classNames({
                      [styles['button-active']]: isSectionExpressBarOpen
                    })}
                    onClick={() => {
                      setIsExpressBarSectionOpen(!isSectionExpressBarOpen)
                      setIsMarkersExpressOpen(false)
                      setIsBarsExpressOpen(false)
                    }}
                  />
                </Tooltip>
              </>
            )}

            {isTypeTiming && (
              <Tooltip title={'Стробы'} placement='bottom'>
                <ButtonToolbar
                  disabled={measurement?.signalDescription === null}
                  icon={'insights'}
                  className={classNames({
                    [styles['button-active']]: isStrobeExpressBarOpen
                  })}
                  onClick={() => setIsExpressBarStrobeOpen(!isStrobeExpressBarOpen)}
                />
              </Tooltip>
            )}
            {isViewModeOverlay && isTypeSpectrum && (
              <Checkbox checked={activatedBackgroundLine} onChange={handleActivateBackgroundLineChange}>
                Линия фона
              </Checkbox>
            )}
          </li>
          {isTypeSpectrum && (
            <li className={styles['view-mode-item']}>
              <ViewMode />
            </li>
          )}
        </>
      )}
    </ul>
  )
}

export default ResultsTools
