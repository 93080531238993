import type { EUnitType } from '@/enums/measurment/unit-type.enum'
import type { IChartTiming } from '@/types/chart/charts.type'
import { unitConverter } from '@/utils/unit/unit-converter.util'
import { isUndefined, maxBy, minBy } from 'lodash'
import type { RefObject } from 'react'

export const getDiapason = (chartDomBlock: RefObject<HTMLDivElement | null>): number | null => {
  if (chartDomBlock.current) {
    const WIDTH_DIFFERENT = 105.14

    return Math.round((chartDomBlock.current.offsetWidth - WIDTH_DIFFERENT) / 2)
  }

  return null
}

export const getThinnedData = (
  sourceData: number[],
  diapason: number,
  rangeData: number[],
  durationInSec: number,
  fromUnit: EUnitType | null,
  toUnit: EUnitType | null
): IChartTiming[] => {
  const [minValue, maxValue] = rangeData

  // Формируем данные для графика с дополнительными полями
  const chartTiming = sourceData.map((item, index, array) => ({
    value: fromUnit && toUnit ? unitConverter(item, fromUnit, toUnit) : item,
    sourceIndex: index,
    index: index,
    time: index / (array.length / durationInSec)
  }))

  const slicedChartTiming = chartTiming.slice(minValue, maxValue)

  if (slicedChartTiming.length <= diapason * 2) {
    return slicedChartTiming
  }

  const interval = Math.round((maxValue - minValue) / diapason)

  const sampleData: IChartTiming[] = []
  for (let i = 0; i < slicedChartTiming.length; i += interval) {
    const segment = slicedChartTiming.slice(i, i + interval)

    const minValueSegment = minBy(segment, 'value') as IChartTiming
    const maxValueSegment = maxBy(segment, 'value')

    if (minValueSegment && maxValueSegment) {
      if (minValueSegment.sourceIndex < maxValueSegment.sourceIndex) {
        sampleData.push(minValueSegment, maxValueSegment)
      } else {
        sampleData.push(maxValueSegment, minValueSegment)
      }
    }
  }

  return sampleData.map((item, index) => ({ ...item, index }))
}

export const getRangeValues = (chartData: IChartTiming[] | null, range: number[] | null): number[] | null => {
  if (chartData && range && range[0] && range[1]) {
    const values = chartData.map((element) => element.value).slice(range[0], range[1])
    return [Math.min(...values), Math.max(...values)]
  }

  return null
}

export const getRangeTimeValues = (chartData: IChartTiming[] | null, range: number[] | null) => {
  if (!chartData || !range || isUndefined(range[0]) || isUndefined(range[1])) {
    return null
  }

  const pointOne = chartData[range[0]]
  const pointTwo = chartData[range[1]]

  return [pointOne?.time, pointTwo?.time]
}
