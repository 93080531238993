import KinematicSpace from '@/components/widgets/kinematic-space/kinematic-space'
import { EKinematicWorkMode } from '@/enums/kinematic/work-mode.enum'
import { useGetCurrentKinematicScheme } from '@/hooks/api/use-get-current-kinematic-scheme'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'
import { useEffect } from 'react'

import styles from './kinematics-condition.module.css'

const KinematicsCondition: FC = () => {
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)
  const { data: currentKinematic } = useGetCurrentKinematicScheme(selectedMachineId)
  const { setWorkMode, setSelectedKinematic } = useActions()

  // Resetting the UI states of the kinematic scheme
  useEffect(() => {
    setWorkMode(EKinematicWorkMode.Condition)
    setSelectedKinematic(null)
  }, [setSelectedKinematic, setWorkMode])

  return currentKinematic ? (
    <KinematicSpace kinematicScheme={currentKinematic} />
  ) : (
    <p className={styles['text-information']}>Кинематическая схема отсутствует</p>
  )
}

export default KinematicsCondition
