export enum EUnitType {
  MS2 = 'MS2',
  G = 'G',
  MMS2 = 'MMS2',
  MMS = 'MMS',
  MS = 'MS',
  MKM = 'MKM',
  MM = 'MM',
  M = 'M',
  A = 'A',
  MA = 'MA',
  MKA = 'MKA',
  VOLT = 'VOLT',
  M_VOLT = 'M_VOLT',
  U_VOLT = 'U_VOLT',
  DEGREES = 'DEGREES',
  ROTATION_COUNT_IN_SEC = 'ROTATION_COUNT_IN_SEC',
  ROTATION_COUNT_IN_MINUTE = 'ROTATION_COUNT_IN_MINUTE',
  D_ROTATION_COUNT_IN_SEC = 'D_ROTATION_COUNT_IN_SEC',
  D_ROTATION_COUNT_IN_MINUTE = 'D_ROTATION_COUNT_IN_MINUTE',
  DEGREES_CELSIUS = 'DEGREES_CELSIUS',
  DEGREES_FAHRENHEIT = 'DEGREES_FAHRENHEIT',
  DEGREES_KELVIN = 'DEGREES_KELVIN'
}
