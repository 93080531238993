import StrobeModal from '@/app/condition/components/results/components/charts/components/express-sidebar/components/strobe/components/strobe-modal/strobe-modal'
import ButtonPrimary from '@/components/controlers/button-primary/button-primary'
import type { FC, LegacyRef, MutableRefObject } from 'react'
import { useState } from 'react'

import styles from '../../express-sidebar.module.css'

import type { TExpressAnalysis } from '../../../../charts'
import StrobeResultItem from './components/strobe-result-item/strobe-result-item'

interface IProps {
  expressAnalysisItems: TExpressAnalysis
  itemStrobesRefs: MutableRefObject<LegacyRef<HTMLLIElement>[] | null>
  onClickExpressStrobeButton: (index: number) => void
  durationInSec?: number
  dataLength: number
}

const ExpressStrobe: FC<IProps> = ({ expressAnalysisItems, itemStrobesRefs, onClickExpressStrobeButton }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  return (
    <div className={styles['container-express']}>
      <ButtonPrimary onClick={() => setIsPopupOpen(true)} htmlType={'button'} title={'Стробы'} />
      <StrobeModal onClose={() => setIsPopupOpen(false)} open={isPopupOpen} />
      <ul className={styles.list}>
        {expressAnalysisItems.map((result, i) => (
          <StrobeResultItem
            currentIndex={i}
            onClickExpressStrobeButton={() => onClickExpressStrobeButton(i)}
            refStrobeListItem={itemStrobesRefs?.current && itemStrobesRefs?.current[i]}
            key={result.title + i}
            results={result.results}
            title={result.title}
            strobeData={{
              start: result.strobeOptions.start,
              duration: result.strobeOptions.duration,
              period: result.strobeOptions.period,
              offset: null
            }}
          />
        ))}
      </ul>
    </div>
  )
}

export default ExpressStrobe
