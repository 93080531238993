import Group from '@/app/configuration/components/equipments/components/group/group'
import Machine from '@/app/configuration/components/equipments/components/machine/machine'
import Threshold from '@/app/configuration/components/equipments/components/measurements/components/threshold/threshold'
import Measurement from '@/app/configuration/components/equipments/components/measurements/measurement'
import Point from '@/app/configuration/components/equipments/components/point/point'
import { EEquipmentContent } from '@/enums/equipments/equipment-content.enum'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import React, { type FC } from 'react'

const Equipment: FC = () => {
  const { equipmentContent } = useTypedSelector((state) => state.equipmentsUiReducer)

  return (
    <>
      {equipmentContent === EEquipmentContent.GROUP && <Group />}
      {equipmentContent === EEquipmentContent.MACHINE && <Machine />}
      {equipmentContent === EEquipmentContent.POINT && <Point />}
      {equipmentContent === EEquipmentContent.MEASUREMENT && <Measurement />}
      {equipmentContent === EEquipmentContent.TOLERANCE && <Threshold />}
    </>
  )
}

export default Equipment
