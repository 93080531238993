import {
  calculateDecibel,
  calculateLinderByDecibel
} from '@/app/condition/components/results/components/charts/components/chart-spectrum/chart-spectrum.util'
import type { TExpressSection } from '@/store/reducers/charts/express-section.slice'
import type { IChartSpectrum } from '@/types/chart/chart-spectrun.type'
import type { IMeasurement } from '@/types/measurement/measurement.type'

export const calculateDeltaFrequency = (selectedMeasurement: IMeasurement) =>
  // Граничная частота делится на количество линий.

  Number(selectedMeasurement.programSpecterDescription?.visualFreqLimit?.split('_')[1]) /
  Number(selectedMeasurement.programSpecterDescription?.specterLineCount?.split('_')[2])

// export const calculateSKZ = ({
//                                chartData,
//                                startIndex,
//                                endIndex
//                              }: {
//   chartData: IChartSpectrum[]
//   startIndex: number
//   endIndex: number
// }) => {
//   // Проверяем границы startIndex и endIndex
//   if (startIndex < 0 || startIndex >= chartData.length) return 0
//   if (endIndex < 0 || endIndex >= chartData.length) {
//     endIndex = chartData.length - 1
//   }
//
//   // Проверяем, что startIndex не превышает endIndex
//   if (startIndex > endIndex) return 0
//
//   let sumOfSquares = 0
//
//   for (let i = startIndex; i <= endIndex; i++) {
//     const dataPoint = chartData[i]
//
//     // Проверяем наличие и валидность данных
//     if (!dataPoint) {
//       return 0
//     }
//
//     // Суммируем квадраты амплитуд
//     sumOfSquares += Math.pow(dataPoint.amplitude, 2)
//   }
//
//   // Вычисляем среднеквадратическое значение (SKZ)
//   const result = Math.sqrt(sumOfSquares / (endIndex - startIndex + 1))
//
//
//   return result
// }

export const calculateSKZ = ({
  chartData,
  startIndex,
  endIndex,
  isDb
}: {
  chartData: IChartSpectrum[]
  startIndex: number
  endIndex: number
  isDb: boolean
}) => {
  const linerChartData = isDb
    ? chartData.map((item) => ({ ...item, amplitude: calculateLinderByDecibel(item.amplitude) }))
    : chartData

  let result = 0

  if (startIndex < 0) return 0

  if (endIndex < 0) {
    endIndex = linerChartData.length - 1
  }

  for (let i = startIndex; i < endIndex; i++) {
    if (linerChartData[i] === undefined) {
      return 0
    }

    const { amplitude } = linerChartData[i]
    result += amplitude
  }

  result = Math.sqrt(result) / Math.sqrt(2)

  return isDb ? calculateDecibel(result) : result
}

export const calculateAmFm = ({
  chartData,
  startIndex,
  endIndex,
  selectedMeasurement,
  isDb
}: {
  chartData: IChartSpectrum[]
  startIndex: number
  endIndex: number
  selectedMeasurement: IMeasurement
  isDb: boolean
}) => {
  const linerChartData = isDb
    ? chartData.map((item) => ({ ...item, amplitude: calculateLinderByDecibel(item.amplitude) }))
    : chartData

  let maxIndex = 0
  let am = 0
  let fm = 0
  const dfValue = calculateDeltaFrequency(selectedMeasurement)

  if (startIndex < 0) {
    return { am: 0, fm: 0 }
  }

  if (endIndex < 0) {
    endIndex = linerChartData.length - 1
  }

  for (let i = startIndex; i < endIndex; i++) {
    if (linerChartData[i] === undefined) {
      return { am: 0, fm: 0 }
    }

    if (linerChartData[i].amplitude > am) {
      maxIndex = i

      const { amplitude } = linerChartData[i]
      am = amplitude
    }
  }
  fm = dfValue * maxIndex

  am = isDb ? calculateDecibel(am) : am
  return { am, fm }
}

export const binarySearch = (data: IChartSpectrum[], target: number, start: number, end: number): IChartSpectrum => {
  if (end < 1) return data[0]

  const middle = Math.floor(start + (end - start) / 2)

  const middleFreq = data[middle].frequency
  const startFreq = data[start].frequency
  const endFreq = data[end].frequency

  if (target === middleFreq) {
    return data[middle]
  }

  if (end - 1 === start) {
    return Math.abs(startFreq - target) > Math.abs(endFreq - target) ? data[end] : data[start]
  }

  if (target > middleFreq) return binarySearch(data, target, middle, end)

  if (target < middleFreq) return binarySearch(data, target, start, middle)

  return data[0]
}

export const calculateExpressSectionsData = ({
  chartData,
  sections,
  selectedMeasurement,
  isDb
}: {
  chartData: IChartSpectrum[]
  sections: TExpressSection[]
  selectedMeasurement: IMeasurement
  isDb: boolean
}) =>
  sections
    .map((section) => {
      const startIndex = binarySearch(chartData, section.fl, 0, chartData.length - 1)?.index

      const endIndex = binarySearch(chartData, section.fh, 0, chartData.length - 1)?.index

      return [startIndex, endIndex]
    })
    .map((slicedArray) => {
      const [startIndex, endIndex] = slicedArray

      const calculated = calculateAmFm({
        chartData,
        startIndex,
        endIndex,
        selectedMeasurement,
        isDb
      })

      return {
        skz: calculateSKZ({ chartData, startIndex, endIndex, isDb }),
        fm: calculated.fm,
        am: calculated.am
      }
    })
