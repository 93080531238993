import ServerToolbar from '@/app/settings/components/server-toolbar/server-toolbar'
import Submenu from '@/components/controlers/submenu/submenu'
import ToolBar from '@/components/layouts/tool-bar/tool-bar'
import WorkSpace from '@/components/layouts/work-space/work-space'
import { settingsLinks } from '@/constants/navigation/links.constant'
import type { FC } from 'react'
import React from 'react'
import { Outlet } from 'react-router'

const Settings: FC = () => (
  <>
    <ToolBar>
      <ServerToolbar />
    </ToolBar>
    <WorkSpace>
      <Submenu links={settingsLinks} />
      <Outlet />
    </WorkSpace>
  </>
)

export default Settings
