import useGetMnemoSchema from '@/hooks/api/use-get-mnemo-schema.hook'
import { useGetMnemonicElementQuery } from '@/store/api/mnemo.api'

import { useTypedSelector } from '../use-typed-selector'

export const useGetMnemonicElement = () => {
  const { mnemoSchemeItem } = useTypedSelector((state) => state.mnemonicReducer)

  const { data } = useGetMnemoSchema()

  const dataQuery = useGetMnemonicElementQuery(
    {
      mnemoSchemeId: data?.mnemoSchemeId,
      mnemoElementId: mnemoSchemeItem?.mnemoElementId
    },
    { skip: !data?.mnemoSchemeId || !mnemoSchemeItem?.mnemoElementId }
  )

  return dataQuery
}
