import CommonLevelForm from '@/app/configuration/components/equipments/components/measurements/components/common-level-form/common-level-form'
import SignalForm from '@/app/configuration/components/equipments/components/measurements/components/signal-form/signal-form'
import SpecterForm from '@/app/configuration/components/equipments/components/measurements/components/specter-form/specter-form'
import TemperatureForm from '@/app/configuration/components/equipments/components/measurements/components/temperature-form/temperature-form'
import type {
  TMeasurementForm,
  TMeasurementStatic
} from '@/app/configuration/components/equipments/components/measurements/measurement.interface'
import {
  generateMeasurementName,
  getDefaultValues,
  getMeasurementStatic,
  getMeasurementTypeOptions,
  mapMeasurementToForm,
  mapToRequestMeasurement
} from '@/app/configuration/components/equipments/components/measurements/measurement.service'
import { getTypePoint } from '@/app/configuration/components/equipments/components/point/point.service'
import ButtonPrimary from '@/components/controlers/button-primary/button-primary'
import TextStatic from '@/components/controlers/form-controls/text-static/text-static'
import ContentWrapper from '@/components/controlers/panel/content-wrapper/content-wrapper'
import FormItemApp from '@/components/controlers/panel/form-item-mcm/form-item-app'
import MeasurementIcon from '@/components/icons/equipments/measurement-icon'
import measurementIcon from '@/components/icons/equipments/measurement-icon'
import EquipmentIcons from '@/components/widgets/equipment-icons/equipment-icons'
import HeaderConfiguration from '@/components/widgets/header-work-space/header-configuration'
import Spinner from '@/components/widgets/spinner/spinner'
import ButtonsWrapper from '@/components/wrappers/buttons-wrapper/buttons-wrapper'
import ControlsWrapper from '@/components/wrappers/controls-wrapper/controls-wrapper'
import { ButtonTitle } from '@/constants/core/common.constant'
import { mapDeviceType, targetTypeOptions } from '@/constants/device/device-options.constant'
import { mapMeasurementType } from '@/constants/measurement/measurements.constant'
import { mapUnitType } from '@/constants/measurement/unit.constant'
import { ETargetType } from '@/enums/connection/target-type.enum'
import { EEquipmentMode } from '@/enums/equipments/equipment-mode.enum'
import { EEquipmentTree } from '@/enums/equipments/equipment-tree.enum'
import { ETypeMeasurement } from '@/enums/measurment/type-measurement.enum'
import { EUnitType } from '@/enums/measurment/unit-type.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import {
  useCreateMeasurementMutation,
  useGetMeasurementQuery,
  useGetMeasurementsQuery,
  useUpdateMeasurementsStatusMutation
} from '@/store/api/measurements.api'
import { useGetAllNodesQuery } from '@/store/api/nodes.api'
import { useGetMeasuringPointQuery } from '@/store/api/points.api'
import { getUnitTypeOptions } from '@/utils/measurement/get-filter-unit.unit'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Form, Select, Switch } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { useForm } from 'antd/lib/form/Form'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'

import styles from '@/app/configuration/components/equipments/components/measurements/measurement.module.css'

const Measurement: FC = () => {
  const { selectedPointId: pointId, selectedMeasurementId: measurementId } = useTypedSelector(
    (state) => state.globalReducer
  )
  const { equipmentMode } = useTypedSelector((state) => state.equipmentsUiReducer)

  const { setEquipmentMode, setSelectedMeasurementId, updateTreeMenuItem, setSelectedTreeItems, updateTreeMenuTitle } =
    useActions()
  const [measurementForm] = useForm<TMeasurementForm>()
  const [staticData, setStaticData] = useState<TMeasurementStatic | null>(null)
  const { data: measurement, isLoading: isLoadingMeasurement } = useGetMeasurementQuery(measurementId, {
    skip: !measurementId,
    refetchOnMountOrArgChange: true
  })

  const { data: point } = useGetMeasuringPointQuery(pointId, {
    skip: !pointId
  })

  const { data: measurements } = useGetMeasurementsQuery(pointId, {
    skip: !pointId
  })
  const isAddMode = equipmentMode === EEquipmentMode.ADD
  const isEditMode = equipmentMode === EEquipmentMode.DEFAULT

  const [createMeasurement, { isLoading: isLoadingCreateMeasurement }] = useCreateMeasurementMutation()
  const [updateMeasurementsStatus, { isLoading: isLoadingUpdateMeasurementStatus }] =
    useUpdateMeasurementsStatusMutation()

  const measurementTypeWatched = useWatch('measurementType', measurementForm)
  const targetTypeWatched = useWatch('targetType', measurementForm)
  const typePoint = getTypePoint(point)
  const unitOptions = getUnitTypeOptions(point?.physicalQuantityType)
  const measurementTypeOptions = getMeasurementTypeOptions(point)

  const measurementType = measurementTypeWatched || staticData?.measurementType
  const targetType = targetTypeWatched || staticData?.targetType

  const isSignalDescription = measurementType === ETypeMeasurement.SignalDescription
  const isProgramSpecterDescription = measurementType === ETypeMeasurement.ProgramSpecterDescription
  const isCommonLevelDescription = measurementType === ETypeMeasurement.CommonLevelDescription
  const isTemperatureDescription = measurementType === ETypeMeasurement.TemperatureDescription
  const isLtr24 = targetType === ETargetType.LTR24

  const filteredTypeTargetOptions = point
    ? targetTypeOptions.filter((option) => option.availableFor.includes(point.physicalQuantityType))
    : undefined

  useEffect(() => {
    setStaticData(null)
  }, [])

  useEffect(() => {
    if (measurement && point && isEditMode) {
      const staticDataFromResponse = getMeasurementStatic(measurement)
      const formData = mapMeasurementToForm(measurement)
      setStaticData(staticDataFromResponse)
      measurementForm.setFieldsValue(formData)
    }
  }, [isEditMode, measurement, measurementForm, point])

  useEffect(() => {
    if (isAddMode) {
      setStaticData(null)
      measurementForm.resetFields()
      if (point?.physicalQuantityType) {
        measurementForm.setFieldsValue(getDefaultValues(point.physicalQuantityType))
      }
    }
  }, [isAddMode, measurementForm, point?.physicalQuantityType])

  const handleMeasurementFinish = async () => {
    const { getFieldsValue } = measurementForm
    const measurementFormValues = getFieldsValue()
    try {
      if (isAddMode && pointId) {
        const measurementName = generateMeasurementName(measurementFormValues, measurements?.content)
        const measurementForRequest = mapToRequestMeasurement(measurementFormValues, measurementName, pointId)

        if (measurementForRequest && measurementName) {
          const { id } = await createMeasurement(measurementForRequest).unwrap()
          setSelectedMeasurementId(id)
          updateTreeMenuItem({
            id: id,
            name: measurementName,
            parentKey: pointId,
            group: EEquipmentTree.MEASUREMENTS,
            icon: measurementIcon
          })
          setSelectedTreeItems([id])
          setEquipmentMode(EEquipmentMode.DEFAULT)
          successNotificationCreate('Вид измерения успешно добавлен')
        }
      }
    } catch (err) {
      errorNotificationCreate(err)
    }
  }

  const handleChangePaused = async () => {
    if (measurementId && isEditMode) {
      const isActivated = measurementForm.getFieldValue('activated')
      try {
        await updateMeasurementsStatus({
          updateData: {
            paused: !isActivated
          },
          measurementId
        }).unwrap()
      } catch (err) {
        errorNotificationCreate(err)
      }
    }
  }

  const getTitle = () => {
    if (isAddMode) {
      return 'Добавление нового вида измерения'
    }

    return `Вид измерения ${measurement?.name}`
  }

  const resetMeasurementForm = () => {
    measurementForm.resetFields()
  }

  const handleUnitTypeSelect = (unit: EUnitType) => {
    if (unit === EUnitType.G) {
      measurementForm.setFieldValue(['commonLevelDescription', 'lvl0db'], 1e-7)
      measurementForm.setFieldValue(['programSpecterDescription', 'lvl0db'], 1e-7)
      return
    }
    measurementForm.setFieldValue(['commonLevelDescription', 'lvl0db'], 1e-6)
    measurementForm.setFieldValue(['programSpecterDescription', 'lvl0db'], 1e-6)
  }

  if (isLoadingMeasurement) {
    return <Spinner />
  }

  return (
    <>
      <HeaderConfiguration content={getTitle()} />
      <ContentWrapper>
        <Form
          form={measurementForm}
          className={styles.container}
          layout='horizontal'
          onFinish={handleMeasurementFinish}
        >
          <ControlsWrapper>
            {isEditMode && <TextStatic label='Наименование' value={staticData?.name} />}
            <TextStatic label='Вид контроля' value={typePoint} />

            {isAddMode && (
              <FormItemApp
                name='targetType'
                label='Тип устройства'
                rules={[{ required: true, message: 'Поле обязательно' }]}
              >
                <Select options={filteredTypeTargetOptions} placeholder='Выберите тип устройства' />
              </FormItemApp>
            )}
            {isEditMode && staticData?.targetType && (
              <TextStatic label='Тип устройства' value={mapDeviceType[staticData?.targetType]} />
            )}
          </ControlsWrapper>

          <ControlsWrapper>
            {isAddMode && (
              <FormItemApp
                name='measurementType'
                label='Вид измерения'
                rules={[{ required: isAddMode, message: 'Выберите вид измерения' }]}
              >
                <Select options={measurementTypeOptions} placeholder='Выберите вид измерения' />
              </FormItemApp>
            )}

            {isEditMode && staticData?.measurementType && (
              <TextStatic label='Вид измерения' value={mapMeasurementType[staticData.measurementType]} />
            )}

            {isAddMode && (
              <FormItemApp
                name='unitType'
                label='Единица измерения'
                rules={[{ required: isAddMode, message: 'Поле обязательно' }]}
              >
                <Select
                  placeholder='Выберите единицу измерения'
                  options={unitOptions}
                  onSelect={handleUnitTypeSelect}
                />
              </FormItemApp>
            )}

            {isEditMode && staticData?.unitType && (
              <TextStatic label='Единица измерения' value={mapUnitType[staticData.unitType]} />
            )}

            {isEditMode && (
              <FormItemApp
                name='activated'
                label='Активно'
                rules={[{ required: isAddMode, message: 'Поле обязательно' }]}
              >
                <Switch onChange={handleChangePaused} loading={isLoadingUpdateMeasurementStatus} />
              </FormItemApp>
            )}
          </ControlsWrapper>
          {measurementType && (
            <ControlsWrapper>
              <h4 className={styles.title}>Параметры измерения</h4>
              {isSignalDescription && (
                <SignalForm
                  isLtr24={isLtr24}
                  isEditMode={isEditMode}
                  isAddMode={isAddMode}
                  signalDescription={staticData?.signalDescription}
                />
              )}
              {isProgramSpecterDescription && (
                <SpecterForm
                  isEditMode={isEditMode}
                  isAddMode={isAddMode}
                  programSpecterDescription={staticData?.programSpecterDescription}
                />
              )}
              {isCommonLevelDescription && (
                <CommonLevelForm
                  isEditMode={isEditMode}
                  isAddMode={isAddMode}
                  commonLevelDescription={staticData?.commonLevelDescription}
                />
              )}
              {isTemperatureDescription && (
                <TemperatureForm
                  measurementForm={measurementForm}
                  isEditMode={isEditMode}
                  isAddMode={isAddMode}
                  temperatureDescription={staticData?.temperatureDescription}
                />
              )}
            </ControlsWrapper>
          )}
          {isAddMode && (
            <ControlsWrapper>
              <ButtonsWrapper>
                <ButtonPrimary htmlType={'button'} title={ButtonTitle.Reset} onClick={resetMeasurementForm} />
                <ButtonPrimary
                  htmlType={'submit'}
                  title={ButtonTitle.Save}
                  disabled={isEditMode}
                  loading={isLoadingCreateMeasurement}
                />
              </ButtonsWrapper>
            </ControlsWrapper>
          )}
        </Form>
      </ContentWrapper>
    </>
  )
}
export default Measurement
