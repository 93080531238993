import cn from 'classnames'
import type { FC } from 'react'

import styles from './menu-tree.module.css'

const PauseIcon: FC = () => (
  <span style={{ border: '1px solid #000' }} className={cn('material-symbols-outlined', styles['icon'])}>
    pause
  </span>
)

export default PauseIcon
