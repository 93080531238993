import { calculateDecibel } from '@/app/condition/components/results/components/charts/components/chart-spectrum/chart-spectrum.util'
import { EAmplitudeMode, type EFrequencyMode } from '@/enums/charts/chart-value-mode.enum'
import { generateGraphTicks, generateGraphTicksLog } from '@/utils/chart/generate-graph-ticks'

export const generateTicks = (
  rangeData: number[] | null,
  valueMode: EAmplitudeMode | EFrequencyMode,
  maxCountLines: number
) => {
  if (!rangeData) {
    return null
  }

  const [minValue, maxValue] = rangeData

  if (valueMode === EAmplitudeMode.LOG) {
    return generateGraphTicksLog(minValue, maxValue)
  } else {
    return generateGraphTicks([minValue, maxValue], maxCountLines)
  }
}

export const amplitudeModeConverter = (mode: EAmplitudeMode, value: number) => {
  switch (mode) {
    case EAmplitudeMode.LINER:
      return value
    case EAmplitudeMode.DB:
      return calculateDecibel(value)
    case EAmplitudeMode.LOG:
      return value
  }
}
