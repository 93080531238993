import {
  getChartData,
  getChartThresholds,
  getInitialRangeValues,
  getInitialValues
} from '@/app/condition/components/results/components/charts/components/chart-scalar/chart-scalar.service'
import CustomLabel from '@/app/condition/components/results/components/charts/components/chart-scalar/components/custom-label/custom-label'
import TooltipScalar from '@/app/condition/components/results/components/charts/components/chart-scalar/components/tooltip-scalar/tooltip-scalar'
import ChartsTools from '@/app/condition/components/results/components/charts/components/chart-tools/charts-tools'
import ChartUnits from '@/app/condition/components/results/components/charts/components/chart-units/chart-units'
import ChartWrapper from '@/app/condition/components/results/components/charts/components/chart-wrapper/chart-wrapper'
import SliderApp from '@/app/condition/components/results/components/charts/components/slider-app/slider-app'
import Spinner from '@/components/widgets/spinner/spinner'
import { mapThresholdColor } from '@/constants/threshold/threshold.constant'
import { ETypeMeasurement } from '@/enums/measurment/type-measurement.enum'
import { EUnitType } from '@/enums/measurment/unit-type.enum'
import useGetMeasurement from '@/hooks/api/use-get-measurement.hook'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetThresholdQuery } from '@/store/api/measurements.api'
import type { IMeasurementScalarResult } from '@/types/chart/charts.type'
import { chartFormatValue } from '@/utils/chart/chart-format-value'
import { generateGraphTicks } from '@/utils/chart/generate-graph-ticks'
import { getStepSlider } from '@/utils/chart/get-step-slider'
import { getTypeMeasurement } from '@/utils/measurement/get-type-measurement'
import { unitConverter } from '@/utils/unit/unit-converter.util'
import { Flex } from 'antd'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { ceil } from 'lodash'
import type { FC } from 'react'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'

type ChartTemperatureProps = {
  title: string
  scalarData?: IMeasurementScalarResult[]
}

const OFFSET = 10

const ChartScalar: FC<ChartTemperatureProps> = ({ title, scalarData }) => {
  const { selectedMeasurementId } = useTypedSelector((state) => state.globalReducer)
  const { data: measurement } = useGetMeasurement()
  const { data: threshold, isLoading: isLoadingThreshold } = useGetThresholdQuery(selectedMeasurementId, {
    skip: !selectedMeasurementId
  })

  const isCommonLevelDescription = getTypeMeasurement(measurement) === ETypeMeasurement.CommonLevelDescription
  const [sliderDisabled, setSliderDisabled] = useState(true)
  const [sliderValues, setSliderValues] = useState<number[] | null>(null)

  const [sliderIndexes, setSliderIndexes] = useState<number[] | null>(null)
  const [targetUnit, setTargetUnit] = useState<EUnitType>(measurement?.unitType || EUnitType.G)
  const sourceUnit = measurement?.unitType ?? EUnitType.G
  const chartRef = useRef<any>(null)
  const [chartWidth, setChartWidth] = useState(0)
  const [chartHeight, setChartHeight] = useState(0)

  const ticksX = generateGraphTicks(sliderIndexes, 14) || undefined
  const ticksY = generateGraphTicks(sliderValues, 8) || undefined

  const getPixelOffset = (distance: number, slider: number[] | null) => {
    if (slider) {
      const range = slider[1] - slider[0]
      const pixelsPerData = distance / range
      return OFFSET / pixelsPerData
    }

    return 0
  }
  const pixelXOffset = getPixelOffset(chartWidth, sliderIndexes)
  const pixelYOffset = getPixelOffset(chartHeight, sliderValues)

  const chartThreshold = useMemo(() => {
    if (threshold && sourceUnit && sliderValues) {
      return getChartThresholds(threshold, sliderValues, sourceUnit, targetUnit, pixelYOffset)
    }
  }, [threshold, sourceUnit, targetUnit, sliderValues, pixelYOffset])

  const chartData = useMemo(() => {
    if (!scalarData || !sourceUnit) {
      return undefined
    }
    return getChartData(scalarData, sourceUnit, targetUnit)
  }, [scalarData, targetUnit, sourceUnit])

  const rangeIndexes = useMemo<number[]>(() => {
    if (!scalarData) {
      return [0, 0]
    }

    if (scalarData.length === 1) {
      return [0, 2]
    }

    return [1, scalarData.length]
  }, [scalarData])

  const rangeValues = useMemo<number[] | null>(
    () => (chartData && sliderIndexes ? getInitialRangeValues(chartData) : null),
    [chartData, sliderIndexes]
  )

  const stepY = getStepSlider(40, rangeValues)

  useEffect(() => {
    setSliderIndexes(rangeIndexes)
  }, [rangeIndexes])

  useEffect(() => {
    if (rangeValues && !sliderValues) {
      setSliderValues(rangeValues)
    }
  }, [rangeValues, sliderValues])

  const handleResetValueDoubleClick = () => {
    if (rangeValues) {
      setSliderValues(rangeValues)
    }
  }

  const handleResize = (width: number, height: number) => {
    // Определяем реальные атрибуты графика.
    setChartWidth(width - 84.11)
    setChartHeight(height - 54.01)
  }

  const handleSliderIndex = (range: number[]) => {
    if (chartData) {
      setSliderIndexes(range)
      if (sliderDisabled) {
        setSliderValues(getInitialValues(chartData, range))
      }
    }
  }

  const handleResetIndexesDoubleClick = () => {
    if (rangeIndexes) {
      setSliderIndexes(rangeIndexes)
    }
  }

  const handleSliderDisableCheckboxChange = (evt: CheckboxChangeEvent) => {
    const notChecked = !evt.target.checked
    setSliderDisabled(notChecked)
    if (chartData) {
      if (notChecked && sliderIndexes) {
        setSliderValues(getInitialValues(chartData, sliderIndexes))
        return
      }
      setSliderValues(getInitialValues(chartData, rangeIndexes))
    }
  }

  const handleUnitChange = (unit: EUnitType) => {
    setTargetUnit(unit)
    setSliderValues((prev) => (prev ? prev.map((item) => unitConverter(item, targetUnit, unit)) : null))
  }

  if (isLoadingThreshold) {
    return <Spinner />
  }

  if (scalarData?.length === 0) {
    return null
  }

  return (
    <ChartWrapper title={title}>
      <Flex>
        <SliderApp
          min={rangeValues ? rangeValues[0] : 0}
          max={rangeValues ? rangeValues[1] : 0}
          value={sliderValues || [0, 0]}
          onChange={setSliderValues}
          range={{ draggableTrack: true }}
          vertical={true}
          step={stepY}
          tooltip={{ formatter: chartFormatValue }}
          onDoubleClick={handleResetValueDoubleClick}
          onCheckboxChange={handleSliderDisableCheckboxChange}
          disabled={sliderDisabled}
        />
        <Flex flex={1} vertical={true} gap={5}>
          <ChartsTools>
            <ChartUnits sourceUnit={sourceUnit} onSetUnitChange={handleUnitChange} targetUnit={targetUnit} />
          </ChartsTools>
          <ResponsiveContainer height={400} ref={chartRef} onResize={handleResize}>
            <ComposedChart data={chartData}>
              <XAxis
                padding={{ left: OFFSET, right: OFFSET }}
                dataKey='index'
                type='number'
                domain={sliderIndexes || [0, 0]}
                axisLine={true}
                ticks={ticksX}
                tick={{ fill: 'black' }}
                allowDataOverflow={true}
              />
              <YAxis
                padding={{ top: OFFSET, bottom: OFFSET }}
                dataKey='value'
                axisLine={true}
                domain={sliderValues || [0, 0]}
                allowDataOverflow={true}
                ticks={ticksY}
                tickLine={true}
                tick={{ fill: 'black' }}
              />
              <CartesianGrid strokeDasharray='3 3' />
              {chartThreshold?.map((thresholdItem, index) => (
                <Fragment key={index}>
                  <ReferenceLine y={thresholdItem.hysteresis} stroke={'red'} strokeDasharray='5 5' />
                  <ReferenceArea
                    y1={thresholdItem.lower}
                    y2={thresholdItem.upper}
                    x1={sliderIndexes ? sliderIndexes[0] - pixelXOffset : 0}
                    x2={sliderIndexes ? sliderIndexes[1] + pixelXOffset : 0}
                    fill={mapThresholdColor[thresholdItem.thresholdLevel]}
                    fillOpacity={0.2}
                    ifOverflow='hidden'
                  />
                  <ReferenceLine
                    y={thresholdItem.lower}
                    stroke={'black'}
                    label={(props) => <CustomLabel value={ceil(thresholdItem.lower || 0, 3)} {...props} />}
                  />
                </Fragment>
              ))}

              <Line
                isAnimationActive={false}
                dot={{ fill: '#444', stroke: '#444', strokeWidth: 0.5 }}
                dataKey='value'
                stroke='#444444'
                strokeWidth={0}
                ref={chartRef}
              />

              <Tooltip content={<TooltipScalar unit={targetUnit} />} isAnimationActive={false} />
            </ComposedChart>
          </ResponsiveContainer>
          <SliderApp
            onDoubleClick={handleResetIndexesDoubleClick}
            min={rangeIndexes[0]}
            max={rangeIndexes[1]}
            value={sliderIndexes || [0, 0]}
            onChange={handleSliderIndex}
            range={{ draggableTrack: true }}
          />
        </Flex>
      </Flex>
    </ChartWrapper>
  )
}

export default ChartScalar
