import PointIcon from '@/components/icons/equipments/point-icon'
import EquipmentItem from '@/components/widgets/equipment-item/equipment-item'
import StatisticalIndicator from '@/components/widgets/statistical-indicator/statistical-indicator'
import { EEquipmentTree } from '@/enums/equipments/equipment-tree.enum'
import type { FC } from 'react'

interface IProps {
  name: string
  paused: boolean
  id: string
}

const PlanPointItem: FC<IProps> = ({ name, paused, id }) => (
  <EquipmentItem
    element={{
      id,
      name,
      paused,
      indicator: <StatisticalIndicator id={id} type={EEquipmentTree.POINTS} />,
      equipmentIcon: <PointIcon />
    }}
  />
)

export default PlanPointItem
