import { ApiResource, ApiTag } from '@/constants/store/api.constants'
import { baseApi } from '@/store/api/api-base-query'
import type { INodesState } from '@/types/nodes/nodes.type'

export const nodesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllNodes: builder.query<INodesState[], void | null>({
      query: () => ({
        url: `${ApiResource.GetAllNodes}/get-all-nodes`,
        method: 'GET'
      }),
      providesTags: [ApiTag.GetAllNodes]
    }),
    getAllParents: builder.query<{ id: string; name: string }[], { objectId: string | null }>({
      query: ({ objectId }) => ({
        url: `${ApiResource.GetAllNodes}/get-all-parents?objectId=${objectId}`,
        method: 'GET'
      }),
      providesTags: [ApiTag.GetAllNodes]
    })
  })
})

export const { useGetAllNodesQuery, useGetAllParentsQuery, useLazyGetAllParentsQuery } = nodesApi
