import type { ITreeNode } from '@/types/tree/tree-node.interface'
import type { Key } from 'react'

export const findParentKeys = (key: string, nodes: ITreeNode[], parentKey?: Key): Key[] => {
  for (const node of nodes) {
    if (node.key === key) {
      return parentKey ? [parentKey] : []
    }
    if (node.children) {
      const result = findParentKeys(key, node.children, node.key)
      if (result.length > 0) {
        return parentKey ? [parentKey, ...result] : result
      }
    }
  }
  return []
}
