import type { IMachineForm } from '@/app/configuration/components/equipments/components/machine/machine.interface'
import {
  mapFromResponseMachine,
  mapToRequestMachine,
  validateNotAllZero
} from '@/app/configuration/components/equipments/components/machine/machine.service'
import ButtonPrimary from '@/components/controlers/button-primary/button-primary'
import ContentWrapper from '@/components/controlers/panel/content-wrapper/content-wrapper'
import FormItemApp from '@/components/controlers/panel/form-item-mcm/form-item-app'
import PanelTitle from '@/components/controlers/panel/panel-title/panel-title'
import machineIcon from '@/components/icons/equipments/machine-icon'
import DocTooltip from '@/components/widgets/doc-tooltip/doc-tooltip'
import { KEY_ROOT } from '@/components/widgets/equipment-tree/equipment-tree.constant'
import HeaderConfiguration from '@/components/widgets/header-work-space/header-configuration'
import Spinner from '@/components/widgets/spinner/spinner'
import ButtonsWrapper from '@/components/wrappers/buttons-wrapper/buttons-wrapper'
import ControlsWrapper from '@/components/wrappers/controls-wrapper/controls-wrapper'
import { ButtonTitle } from '@/constants/core/common.constant'
import { FormKey } from '@/constants/doc-tooltip.constant'
import { MachineLabel, MachineName } from '@/constants/machine/machines-form.constant'
import { EEquipmentMode } from '@/enums/equipments/equipment-mode.enum'
import { EEquipmentTree } from '@/enums/equipments/equipment-tree.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import {
  useCreateMachineMutation,
  useGetMachineQuery,
  useUpdateMachinesMutation,
  useUpdateMachinesStatusMutation
} from '@/store/api/machines.api'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Form, Input, InputNumber, Switch } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import { useForm } from 'antd/lib/form/Form'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'

import styles from './machine-form.module.css'

interface IValuesControl {
  minutes?: number
  seconds?: number
}

const Machine: FC = () => {
  const { selectedGroupId: groupId, selectedMachineId: machineId } = useTypedSelector((state) => state.globalReducer)
  const { equipmentMode } = useTypedSelector((state) => state.equipmentsUiReducer)
  const [machineForm] = useForm<IMachineForm>()
  const [savedData, setSavedData] = useState<IMachineForm | undefined>(undefined)
  const [maxValuesControl, setMaxValuesControl] = useState<IValuesControl>({})
  const [createMachine, { isLoading: isLoadingCreateMachine }] = useCreateMachineMutation()
  const [updateMachine, { isLoading: isLoadingUpdateMachine }] = useUpdateMachinesMutation()
  const [updateMachinesStatus, { isLoading: isLoadingUpdateMachinesStatus }] = useUpdateMachinesStatusMutation()
  const { setEquipmentMode, setSelectedMachineId, updateTreeMenuTitle, updateTreeMenuItem, setSelectedTreeItems } =
    useActions()

  const isAddMode = equipmentMode === EEquipmentMode.ADD
  const isEditMode = equipmentMode === EEquipmentMode.DEFAULT
  const { data: machine, isLoading } = useGetMachineQuery(machineId || null, {
    skip: !machineId && isAddMode,
    refetchOnMountOrArgChange: true
  })

  const periodControlWatched = useWatch(MachineName.PeriodicControl, machineForm)
  const cyclicControlWatched = useWatch(MachineName.CyclicControl, machineForm)

  useEffect(() => {
    if (machine && isEditMode) {
      const formData = mapFromResponseMachine(machine)
      machineForm.setFieldsValue(formData)
      setSavedData(formData)
    }
  }, [isEditMode, machine, machineForm])

  useEffect(() => {
    if (isAddMode) {
      const initialValuesForms: IMachineForm = {
        [MachineName.OperationalControl]: 300,
        [MachineName.CyclicControl]: 20,
        [MachineName.PeriodicControl]: 30
      }

      machineForm.setFieldsValue(initialValuesForms)
    }
  }, [machineForm, isAddMode])

  useEffect(() => {
    const maxMinute = periodControlWatched * 1440
    const maxSecond = periodControlWatched * 86400

    setMaxValuesControl({ minutes: maxMinute, seconds: maxSecond })

    if (periodControlWatched === 0) {
      const maxSecondByMinutes = cyclicControlWatched * 60
      setMaxValuesControl({ seconds: maxSecondByMinutes })
    }

    if (cyclicControlWatched === 0) {
      setMaxValuesControl({ seconds: undefined })
    }
  }, [periodControlWatched, cyclicControlWatched])

  const handleMachineFinish = async () => {
    const { getFieldsValue, validateFields } = machineForm
    try {
      try {
        await validateFields({ recursive: true })
      } catch (e) {
        errorNotificationCreate(e)
      }

      if (isAddMode) {
        const parentId = groupId === KEY_ROOT ? null : groupId
        const dataForRequest = mapToRequestMachine(getFieldsValue(), parentId)
        const { id } = await createMachine(dataForRequest).unwrap()
        setSelectedMachineId(id)
        successNotificationCreate('Машина успешно добавлена')
        updateTreeMenuItem({
          id,
          name: dataForRequest.name as string,
          group: EEquipmentTree.MACHINES,
          parentKey: groupId || KEY_ROOT,
          icon: machineIcon
        })
        setSelectedTreeItems([id])
        setEquipmentMode(EEquipmentMode.DEFAULT)
      }

      if (isEditMode && machineId) {
        const dataForRequest = mapToRequestMachine(getFieldsValue(), machine?.groupId)
        await updateMachine({
          machineData: dataForRequest,
          machineId: machineId
        }).unwrap()

        updateTreeMenuTitle({
          id: machineId,
          newTitle: dataForRequest.name as string
        })
        successNotificationCreate('Конфигурация машины успешно обновлена')
      }
    } catch (err) {
      errorNotificationCreate(err)
    }
  }

  const resetMachineForm = () => {
    if (savedData) {
      machineForm.setFieldsValue(savedData)
    }
  }

  const handleChangePaused = async () => {
    if (machineId && isEditMode) {
      const activated = machineForm.getFieldValue(MachineName.isActivated)
      const name = machineForm.getFieldValue(MachineName.ShortName)

      try {
        await updateMachinesStatus({
          updateData: {
            paused: !activated
          },
          machineId: machineId
        }).unwrap()
      } catch (error) {
        errorNotificationCreate(error)
      }
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  const getTitle = () => {
    if (isAddMode) {
      return 'Добавление новой машины'
    }

    return `Машина ${machine?.name}`
  }

  return (
    <>
      <HeaderConfiguration content={getTitle()} />
      <ContentWrapper>
        <Form form={machineForm} className={styles.container} layout={'horizontal'} onFinish={handleMachineFinish}>
          <ControlsWrapper toolTip={<DocTooltip theme='light' formKey={FormKey.FormOne} />}>
            <FormItemApp
              name={MachineName.ShortName}
              label={MachineLabel.ShortName}
              rules={[{ required: true, message: 'Поле обязательно' }]}
            >
              <Input placeholder='Введите наименование' />
            </FormItemApp>
            <FormItemApp name={MachineName.FullName} label={MachineLabel.FullName}>
              <Input placeholder='Введите полное наименование' />
            </FormItemApp>
            <FormItemApp name={MachineName.Location} label={MachineLabel.Location}>
              <Input placeholder='Введите расположение' />
            </FormItemApp>
            <FormItemApp name={MachineName.Comment} label={MachineLabel.Comment}>
              <TextArea placeholder='Введите комментарий' className={styles['text-area']} />
            </FormItemApp>
          </ControlsWrapper>
          <ControlsWrapper toolTip={<DocTooltip theme='light' formKey={FormKey.FormOne} />}>
            <PanelTitle>Интервал контроля</PanelTitle>
            {/* TODO: Необходимо доработать алгоритм валидации. Валидация срабатывает только на одном поле и требует корректировки только этого поля */}
            <FormItemApp
              name={MachineName.OperationalControl}
              label={MachineLabel.OperationalControl}
              rules={[{ required: true, message: 'Поле обязательно' }, { validator: validateNotAllZero(machineForm) }]}
            >
              <InputNumber
                decimalSeparator={','}
                placeholder='Введите время оперативный интервала контроля'
                min={0}
                max={maxValuesControl.seconds}
              />
            </FormItemApp>
            <FormItemApp
              name={MachineName.CyclicControl}
              label={MachineLabel.CyclicControl}
              rules={[{ required: true, message: 'Поле обязательно' }, { validator: validateNotAllZero(machineForm) }]}
            >
              <InputNumber
                decimalSeparator={','}
                placeholder='Введите время циклический интервала контроля'
                min={0}
                max={maxValuesControl.minutes}
              />
            </FormItemApp>
            <FormItemApp
              name={MachineName.PeriodicControl}
              label={MachineLabel.PeriodicControl}
              rules={[{ required: true, message: 'Поле обязательно' }, { validator: validateNotAllZero(machineForm) }]}
            >
              <InputNumber
                decimalSeparator={','}
                placeholder='Введите время периодического интервала контроля'
                min={0}
              />
            </FormItemApp>
          </ControlsWrapper>
          <ControlsWrapper toolTip={<DocTooltip theme='light' formKey={FormKey.FormOne} />}>
            {isEditMode && (
              <FormItemApp
                name={MachineName.isActivated}
                label={MachineLabel.isActivated}
                rules={[{ required: true, message: 'Поле обязательно' }]}
              >
                <Switch
                  className={styles['switch']}
                  loading={isLoadingUpdateMachinesStatus}
                  onChange={handleChangePaused}
                />
              </FormItemApp>
            )}
            <FormItemApp
              name={MachineName.RotationSpeedMin}
              label={MachineLabel.RotationSpeedMin}
              rules={[{ required: true, message: 'Поле обязательно' }]}
            >
              <InputNumber decimalSeparator={','} placeholder='Введите минимальную частоту вращения' min={0} />
            </FormItemApp>
            <FormItemApp
              name={MachineName.RotationSpeedMax}
              label={MachineLabel.RotationSpeedMax}
              rules={[{ required: true, message: 'Поле обязательно' }]}
            >
              <InputNumber decimalSeparator={','} placeholder='Введите максимальную частоту вращения' min={0} />
            </FormItemApp>
          </ControlsWrapper>
          <ControlsWrapper>
            <ButtonsWrapper>
              <ButtonPrimary
                htmlType='button'
                onClick={resetMachineForm}
                title={ButtonTitle.Reset}
                isLoadingSkeleton={false}
                isLoading={false}
                disabled={false}
              />
              <ButtonPrimary
                htmlType='submit'
                title={ButtonTitle.Save}
                isLoading={isLoadingCreateMachine || isLoadingUpdateMachine}
              />
            </ButtonsWrapper>
          </ControlsWrapper>
        </Form>
      </ContentWrapper>
    </>
  )
}

export default Machine
