import { mapThresholdColor } from '@/constants/threshold/threshold.constant'
import { EThresholdLevel } from '@/enums/threshold/threshold-level.enum'
import { useGetAllNodesQuery } from '@/store/api/nodes.api'
import type { INodesState } from '@/types/nodes/nodes.type'
import type { FC } from 'react'

import styles from './danger-state-Indicator.module.css'

interface IProps {
  nodeState?: INodesState
}

const ThresholdIndicator: FC<IProps> = ({ nodeState }) => {
  const thresholdColor = nodeState?.currentLevel
    ? mapThresholdColor[nodeState.currentLevel]
    : mapThresholdColor[EThresholdLevel.NONE]

  return <div style={{ backgroundColor: thresholdColor }} className={styles['circle-indicator']} />
}

export default ThresholdIndicator
