import Description from '@/app/condition/components/description/description'
import KinematicsCondition from '@/app/condition/components/kinematics-condition/kinematics-condition'
import MnemonicCondition from '@/app/condition/components/mnemonic-condition/mnemonic-condition'
import Results from '@/app/condition/components/results/results'
import { ConditionRoute } from '@/constants/navigation/routes.constant'
import type { RouteObject } from 'react-router'

const machineConditionRoute: RouteObject[] = [
  {
    path: ConditionRoute.Description,
    element: <Description />,
    index: true
  },
  {
    path: ConditionRoute.MimicDiagram,
    element: <MnemonicCondition />
  },
  {
    path: ConditionRoute.KinematicsDiagram,
    element: <KinematicsCondition />
  },
  {
    path: ConditionRoute.Results,
    element: <Results />
  }
]

export default machineConditionRoute
