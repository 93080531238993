import { EModeModal } from '@/app/settings/components/server-settings/server-settings.type'
import ButtonToolbar from '@/components/controlers/button-toolbar/button-toolbar'
import useActions from '@/hooks/use-actions'
import { Tooltip } from 'antd'
import type { FC } from 'react'
import React from 'react'

const ServerToolbar: FC = () => {
  const { setModalSettings } = useActions()
  const handleModalOpenedClick = (mode: EModeModal) => {
    setModalSettings({ opened: true, mode })
  }
  return (
    <>
      <Tooltip title='Добавить сервер' placement='right'>
        <ButtonToolbar icon='add' onClick={() => handleModalOpenedClick(EModeModal.ADD)} />
      </Tooltip>
      <Tooltip title='Редактировать сервер' placement='right'>
        <ButtonToolbar icon='edit' onClick={() => handleModalOpenedClick(EModeModal.EDIT)} />
      </Tooltip>
      <Tooltip title='Удалить сервер' placement='right'>
        <ButtonToolbar
          icon='delete'
          onClick={() => handleModalOpenedClick(EModeModal.DELETE)}
          /*disabled={isSelectedActiveServer}*/
        />
      </Tooltip>
    </>
  )
}

export default ServerToolbar
